import { getI18n } from 'react-i18next'
import { TimeUnitInSeconds } from '../features/services/checks/service-utils'

export const DateFormatter = new Intl.DateTimeFormat(
  (getI18n() && getI18n().language) ?? 'en',
  {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
)

const secondsToUpperUnits = (
  seconds,
  upperLimit,
  upperLimitStep,
  unitUp,
  unitLow
) => {
  const upper = Math.floor(seconds / upperLimit)
  const lower = Math.floor((seconds % upperLimit) / upperLimitStep)
  return lower === 0
    ? `${upper}${unitUp}`
    : `${upper}${unitUp} ${lower}${unitLow}`
}

export const SecondsPrettify = (seconds) => {
  if (seconds < TimeUnitInSeconds.minute) {
    return `${seconds}s`
  }
  if (seconds < TimeUnitInSeconds.hour) {
    return secondsToUpperUnits(
      seconds,
      TimeUnitInSeconds.minute,
      TimeUnitInSeconds.second,
      'm',
      's'
    )
  }
  if (seconds < TimeUnitInSeconds.day) {
    return secondsToUpperUnits(
      seconds,
      TimeUnitInSeconds.hour,
      TimeUnitInSeconds.minute,
      'h',
      'm'
    )
  }
  return secondsToUpperUnits(
    seconds,
    TimeUnitInSeconds.day,
    TimeUnitInSeconds.hour,
    'd',
    'h'
  )
}
