import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { getI18n, withTranslation } from 'react-i18next'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { eventToText } from '../../../utils/eventsExt'
import { useHistory } from 'react-router'
import { AssignmentTurnedIn } from '@material-ui/icons'
import Loading from '../../../components/Loading'
import { Routes } from '../../../routes'
import { S_DELETE } from '../../../utils/states-actions'
import { GetStatusColor } from '../../services/checks/service-utils'
import { LocationSection } from '../details/views/list'
import NoDataView from '../../../components/no-data-view'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
})

const OverviewList = (props) => {
  const { state, t, classes } = props
  const history = useHistory()
  const [filterState, setFilterState] = useState({ nameFilter: '' })
  const formatter = new Intl.DateTimeFormat(getI18n().language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
  const handleOpen = (id) => {
    history.push(Routes.checkResults(id))
  }
  const handleFilterChange = (event) => {
    const newFilter = { ...filterState }
    newFilter[`${event.target.id}`] = eventToText(event)
    setFilterState(newFilter)
  }
  const hasData = state.checks.length > 0
  console.log('state.checks', state.checks)
  const OverviewTable = () => {
    if (!hasData) {
      return null
    }
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell key="status">{t('overview.status')}</TableCell>
            <TableCell key="name">{t('overview.name')}</TableCell>
            <TableCell key="tested_from">{t('overview.tested_from')}</TableCell>
            <TableCell key="latest">{t('overview.latest')}</TableCell>
            <TableCell key="loadtime">{t('overview.loadtime')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.checks.map((item) => {
            const openAction = () => handleOpen(item.id)
            return (
              <TableRow key={item.id} className={classes.row}>
                <TableCell onClick={openAction}>
                  <AssignmentTurnedIn
                    style={{ color: GetStatusColor(item.returncode) }}
                  />
                </TableCell>
                <TableCell onClick={openAction}>{item.name}</TableCell>
                <TableCell>
                  {(item.src_geoip && (
                    <LocationSection
                      classes={classes}
                      asSingleLine={'ajdee'}
                      geoip={item.src_geoip}
                    />
                  )) ||
                    null}
                </TableCell>
                <TableCell onClick={openAction}>
                  {formatter.format(new Date(item.timestamp)) || 0}
                </TableCell>
                <TableCell onClick={openAction}>{item.loadtime}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box
        className={classes.appBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={hasData ? 'space-between' : 'center'}
        p={1}
      >
        {/* SearchBox is hidden via CSS, check styles on top */}
        {hasData ? (
          <TextField
            id={'nameFilter'}
            size={'small'}
            label={t('overview.search')}
            className={classes.textField}
            value={filterState.nameFilter}
            onChange={handleFilterChange}
            variant="outlined"
            type="text"
          />
        ) : (
          <NoDataView message={t('overview.no_data')} />
        )}
      </Box>
      <OverviewTable />
      {state.type === S_DELETE && (
        <Loading className={classes.loader} onClick={() => {}} />
      )}
    </div>
  )
}
export default withTranslation()(withStyles(styles)(OverviewList))
