import React from 'react'
import {
  buildForDelete,
  ExecuteGQLWithBuilder,
} from '../../../utils/executeGraphQL'
import { Entity } from '../../entities'

export const DeleteUser = (props) => {
  const { id, onDone, onError } = props
  const filter = { id: id }
  return (
    <ExecuteGQLWithBuilder
      builderData={buildForDelete(Entity.user, filter, 'id')}
      onDone={() => {
        onDone(filter)
      }}
      onError={onError}
    />
  )
}

export default DeleteUser
