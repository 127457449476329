export const NodeTypes = {
  IdReq: 'ID!',
  Int: 'Int',
  IntReq: 'Int!',
  Float: 'Float',
  String: 'String',
  StringReq: 'String!',
  Boolean: 'Boolean',
  Date: 'DateTime',
  ArrInt: '[Int]',
}
