import { Button, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSubscriptionContext } from '../context'
import {
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../../utils/states-actions'
import EditSubscription from './edit-subscription'
import ErrorCmp from '../../../../components/error'
import { LoadingForState } from '../../../../components/Loading'
import { Row } from 'reactstrap'
import CurrentPlan, { PlanType } from '../../../../components/current-plan'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Routes } from '../../../../routes'
import TopBar from '../../../../components/topbar'

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  planHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
  },
  planIcon: {
    minWidth: 'auto',
  },
  plan: {
    flex: '0 0 auto',
    flexDirection: 'row',
    padding: '8px',
  },
})

export const ChangePlan = (props) => {
  const { plan, t, classes } = props
  const planName = plan.name
  const history = useHistory()
  const handleClick = () => {
    history.push(Routes.subscriptions)
  }
  return (
    <Row className={classes.planHolder}>
      <CurrentPlan
        planName={planName}
        planIconClassName={classes.planIcon}
        planClassName={classes.plan}
      />
      <Button onClick={handleClick}>
        {t(
          `subscription_edit.${
            planName === PlanType.free ? 'choose' : 'change'
          }_plan`
        )}
      </Button>
    </Row>
  )
}

const SubscriptionViews = (props) => {
  const hookRef = useSubscriptionContext()
  const { state } = hookRef
  const { classes, t } = props
  // console.log(state)

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.load()}
            <LoadingForState />
          </>
        )
      case S_NO_DATA:
        return <ChangePlan {...hookRef} t={t} classes={classes} />
      case S_LOAD_DONE:
        return (
          <>
            {(props.editSubscription && props.editSubscription(hookRef)) || (
              <EditSubscription {...hookRef} />
            )}
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar titleText={t('subscription_edit.title')} hideBack={true} />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(SubscriptionViews))
