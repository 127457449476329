import { FormControlLabel, Radio } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import Subtitle from './Subtitle'

export const RadioItem = (props) => {
  return (
    <FormControlLabel
      value={props.value}
      disabled={props.disabled}
      control={
        <Radio
          inputProps={{
            'data-testid': props.testId,
          }}
        />
      }
      label={
        (props.label && <Subtitle>{props.label}</Subtitle>) ||
        props.labelComponent
      }
    />
  )
}

RadioItem.propTypes = {
  value: PropTypes.any.isRequired,
  testId: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelComponent: PropTypes.object,
  disabled: PropTypes.bool,
}
