import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useUsersContext } from '../context'
import UsersList from './users-list'
import ErrorCmp from '../../../components/error'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../utils/states-actions'
import LoadingView from '../../../components/loading-view'
import TopBar from '../../../components/topbar'
import { Add } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Routes } from '../../../routes'

const styles = () => ({
  root: {
    maxWidth: '100%',
    overflowX: 'auto',
    margin: 'auto',
  },
})

const UsersViews = (props) => {
  const hookRef = useUsersContext()
  const history = useHistory()
  const { state, accountId } = hookRef
  const { classes, t } = props
  const handleCreate = () => {
    history.push(Routes.userNew(accountId, 'new'))
  }
  // console.log(state)

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        hookRef.load(hookRef.accountId)
        return (
          <>
            <LoadingView />
          </>
        )
      case S_DELETE:
      case S_NO_DATA:
      case S_LOAD_DONE:
        return (
          <>
            {state.type === S_DELETE && hookRef.deleteUser(state.forDeletion)}
            <UsersList {...hookRef} />
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar
        titleText={t('users.title')}
        hideBack={true}
        hasNextButton={true}
        nextButtonIcon={<Add />}
        textNext={t('users.add')}
        onNext={handleCreate}
      />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(UsersViews))
