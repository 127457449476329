import { useHistory } from 'react-router'
import { withTranslation } from 'react-i18next'
import Title from './Title'
import { Box, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const styles = (theme) => ({
  holder: {
    backgroundColor: 'transparent',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonRight: {},
  title: {
    margin: 0,
    flex: 'auto',
  },
})

const TopBar = (props) => {
  const { t, classes } = props
  const history = useHistory()
  const handleGoBack = () => {
    history.goBack()
  }
  const hasBack = !(props.hideBack ?? false)
  const backButton = hasBack && (
    <Button
      size={'small'}
      autoCapitalize={'lower'}
      className={classes.button}
      variant="text"
      color="primary"
      onClick={props.onBack ?? handleGoBack}
    >
      <ChevronLeft />
      {props.textBack ?? t('navigation.back')}
    </Button>
  )
  const nextButton = (props.hasNextButton ?? false) && (
    <Button
      size={'small'}
      autoCapitalize={'lower'}
      className={classes.buttonRight}
      variant="text"
      color="primary"
      onClick={props.onNext}
    >
      {props.nextButtonIcon ?? <ChevronRight />}
      {props.textNext}
    </Button>
  )
  return (
    <Box
      className={classes.holder}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'start'}
      alignContent={'center'}
      p={1}
    >
      {backButton}
      {props.title || (
        <Title className={classes.title}>{props.titleText}</Title>
      )}
      {nextButton}
    </Box>
  )
}
TopBar.propTypes = {
  textBack: PropTypes.string,
  titleText: PropTypes.string,
  textNext: PropTypes.string,
  title: PropTypes.node,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  hideBack: PropTypes.bool,
  hasNextButton: PropTypes.bool,
  nextButtonIcon: PropTypes.element,
}
export default withTranslation()(withStyles(styles)(TopBar))
