import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation, getI18n } from 'react-i18next'
import EditField from '../../../../../components/edit-field'
import SaveButton from '../../../../../components/save-button'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import Subtitle from '../../../../../components/Subtitle'
import { ChevronRight, NotificationsActive } from '@material-ui/icons'
import { useTeamContext } from '../context'
import { S_WITH_NOTIFICATIONS } from '../states-actions'
import NotificationEditView from '../../../../notifications/notification/edit'
import { Entity } from '../../../../entities'
import { NotificationHooks } from '../../../../notifications/notification/edit/views/edit-settings'
import { Limits } from '../../../../../utils/limits'

const styles = (theme) => ({
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
})

const NotificationsPart = (props) => {
  const { state, classes, t, showNotifications } = props
  const { onNewSchedule } = useTeamContext()
  console.log('NotificationsPart: state', state)

  const NotificationsButton = () => (
    <Button
      size={'small'}
      autoCapitalize={'lower'}
      className={classes.button}
      variant="contained"
      color="default"
      onClick={showNotifications}
    >
      <NotificationsActive fontSize="small" />
      {t('team_edit.notification_settings')}
      <ChevronRight fontSize="small" />
    </Button>
  )

  return state.type === S_WITH_NOTIFICATIONS ? (
    <NotificationEditView
      onNewSchedule={onNewSchedule}
      forEntity={Entity.team}
      entityId={state.team.id}
      scheduleId={state.team.notifications.schedule.id}
    />
  ) : (
    <NotificationsButton />
  )
}

const EditTeamInfoDetails = (props) => {
  const { classes, getEditField, onFieldChange, hasChanges, doSave, t } = props
  console.log('EditTeamInfoDetails:: props', props)
  const isNew = getEditField('id').value === 'new'
  const formatter = new Intl.DateTimeFormat(getI18n().language)
  const isActive = getEditField('active').value === true
  const hookRef = useTeamContext()

  return (
    <>
      {!isNew && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                id={'active'}
                name={'active'}
                size={'small'}
                checked={isActive}
                onClick={onFieldChange}
                inputProps={{
                  'data-testid': 'check-active',
                }}
              />
            }
            label={<Subtitle>{t('team_edit.active')}</Subtitle>}
          />
          <EditField
            fieldType="createdAt"
            field="createdAt"
            format={(iVal) => {
              return iVal ? formatter.format(Date.parse(iVal)) : ''
            }}
            label={t('team_edit.created_at')}
            getEditField={getEditField}
            disabled
            onEditFieldChange={onFieldChange}
          />
          <NotificationHooks {...props} />
        </>
      )}
      <SaveButton hasChanges={hasChanges} handleClick={doSave} />

      {!isNew && (
        <NotificationsPart
          state={hookRef.state}
          classes={classes}
          t={t}
          showNotifications={hookRef.showNotifications}
        />
      )}
    </>
  )
}
const EditTeamInfo = (props) => {
  const { getEditField, onFieldChange, t } = props

  return (
    <>
      <EditField
        field="name"
        label={t('team_edit.name')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditField
        field="description"
        label={t('team_edit.desc')}
        multiline={true}
        maxLength={Limits.organizationDesc}
        maxLines={4}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditTeamInfoDetails {...props} />
    </>
  )
}
export default withTranslation()(withStyles(styles)(EditTeamInfo))
