import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedAccount,
  actionNoAccount,
  actionOnAccountChange,
  actionSaveAccount,
  StateInitial,
} from './states-actions'
import { eventToText } from '../../../../utils/eventsExt'
import { reducer } from '../../../../components/reducer'
import { actionLoadFailed } from '../../../../utils/states-actions'

export const AccountContext = createContext({})

export const useAccount = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { accountId } = data

  const onFieldChange = (value) => {
    const keyValPair = {}
    keyValPair[`${value.target.id}`] = eventToText(value)
    dispatch(actionOnAccountChange({ ...keyValPair }))
  }

  const getEditField = (name) => {
    const value =
      typeof state.accountEdit[name] === 'undefined'
        ? state.account[name]
        : state.accountEdit[name]
    return {
      value: value || '',
      error: state.errors[name] || null,
    }
  }

  const Load = (accountId) => {
    const onError = (err) => {
      console.log('::LoadAccount llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(data.id ? actionLoadedAccount(data) : actionNoAccount())
    }
    return provider.GetAccount(accountId, onLoad, onError)
  }

  const Update = () => {
    const onError = (err) => {
      console.log('::UpdateAccount llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onLoad = (data) => {
      dispatch(actionLoadedAccount(data))
    }

    return provider.UpdateAccount(
      state.account.id,
      state.accountEdit,
      onLoad,
      onError
    )
  }

  const hasChanges = () => {
    return Object.keys(state.accountEdit).length > 0
  }

  const doUpdate = () => {
    dispatch(actionSaveAccount())
  }

  return {
    state,
    accountId,
    load: Load,
    update: Update,
    hasChanges,
    doUpdate,
    getEditField,
    onFieldChange,
  }
}

export const useAccountContext = () => useContext(AccountContext)
export const AccountContextProvider = (props) => {
  const hookRef = useAccount(props.data, props.provider)

  return (
    <AccountContext.Provider value={hookRef}>
      {props.children}
    </AccountContext.Provider>
  )
}
