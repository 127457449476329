import React from 'react'
import Title from '../../../../../components/Title'
import { withTranslation } from 'react-i18next'
import { useServiceCheckContext } from '../context'
import { Button, Paper } from '@material-ui/core'
import { AssignmentTurnedIn } from '@material-ui/icons'
import { GetStatusColor } from '../../service-utils'
import { withStyles } from '@material-ui/core/styles'
import Subtitle from '../../../../../components/Subtitle'
import Loading from '../../../../../components/Loading'
import { S_TESTING, S_TESTING_DONE } from '../states-actions'

const styles = (theme) => ({
  table: {},
  button: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  navHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  sectionHolder: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

const TestingView = (props) => {
  const { t } = props

  return (
    <>
      <Loading />
      <Title>{t('please_wait')}</Title>
    </>
  )
}

const TestingReportViewResult = (props) => {
  const { t, classes } = props
  const hookRef = useServiceCheckContext()
  const item = hookRef.state.result
  const statusStyle = { color: GetStatusColor(item.returncode) }

  return (
    <>
      <Subtitle style={{ display: 'flex' }}>
        <AssignmentTurnedIn style={statusStyle} />
        {item.loadtime}
      </Subtitle>
      <Subtitle>
        {(item.error ? item.error.message : item.info.message) || ''}
      </Subtitle>
      <br />
      <Button
        size={'small'}
        autoCapitalize={'lower'}
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={hookRef.doRunTest}
      >
        {t('serviceCheck_edit.test_again')}
      </Button>
    </>
  )
}

const TestingReportView = (props) => {
  const { t, classes } = props
  const hookRef = useServiceCheckContext()
  const hasErrors =
    hookRef.state.errors && Object.values(hookRef.state.errors).length > 0
  console.log('hookRef.state.errors', hookRef.state, hasErrors)
  const getBySubtype = (subtype) => {
    console.log('subtype', subtype)
    switch (subtype) {
      case S_TESTING:
        return (
          <>
            {hookRef.runTest()}
            <TestingView t={t} />
          </>
        )
      case S_TESTING_DONE:
        return <TestingReportViewResult t={t} classes={classes} />
      default:
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={hookRef.doRunTest}
            >
              {t('serviceCheck_edit.test')}
            </Button>
            {hasErrors && (
              <Subtitle>{t('serviceCheck_edit.test_errors')}</Subtitle>
            )}
          </>
        )
    }
  }
  return (
    <>
      <Subtitle>{t('serviceCheck_edit.testing')}</Subtitle>
      <Paper className={classes.sectionHolder}>
        {getBySubtype(hookRef.state.subtype)}
      </Paper>
    </>
  )
}
export default withTranslation()(withStyles(styles)(TestingReportView))
