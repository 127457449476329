import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useServiceCheckContext } from '../context'
import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_SAVING,
} from '../../../../../utils/states-actions'
import EditView, { ManageServiceCheck } from './edit-view'
import SavingView from './saving'
import ErrorCmp from '../../../../../components/error'
import { useHistory } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Routes } from '../../../../../routes'
import TopBar from '../../../../../components/topbar'
import LoadingView from '../../../../../components/loading-view'

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  button: {
    margin: 'auto',
  },
  buttonRight: {
    float: 'right',
  },
})

const ServiceCheckViews = (props) => {
  const hookRef = useServiceCheckContext()
  const history = useHistory()
  const { state } = hookRef
  const { classes, t } = props
  // console.log('STATE: ', { ...state })

  const handleOnCreated = () => {
    history.replace(Routes.serviceGroup())
  }
  const addingIds = Object.keys(state.addingToTeam || {})

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        hookRef.load(hookRef.id)
        return <LoadingView />
      case S_LOAD_DONE:
        return (
          <>
            {addingIds.length > 0 &&
              hookRef.toggleServiceCheckTeam(
                state.serviceCheck.id,
                addingIds.pop()
              )}
            <EditView {...hookRef} teamsListComp={props.teamsListComp} />
          </>
        )
      case S_CREATED: {
        // Using case LOADED wrapped in block { } because of >> constS << and no-case-declarations rule
        const isNew = hookRef.getEditField('id').value === 'new'
        const addingTo = state.addingToAccount || {}
        const isUpdating = Object.keys(addingTo).length > 0
        return (
          <>
            {addingIds.length > 0 &&
              hookRef.toggleServiceCheckTeam(
                state.serviceCheck.id,
                addingIds.pop()
              )}
            <ManageServiceCheck
              t={t}
              hideAddToAccount={isNew || isUpdating}
              doToggleAccount={hookRef.doToggleAccount}
              doToggleTeam={hookRef.doToggleTeam}
              state={state}
            />
          </>
        )
      }
      case S_SAVING:
        return (
          <>
            {hookRef.save()}
            <SavingView />
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }
  const isNew = hookRef.getEditField('id').value === 'new'

  return (
    <Paper className={classes.root}>
      <TopBar
        titleText={t(`serviceCheck_edit.title${isNew ? '_new' : ''}`)}
        onBack={handleOnCreated}
      />
      {getByState()}
    </Paper>
  )
}

export default withTranslation()(withStyles(styles)(ServiceCheckViews))
