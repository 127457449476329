import React from 'react'
import { withTranslation } from 'react-i18next'
import Loading from './Loading'
import Title from './Title'
import { Row } from 'reactstrap'

const LoadingView = (props) => {
  const { t } = props
  return (
    <Row style={{ textAlign: 'center', padding: '8px' }}>
      <Loading />
      <Title className={'loading-profile'}>
        {t(props.message ?? 'please_wait')}
      </Title>
    </Row>
  )
}
export default withTranslation()(LoadingView)
