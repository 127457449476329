import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { eventToText } from '../../../utils/eventsExt'
import { useHistory } from 'react-router'
import { Delete } from '@material-ui/icons'
import Loading from '../../../components/Loading'
import { S_DELETE } from '../../../utils/states-actions'
import { Routes } from '../../../routes'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    display: 'none',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
})

const TeamsList = (props) => {
  const { state, t, classes, doDeleteTeam } = props
  const history = useHistory()
  const [filterState, setFilterState] = useState({ nameFilter: '' })
  const handleOpenTeam = (id) => {
    history.push(Routes.team(id))
  }
  const handleFilterChange = (event) => {
    const newFilter = { ...filterState }
    newFilter[`${event.target.id}`] = eventToText(event)
    setFilterState(newFilter)
  }
  const hasOrgs = state.teams.length > 0
  const TeamsTable = () => {
    if (!hasOrgs) {
      return null
    }
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell key="name">{t('teams.name')}</TableCell>
            <TableCell key="active">{t('teams.active')}</TableCell>
            <TableCell key="members">{t('teams.members_count')}</TableCell>
            <TableCell key="actions">{props.actionsTitle || ''}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.teams.map((teamItem) => {
            const openOrg = () => handleOpenTeam(teamItem.id)
            const delOrg = () => doDeleteTeam(teamItem.id)
            const isMarkedForDeletion = state.forDeletion === teamItem.id
            return (
              <TableRow key={teamItem.id} className={classes.row}>
                <TableCell onClick={openOrg}>
                  {teamItem.name ?? 'NoName'}
                </TableCell>
                <TableCell onClick={openOrg}>
                  <Checkbox
                    id={'active'}
                    name={'active'}
                    size={'small'}
                    disabled={true}
                    checked={teamItem.active ?? false}
                    inputProps={{
                      'data-testid': 'check-active',
                    }}
                  />
                </TableCell>
                <TableCell onClick={openOrg}>
                  {(teamItem.members ?? []).length}
                </TableCell>
                <TableCell className={classes.action}>
                  <IconButton
                    data-testid={`delete-${teamItem.id}`}
                    disabled={isMarkedForDeletion}
                    size={'small'}
                    onClick={delOrg}
                  >
                    <Delete color={'error'} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box
        className={classes.appBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={hasOrgs ? 'space-between' : 'center'}
        p={1}
      >
        {/* SearchBox is hidden via CSS, check styles on top */}
        {hasOrgs && (
          <TextField
            id={'nameFilter'}
            size={'small'}
            label={t('teams.search')}
            className={classes.textField}
            value={filterState.nameFilter}
            onChange={handleFilterChange}
            variant="outlined"
            type="text"
          />
        )}
      </Box>
      <TeamsTable />
      {state.type === S_DELETE && (
        <Loading className={classes.loader} onClick={() => {}} />
      )}
    </div>
  )
}
export default withTranslation()(withStyles(styles)(TeamsList))
