import {
  buildForUpdate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_USER } from './get-user'
import React from 'react'

export const UpdateUser = (props) => {
  const { profileEdit, profileId } = props
  const changed = { ...profileEdit }
  const builderData = buildForUpdate(
    Entity.user,
    FIELDS_USER,
    { id: profileId },
    changed
  )

  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onLoad}
      onError={props.onError}
    />
  )
}

export default UpdateUser
