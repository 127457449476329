import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useCheckResultsContext } from '../context'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../../utils/states-actions'
import CheckResultsList, { LocationSection } from './list'
import ErrorCmp from '../../../../components/error'
import TopBar from '../../../../components/topbar'
import Title from '../../../../components/Title'
import { useHistory } from 'react-router'
import { Routes } from '../../../../routes'
import { withTranslation } from 'react-i18next'
import LoadingView from '../../../../components/loading-view'

const styles = (theme) => ({
  root: {
    margin: 'auto',
  },
  title: {
    margin: 0,
  },
  subtitle: {
    margin: 'auto ' + theme.spacing(1) + 'px',
  },
})

const CheckResultsViews = (props) => {
  const hookRef = useCheckResultsContext()
  const { state } = hookRef
  const { classes, t } = props
  const history = useHistory()
  const handleToDash = () => {
    history.replace(Routes.overview)
  }
  // console.log(state)
  const CheckTitleSection = (props) => {
    const { check } = props
    console.log('CheckTitleSection', props)

    return (
      <>
        <Title className={classes.title}>{check.name}</Title>
        {(check.dst_geoip && (
          <LocationSection
            classes={classes}
            asSingleLine={'ajdee'}
            geoip={check.dst_geoip}
          />
        )) ||
          null}
      </>
    )
  }

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.load(hookRef.accountId, hookRef.checkId)}
            <LoadingView />
          </>
        )
      case S_DELETE:
      case S_NO_DATA:
      case S_LOAD_DONE:
        return (
          <>
            {(props.checkResultsList && props.checkResultsList(hookRef)) || (
              <CheckResultsList {...hookRef} />
            )}
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }
  const hasData = (state.checkResults ?? []).length > 0

  return (
    <Paper className={classes.root}>
      <TopBar
        title={
          hasData ? <CheckTitleSection check={state.checkResults[0]} /> : null
        }
        titleText={hasData ? null : t('checkResults.title')}
        onBack={handleToDash}
      />
      {getByState()}
    </Paper>
  )
}

export default withTranslation()(withStyles(styles)(CheckResultsViews))
