import React from 'react'
import {
  buildForDelete,
  ExecuteGQLWithBuilder,
} from '../../../utils/executeGraphQL'
import { Entity } from '../../entities'

export const DeleteTeam = (props) => {
  const { id, onDone, onError } = props
  return (
    <ExecuteGQLWithBuilder
      builderData={buildForDelete(Entity.team, { id: id }, 'id')}
      onDone={() => {
        onDone({ id: id })
      }}
      onError={onError}
      cacheModifyBlock={(cache) => {
        console.log('DeleteTeam cacheModifyBlock', cache)
        // updateCacheTeams(cache, { id: id }, CacheChangeType.delete)
      }}
    />
  )
}

export default DeleteTeam
