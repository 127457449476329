import { getDiff } from '../../../../utils/diff'
import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_START,
  S_SAVING,
} from '../../../../utils/states-actions'

export const ScheduleSource = {
  account: 0,
  team: 1,
  custom: 2,
}
export const HooksProvider = {
  slack: 1,
  someOther: 2,
}
export const Days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
export const NotificationDays = ['global', ...Days]
export const StateInitial = (scheduleSource = null) => {
  return {
    type: S_LOAD_START,
    scheduleSource: scheduleSource ?? USER_NTF_TYPE.email,
    notification: {},
    notificationEdit: {},
    team: '',
    account: '',
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoaded = (data, scheduleSource = null) => {
  return {
    type: S_LOAD_DONE,
    scheduleSource: scheduleSource ?? USER_NTF_TYPE.email,
    notification: data,
    notificationEdit: {},
    errors: {},
  }
}
export const StateCreated = (data, scheduleSource = null) => {
  return {
    type: S_CREATED,
    scheduleSource: scheduleSource ?? USER_NTF_TYPE.email,
    notification: data,
    notificationEdit: {},
    errors: {},
  }
}
export const StateSaving = (scheduleSource = null) => {
  return {
    type: S_SAVING,
    scheduleSource: scheduleSource ?? USER_NTF_TYPE.email,
  }
}

export const actionSave = () => {
  return {
    reduce: (state) => {
      const tmpState = { ...state }
      return {
        ...tmpState,
        ...StateSaving(state.scheduleSource),
      }
    },
  }
}
export const actionLoaded = (data) => {
  const notification = { ...data }
  return {
    reduce: (state) => {
      return StateLoaded({ ...notification }, state.scheduleSource)
    },
  }
}
export const actionCreated = (data) => {
  const notification = { ...data }
  return {
    reduce: (state) => {
      return StateCreated(
        { ...notification, hooks: state.notification.hooks },
        state.scheduleSource
      )
    },
  }
}
export const actionUpdated = (data) => {
  const notification = { ...data }
  return {
    reduce: (state) => {
      return StateLoaded(
        { ...notification, hooks: state.notification.hooks },
        state.scheduleSource
      )
    },
  }
}
export const actionOnChange = (data) => {
  const notification = { ...data }
  return {
    reduce: (state) => {
      console.log('changed data: ', JSON.parse(JSON.stringify(notification)))
      return getDiff(notification, state, 'notification')
    },
  }
}
export const actionOnScheduleChange = (scheduleSource) => {
  console.log('actionOnScheduleChange::scheduleSource', scheduleSource)
  return {
    reduce: (state) => {
      return {
        ...state,
        type: S_LOAD_START,
        notification: {},
        notificationEdit: {},
        scheduleSource: scheduleSource,
      }
    },
  }
}
export const NTF_TYPE = {
  allday: '24x7',
  everyday: 'everyday',
  workdays: 'workdays',
  custom: 'custom',
  off: 'none',
}
export const USER_NTF_TYPE = {
  email: 'email',
  sms: 'sms',
}

export const SCH_TYPE = {
  notification: 'NOTIFICATION',
}
