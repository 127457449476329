import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import EditField from '../../../../../components/edit-field'
import SaveButton from '../../../../../components/save-button'
import Subtitle from '../../../../../components/Subtitle'
import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Limits } from '../../../../../utils/limits'
import { TimezonesSimple } from '../../../../../utils/timezones'
import { Autocomplete } from '@material-ui/lab'
import VerifyPhone from '../verify_phone/views/verify_phone'
import { VerifyPhoneContextProvider } from '../verify_phone/context'
import { GraphQLProviderVerifyCode } from '../verify_phone/data/provider'

const styles = (theme) => ({
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
})

const EditProfileInfo = (props) => {
  const {
    doUpdateProfile,
    getProfileField,
    onProfileFieldChange,
    hasChanges,
    t,
  } = props
  console.log('EditProfileInfo', props)
  const isNew = getProfileField('id').value === 'new'
  const fieldEmail = 'ntf_email_enabled'
  const fieldSMS = 'ntf_sms_enabled'
  const useEmail = getProfileField(fieldEmail).value !== ''
  const useSMS = getProfileField(fieldSMS).value !== ''
  console.log('Timezones count', TimezonesSimple.length)

  return (
    <>
      {isNew ? (
        <EditField
          fieldType="email"
          field="email"
          label={t('address.email')}
          trans={t}
          getEditField={getProfileField}
          onEditFieldChange={onProfileFieldChange}
        />
      ) : null}
      <EditField
        fieldType="given-name"
        field="given_name"
        label={t('profile_edit.name_given')}
        maxLength={Limits.user.name.given.max}
        trans={t}
        getEditField={getProfileField}
        onEditFieldChange={onProfileFieldChange}
      />
      <EditField
        fieldType="family-name"
        field="family_name"
        label={t('profile_edit.name_family')}
        maxLength={Limits.user.name.family.max}
        trans={t}
        getEditField={getProfileField}
        onEditFieldChange={onProfileFieldChange}
      />
      {isNew ? null : (
        <>
          <EditField
            fieldType="nickname"
            field="nickname"
            label={t('profile_edit.nickname')}
            trans={t}
            getEditField={getProfileField}
            onEditFieldChange={onProfileFieldChange}
          />
          <EditField
            fieldType="phone"
            field="mobile"
            label={t('profile_edit.mobile')}
            trans={t}
            getEditField={getProfileField}
            onEditFieldChange={onProfileFieldChange}
          />

          <VerifyPhoneContextProvider
            data={{ user: props.state.user }}
            provider={GraphQLProviderVerifyCode()}
          >
            <VerifyPhone />
          </VerifyPhoneContextProvider>
          <Autocomplete
            disablePortal
            id="timezone"
            options={TimezonesSimple}
            defaultValue={'UTC'}
            onChange={(event) => {
              onProfileFieldChange({
                target: { id: 'timezone', value: event.target.innerText },
              })
            }}
            label={t('timezone')}
            value={getProfileField('timezone').value || ''}
            renderOption={(props, option) => {
              return (
                <Box name={'timezone'} value={props}>
                  {props}
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextField id={'blabla'} {...params} label={t('timezone')} />
            )}
          />
          <Subtitle>{t('profile_edit.notify_via')}</Subtitle>
          <FormControlLabel
            control={
              <Checkbox
                id={fieldEmail}
                size={'small'}
                checked={useEmail}
                onClick={onProfileFieldChange}
                inputProps={{
                  'data-testid': 'check-use-email',
                }}
              />
            }
            label={<Subtitle>{t('profile_edit.use_email')}</Subtitle>}
          />
          <FormControlLabel
            control={
              <Checkbox
                id={fieldSMS}
                size={'small'}
                checked={useSMS}
                onClick={onProfileFieldChange}
                inputProps={{
                  'data-testid': 'check-use-sms',
                }}
              />
            }
            label={<Subtitle>{t('profile_edit.use_sms')}</Subtitle>}
          />
        </>
      )}
      <SaveButton hasChanges={hasChanges} handleClick={doUpdateProfile} />
    </>
  )
}
export default withTranslation()(withStyles(styles)(EditProfileInfo))
