import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const Title = (props) => (
  <Typography
    className={props.className}
    component="h2"
    variant="h6"
    color="primary"
    gutterBottom
  >
    {props.children}
  </Typography>
)
export default Title

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
