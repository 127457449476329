import React from 'react'
import GetTeams from './get'
import DeleteTeam from './delete'
import { TeamMapperDataToDomain } from '../team/edit/data/mapper'

export const GQLProviderTeams = () => {
  const Get = (id, accountId, onLoad, onError) => {
    console.log('Get teams: accountId, id: ', accountId, id)
    GetTeams({
      justFirst: false,
      id: id,
      accountId: accountId,
      mapper: TeamMapperDataToDomain,
      onLoad: onLoad,
      onError: onError,
    })
  }
  const Delete = (key, onDone, onError) => {
    return <DeleteTeam id={key} onError={onError} onDone={onDone} />
  }

  return {
    GetTeams: Get,
    DeleteTeam: Delete,
  }
}
