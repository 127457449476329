export const S_LOAD_START = 1
export const S_LOAD_ERR = 10
export const S_LOAD_DONE = 20
export const S_NO_DATA = 21
export const S_POLL = 22
export const S_SAVING = 30
export const S_CREATED = 32
export const S_DELETE = 33

export const StateError = (code, message) => {
  return {
    type: S_LOAD_ERR,
    code: code,
    message: message,
  }
}
export const actionLoadFailed = (data) => {
  const { code, message } = data
  return {
    reduce: (state) => {
      return { ...state, ...StateError(code, message) }
    },
  }
}
export const actionDelete = (id) => {
  const data = `${id}`
  return {
    reduce: (state) => {
      return { ...state, type: S_DELETE, forDeletion: data }
    },
  }
}
export const actionDeleted = (stateKey, data) => {
  const deletedData = { ...data }
  return {
    reduce: (state) => {
      console.log('reduce actionDeleted id', deletedData.id)
      const result = { ...state, type: S_LOAD_DONE }
      console.log('reduce actionDeleted result', result[stateKey])
      delete result.forDeletion
      const rrr = result[stateKey].filter((val) => {
        return val.id !== deletedData.id
      })
      console.log('reduce actionDeleted result after', rrr)
      result[stateKey] = rrr
      return result
    },
  }
}
