import React from 'react'
import PropTypes from 'prop-types'
import GetIncidents from './get'
import Acknowledge from './acknowledge'

export const GQLProviderIncidents = () => {
  const get = (accountId, mapEntities, onLoad, onError) => {
    return (
      <GetIncidents
        onLoad={onLoad}
        onError={onError}
        accountId={accountId}
        mapEntities={mapEntities}
      />
    )
  }

  get.propTypes = {
    accountId: PropTypes.string,
  }
  const doAcknowledge = (
    accountId,
    serviceId,
    timestamp,
    data,
    mapEntities,
    onLoad,
    onError
  ) => {
    return (
      <Acknowledge
        onDone={onLoad}
        onError={onError}
        accountId={accountId}
        serviceId={serviceId}
        data={data}
        timestamp={timestamp}
        mapEntities={mapEntities}
      />
    )
  }

  return {
    GetIncidents: get,
    Acknowledge: doAcknowledge,
  }
}
