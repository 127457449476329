import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@material-ui/core'
import clsx from 'clsx'
import {
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Menu as MenuIcon,
  NetworkCheck,
  Error as ErrorIcon,
  GroupWork,
  People as PeopleIcon,
  ImageSearch,
} from '@material-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import UserMenu from './UserMenu'
import logo from './../assets/img/logo.svg'
import { Routes } from '../routes'
import CurrentPlan from './current-plan'

export const DrawerWidth = 240
const styles = (theme) => ({
  toolbar: {
    paddingRight: 0, // keep right padding when drawer closed
    display: 'flex',
    width: '100%',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarHolder: {
    flex: 'auto',
  },
  toolbarHolderHidden: {
    display: 'none',
  },
  appBar: {
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  overlay: {
    zIndex: theme.zIndex.drawer,
    position: 'absolute',
    left: DrawerWidth,
    right: 0,
    backgroundColor: '#000000aa',
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  menuButton: {
    marginRight: 0,
    flexGrow: 0,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: DrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  navLinkActive: {
    display: 'block',
    backgroundColor: '#0064f017',
    borderLeft: 'solid 10px #0064F0',
  },
  appBarImage: {
    flexGrow: 0,
    maxHeight: '50px',
    maxWidth: '30%',
  },
})

const AppBarAndDrawer = (props) => {
  const [open, setOpen] = useState(window.innerWidth / 3 > DrawerWidth)
  const history = useHistory()
  const currentPath = history.location.pathname
  const { user, plan, logout, classes, t } = props
  console.log('AppBarAndDrawer user', user)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const planName = plan.name ?? ''
  const GetLink = (props) => {
    const { to, children } = props
    return (
      <Link
        to={to}
        className={clsx(
          classes.navLink,
          currentPath === to && classes.navLinkActive
        )}
        onClick={handleDrawerClose}
      >
        {children}
      </Link>
    )
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, classes.toolbarHolder)}
          >
            <MenuIcon />
          </IconButton>
          <img
            className={clsx(classes.appBarImage, classes.toolbarHolder)}
            src={logo}
            alt="XCMONITOR logo"
          />
          <UserMenu
            className={classes.toolbarHolder}
            user={user}
            logout={logout}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <GetLink to={Routes.index}>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t('menu_side.dashboard')} />
            </ListItem>
          </GetLink>
          <GetLink to={Routes.overview}>
            <ListItem button>
              <ListItemIcon>
                <ImageSearch />
              </ListItemIcon>
              <ListItemText primary={t('menu_side.overview')} />
            </ListItem>
          </GetLink>
          <GetLink to={Routes.services}>
            <ListItem button>
              <ListItemIcon>
                <NetworkCheck />
              </ListItemIcon>
              <ListItemText primary={t('menu_side.services')} />
            </ListItem>
          </GetLink>
          <GetLink to={Routes.incidents}>
            <ListItem button>
              <ListItemIcon>
                <ErrorIcon />
              </ListItemIcon>
              <ListItemText primary={t('menu_side.incidents')} />
            </ListItem>
          </GetLink>
          {Routes.canAccess(Routes.teams, planName) && (
            <GetLink to={Routes.teams}>
              <ListItem button>
                <ListItemIcon>
                  <GroupWork />
                </ListItemIcon>
                <ListItemText primary={t('menu_side.teams')} />
              </ListItem>
            </GetLink>
          )}
          {Routes.canAccess(Routes.users, planName) && (
            <GetLink to={Routes.users}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu_side.users')} />
              </ListItem>
            </GetLink>
          )}
          <Divider />
          <GetLink to={Routes.subscription}>
            <ListItem button>
              <CurrentPlan planName={planName} withText={open} />
            </ListItem>
          </GetLink>
        </List>
        <Divider />
      </Drawer>
      <div
        className={open ? classes.overlay : classes.hidden}
        onClick={() => {
          handleDrawerClose()
        }}
      />
    </>
  )
}

export default withTranslation()(withStyles(styles)(AppBarAndDrawer))
