import React from 'react'
import Title from './Title'
import { withTranslation } from 'react-i18next'

export const errorMessageFormat = (code, message) => {
  return `: ${code}:${message}`
}
const ErrorCmp = (props) => {
  const { t, message, code } = props
  return (
    <>
      <Title>{`${t('something_went_wrong')}${errorMessageFormat(
        code,
        message
      )}`}</Title>
    </>
  )
}

export default withTranslation()(ErrorCmp)
