import { getDiff } from '../../../../utils/diff'

export const ACS_LOAD_START = 1
export const ACS_LOAD_ERR = 10
export const ACS_LOAD_DONE = 20
export const ACS_NO_ACCOUNT = 21
export const ACS_SAVING = 30

export const StateInitial = () => {
  return {
    type: ACS_LOAD_START,
    account: {},
    accountEdit: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedAccount = (data) => {
  return {
    type: ACS_LOAD_DONE,
    account: data,
    accountEdit: {},
    errors: {},
  }
}
export const StateNoAccount = {
  type: ACS_NO_ACCOUNT,
  account: {},
  errors: {},
}
export const StateError = (code, message) => {
  return {
    type: ACS_LOAD_ERR,
    code: code,
    message: message,
  }
}
export const StateSaving = {
  type: ACS_SAVING,
}

export const actionSaveAccount = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving,
      }
    },
  }
}
export const actionLoadedAccount = (data) => {
  const account = { ...data }
  return {
    reduce: () => {
      account.createdAt = account.createdAt || ''
      return StateLoadedAccount({ ...account })
    },
  }
}
export const actionNoAccount = () => {
  return {
    reduce: (state) => {
      return { ...state, ...StateNoAccount }
    },
  }
}
export const actionOnAccountChange = (data) => {
  const account = { ...data }
  return {
    reduce: (state) => {
      return getDiff(account, state, 'account')
    },
  }
}
