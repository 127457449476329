import { ExecuteGQLAsync } from '../../../utils/executeGraphQL'
import { Entities } from '../../entities'
import { FIELDS_NTF_TEAM } from '../../notifications/notification/edit/data/get'

export const FIELDS_BASE_TEAM = [
  'id',
  'createdAt',
  'updatedAt',
  'accountId',
  'name',
  'description',
  'active',
  'members',
  'notifications',
]
export const FIELDS_BASE_TEAM_SUB = {
  notifications: 'notifications {' + FIELDS_NTF_TEAM.join('\n') + '}',
}
export const FIELDS_TEAM = FIELDS_BASE_TEAM.map(
  (name) => FIELDS_BASE_TEAM_SUB[name] || name
).join('\n')
export const GET_TEAM = `
  query getTeams($where: TeamWhere!) {
    ${Entities.teams}(where: $where) {
      items {
        ${FIELDS_TEAM}
      }
    }
  }
`
export const GetTeams = (props) => {
  const { accountId, onLoad, onError } = props
  console.log('GetGetTeamsUser: id', props.id, ', accountId: ', accountId)
  const filter = { accountId: accountId }
  if (props.id) {
    filter.id = props.id
  }
  ExecuteGQLAsync({
    justFirst: props.justFirst ?? true,
    actionName: Entities.teams,
    variables: { where: { ...filter } },
    onDone: onLoad,
    onError: onError,
    mapper: props.mapper,
    query: GET_TEAM,
  })
}

export default GetTeams
