import {
  S_LOAD_START,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_SAVING,
  S_CREATED,
} from '../../../../utils/states-actions'
import EditProfileInfo from './views/edit-profile-info'
import { useEditProfileContext } from './context'
import React from 'react'
import SavingProfile from './views/profile-saving'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ErrorCmp from '../../../../components/error'
import { Routes } from '../../../../routes'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import TopBar from '../../../../components/topbar'
import LoadingView from '../../../../components/loading-view'

const styles = (theme) => ({
  root: {
    maxWidth: 700,
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
})

const EditProfileViews = (props) => {
  const hookRef = useEditProfileContext()
  const { state } = hookRef
  const { classes, t } = props
  const history = useHistory()

  const handleOnCreated = () => {
    history.replace(Routes.users)
  }
  const isNew = hookRef.getProfileField('id').value === 'new'

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        hookRef.loadProfile(hookRef.accountId, hookRef.id)
        return <LoadingView />
      case S_CREATED:
        return <></>
      case S_LOAD_DONE:
        return <EditProfileInfo {...hookRef} />
      case S_SAVING:
        return (
          <>
            {hookRef.save()}
            <SavingProfile />
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar
        titleText={t(`profile_edit.title${isNew ? '_new' : ''}`)}
        onBack={handleOnCreated}
      />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(EditProfileViews))
