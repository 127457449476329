import React from 'react'
import { ExecuteGQLWithBuilder } from '../../../utils/executeGraphQL'
import { Entity } from '../../entities'
import { FIELDS_INCIDENTS } from './get'
import { IncidentMapperDataToDomain } from './mapper'
import PropTypes from 'prop-types'

export const Acknowledge = (props) => {
  const { accountId, serviceId, timestamp, data } = props
  const filter = {
    accountId: accountId,
    serviceId: serviceId,
    timestamp: timestamp / 1000,
  }
  const builderData = {
    gql: `
      mutation Ack($input: IncidentAcknowledgeInput!, $where: IncidentWhereMutation!) {
        acknowledgeIncidents(input: $input, where: $where) {
          ${Entity.incident}s {
            ${FIELDS_INCIDENTS}
          }
        }
      }
    `,
    mutationName: 'acknowledgeIncidents',
    variables: { where: { ...filter }, input: { ...data } },
  }
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onDone}
      onError={props.onError}
      mapper={(src) => IncidentMapperDataToDomain(src, props.mapEntities)}
    />
  )
}
Acknowledge.propTypes = {
  mapEntities: PropTypes.func,
}

export default Acknowledge
