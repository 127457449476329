import React from 'react'
import {
  buildForGet,
  ExecuteGQL,
  ExecuteGQLDummy,
} from '../../../utils/executeGraphQL'
import { Entity } from '../../entities'
import { AnalyticsMapperDataToDomain } from './mapper'
import PropTypes from 'prop-types'

export const FIELDS_BASE_ANALYTICS = [
  'active_incidents_unknown',
  'accountId',
  'checks',
  'active_incidents',
  'active_incidents_warning',
  'active_incidents_unknown',
  'active_incidents_critical',
  'checks_critical',
  'checks_ok',
  'checks_unknown',
  'checks_warning',
  'downtime',
  'notifications',
  'services',
  'teams',
  'total_incidents_critical',
  'total_incidents_unknown',
  'total_incidents_warning',
  'total_incidents',
  'uptime',
  'users',
]
export const FIELDS_ANALYTICS = FIELDS_BASE_ANALYTICS.join('\n')

const GetAnalytics = (props) => {
  const { accountId, onLoad, onError } = props
  const { gql, mutationName, variables } = buildForGet(
    Entity.analytic,
    FIELDS_ANALYTICS,
    {
      accountId: accountId,
      filter: {
        year: 2022,
      },
    },
    's'
  )
  return props.useDummyData ? (
    <ExecuteGQLDummy
      justFirst={true}
      fakeSuccess={{
        accountId: '5d64c1f4-2a29-45b7-ae30-e33cdd1a9ea5',
        active_incidents: 1,
        active_incidents_unknown: null,
        active_incidents_warning: null,
        active_incidents_critical: null,
        checks: 45849,
        checks_critical: 55,
        checks_ok: 34724,
        checks_unknown: 1070,
        checks_warning: 12000,
        downtime: null,
        notifications: null,
        services: 5,
        teams: 1,
        total_incidents_critical: 0,
        total_incidents_unknown: 0,
        total_incidents_warning: 0,
        total_incidents: 0,
        uptime: null,
        users: 2,
      }}
      actionName={mutationName}
      variables={variables}
      mapper={(src) => AnalyticsMapperDataToDomain(src)}
      onDone={onLoad}
      onError={onError}
      query={gql}
    />
  ) : (
    <ExecuteGQL
      justFirst={true}
      actionName={mutationName}
      variables={variables}
      mapper={(src) => AnalyticsMapperDataToDomain(src)}
      onDone={onLoad}
      onError={onError}
      query={gql}
    />
  )
}
GetAnalytics.propTypes = {
  accountId: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  useDummyData: PropTypes.bool,
}

export default GetAnalytics
