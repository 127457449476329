import React from 'react'
import { FormLabel, TextareaAutosize, TextField } from '@material-ui/core'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'

export const EditTextArea = (props) => {
  const { getEditField, onEditFieldChange } = props
  const field = getEditField(props.field)
  const hasErrors = field.error && field.error.length > 0
  const trans =
    props.trans ||
    ((what) => {
      return what
    })
  return (
    <Row>
      <FormLabel component="label" htmlFor={props.field} error={hasErrors}>
        {props.label || props.field.replaceAll(/_/g, ' ')}
        {hasErrors && ` (${trans(field.error)})`}
      </FormLabel>
      <TextareaAutosize
        id={props.field}
        className={props.className}
        placeholder={props.label || props.field.replaceAll(/_/g, ' ')}
        value={props.format ? props.format(field.value) : field.value}
        minRows={props.rowsMin || 3}
        maxRows={props.rowsMax || 5}
        disabled={props.disabled}
        onChange={onEditFieldChange}
        margin="normal"
        variant="outlined"
      />
    </Row>
  )
}
EditTextArea.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.func,
  trans: PropTypes.func,
  error: PropTypes.string,
  rowsMax: PropTypes.number,
  rowsMin: PropTypes.number,
  onEditFieldChange: PropTypes.func,
  getEditField: PropTypes.func,
  maxLength: PropTypes.number,
}
const EditField = (props) => {
  const { getEditField, onEditFieldChange } = props
  const field = getEditField(props.field)
  const trans =
    props.trans ||
    ((what) => {
      return what
    })
  const inputProps = {
    'data-testid': props.field,
    maxLength: props.maxLength,
  }
  if (props.fieldType === 'time' && props.timestep) {
    inputProps.step = props.timestep
  }

  const editField = (
    <TextField
      id={props.field}
      label={props.label || props.field.replaceAll(/_/g, ' ')}
      value={props.format ? props.format(field.value) : field.value}
      error={field.error && field.error.length > 0}
      helperText={trans(field.error)}
      className={props.className}
      multiline={props.multiline || false}
      maxRows={props.rowsMax || 5}
      disabled={props.disabled}
      onChange={onEditFieldChange}
      margin="normal"
      variant="outlined"
      fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
      inputRef={props.forwardRef}
      type={props.fieldType || 'text'}
      inputProps={inputProps}
      InputLabelProps={props.inputLabelProps}
    />
  )
  const asRow = props.asRow === undefined ? true : props.asRow
  return asRow ? <Row>{editField}</Row> : <>{editField}</>
}
export default EditField

EditField.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.func,
  trans: PropTypes.func,
  error: PropTypes.string,
  inputLabelProps: PropTypes.object,
  rowsMax: PropTypes.number,
  rowsMin: PropTypes.number,
  onEditFieldChange: PropTypes.func,
  getEditField: PropTypes.func,
  maxLength: PropTypes.number,
  asRow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  timestep: PropTypes.number,
  fieldType: PropTypes.oneOf([
    'text',
    'time',
    'url',
    'date',
    'number',
    'email',
    'password',
    'nickname',
    'given-name',
    'family-name',
    'phone',
    'zip',
    'city',
    'address',
    'state',
    'country',
    'createdAt',
  ]),
}
