export const tawkTo = (propertyId, key) => {
  if (!window) {
    throw new Error('DOM is unavailable')
  }

  window.Tawk_API = window.Tawk_API || {}
  window.Tawk_LoadStart = new Date()

  const tawk = document.getElementById('tawkId')
  if (tawk) {
    // Prevent TawkTo to create root script if it already exists
    return window.Tawk_API
  }

  if (!key) {
    throw new Error(
      'Key not provided. Get key from tawk dashboard - Direct Chat Link'
    )
  }

  const script = document.createElement('script')
  script.id = 'tawkId'
  script.async = true
  script.src = 'https://embed.tawk.to/' + propertyId + '/' + key
  script.charset = 'UTF-8'
  script.setAttribute('crossorigin', '*')

  const firstscriptTag = document.getElementsByTagName('script')[0]
  if (!firstscriptTag || !firstscriptTag.parentNode) {
    throw new Error('DOM is unavailable')
  }

  firstscriptTag.parentNode.insertBefore(script, firstscriptTag)
}
export default tawkTo
