export const Limits = {
  organizationDesc: 100,
  certExpireMin: 1,
  certExpireMax: 45,
  phoneNumberCode: 4,
  notificationsInterval: { min: 30, default: 30, max: 60 * 24 },
  user: {
    name: {
      given: { min: 1, max: 40 },
      family: { min: 1, max: 40 },
    },
  },
}
