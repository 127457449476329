import React from 'react'
import { GQLProviderEvents } from './data/provider'
import { EventsContextProvider } from './context'
import { useAppContext } from '../../../context/app-context'
import EventsViews from './views'

const EventsView = (props) => {
  const { appData } = useAppContext()
  console.log('props.match', props.match)
  const data = {
    account: appData.account || props.account,
    serviceId: props.serviceId ?? props.match.params.serviceId,
    timestamp: props.timestamp ?? props.match.params.timestamp,
  }
  return (
    <EventsContextProvider data={data} provider={GQLProviderEvents()}>
      <EventsViews />
    </EventsContextProvider>
  )
}

export default EventsView
