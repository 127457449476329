import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation, withTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { eventToText } from '../../../utils/eventsExt'
import { useHistory } from 'react-router'
import { Routes } from '../../../routes'
import { AssignmentTurnedIn, Check } from '@material-ui/icons'
import { getEntity } from '../../notifications/notification/edit/data/provider'
import { GetStatusColor } from '../../services/checks/service-utils'
import { DateFormatter, SecondsPrettify } from '../../../utils/date-utils'
import { Row } from 'reactstrap'
import { useAppContext } from '../../../context/app-context'
import XCModal from '../../../components/modal-view'
import Loading from '../../../components/Loading'
import { ShouldAllowAcknowledge } from '../data/utils'
import { Entity } from '../../entities'
import { ProfileView } from '../../users/manage/views/manage-user-info'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  modalDate: {
    textAlign: 'right',
    fontSize: '14px',
    paddingBottom: '14px',
  },
})

const IncidentItem = (props) => {
  const { classes, inc, state, doAcknowledge, toggleModal } = props
  const history = useHistory()
  const { t } = useTranslation()
  const { appData } = useAppContext()
  const handleOpenIncident = (incident) => {
    history.push(Routes.incident(incident.serviceId, incident.timestamp / 1000))
  }

  const handleOpenUser = (ent) => {
    console.log('handleOpenUser', ent)
    if (ent.entity !== Entity.user) {
      return
    }
    history.push(Routes.userManage(appData.account.id, ent.entityId))
  }

  const AckItem = (props) => {
    const { ent } = props
    const isUser = ent.entity === Entity.user
    return (
      <>
        <ProfileView
          id={ent.id}
          withAvatar={isUser}
          title={isUser ? ent.title : ent.entity}
          subtitle={isUser ? ent.subtitle : ent.entityId + ' ' + ent.source}
          onClick={() => handleOpenUser(ent)}
        />
        <div className={classes.modalDate}>
          {DateFormatter.format(ent.acknowledgedAt)}
        </div>
      </>
    )
  }
  const handleOpenEntity = (ent, ackBy) => {
    console.log('handleOpenEntity', ent)
    toggleModal({
      titleText: t('incidents.acknowledged_by'),
      subtitle: (
        <Box>
          {ackBy.map((ack) => (
            <AckItem ent={ack} key={ack.acknowledgedAt} />
          ))}
        </Box>
      ),
    })
  }
  const openAction = () => handleOpenIncident(inc)
  const ackInc = () => doAcknowledge(inc.id, inc.serviceId, inc.timestamp)
  const isMarkedForAck = state.ackInProgressIndexed[inc.id] ?? -1
  const allowAck = ShouldAllowAcknowledge(inc, appData.user.id)
  const ActionAcknowledge = () => {
    return isMarkedForAck >= 0 ? (
      <Loading />
    ) : (
      <IconButton
        data-testid={`acknowledge-${inc.id}`}
        size={'small'}
        onClick={ackInc}
        disabled={!allowAck}
      >
        <Check color={allowAck ? 'primary' : 'disabled'} />
      </IconButton>
    )
  }

  return (
    <TableRow key={inc.id} className={classes.row}>
      <TableCell onClick={openAction}>{inc.name}</TableCell>
      <TableCell onClick={openAction}>
        <AssignmentTurnedIn style={{ color: GetStatusColor(inc.code) }} />
      </TableCell>
      <TableCell onClick={openAction}>
        {DateFormatter.format(inc.timestamp)}
      </TableCell>
      <TableCell onClick={openAction}>
        {inc.closedAt && inc.closedAt > 0
          ? DateFormatter.format(inc.closedAt)
          : ''}
      </TableCell>
      <TableCell onClick={openAction}>
        {(inc.duration && SecondsPrettify(inc.duration)) ?? ''}
      </TableCell>
      <TableCell onClick={openAction}>
        {(inc.acknowledgedBy ?? []).map((ent, ind) => {
          const openEntity = (ev) => {
            ev.stopPropagation()
            handleOpenEntity(ent, inc.acknowledgedBy)
          }
          return (
            <Row key={ind + '-' + ent.id}>
              <Button
                size={'small'}
                autoCapitalize={'lower'}
                data-testid={`${inc.id}-entity-${ent.id}`}
                variant="text"
                color="primary"
                onClick={openEntity}
              >
                {
                  (
                    getEntity(ent.entity, ent.entityId) ?? {
                      name: ent.entity + ' - ' + ent.source,
                    }
                  ).name
                }
              </Button>
            </Row>
          )
        })}
      </TableCell>
      <TableCell className={classes.action}>
        <ActionAcknowledge />
      </TableCell>
    </TableRow>
  )
}

const IncidentsList = (props) => {
  const { state, t, classes, doAcknowledge } = props
  const [modalData, setModalData] = useState(null)
  const [filterState, setFilterState] = useState({ nameFilter: '' })
  const handleFilterChange = (event) => {
    const newFilter = { ...filterState }
    newFilter[`${event.target.id}`] = eventToText(event)
    setFilterState(newFilter)
  }
  const hasIncidents = state.incidents.length > 0
  console.log('incidents state', state)
  const IncidentsTable = () => {
    if (!hasIncidents) {
      return null
    }
    return (
      <>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell key="name">{t('incidents.name')}</TableCell>
              <TableCell key="code">{t('incidents.code')}</TableCell>
              <TableCell key="started">{t('incidents.started')}</TableCell>
              <TableCell key="closed">{t('incidents.closed')}</TableCell>
              <TableCell key="duration">{t('incidents.duration')}</TableCell>
              <TableCell key="acknowledged">
                {t('incidents.acknowledged_by')}
              </TableCell>
              <TableCell key="actions" className={classes.action}>
                {props.actionsTitle || ''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.incidents.map((inc) => (
              <IncidentItem
                key={inc.id}
                inc={inc}
                state={state}
                doAcknowledge={doAcknowledge}
                toggleModal={setModalData}
                classes={props.classes}
              />
            ))}
          </TableBody>
        </Table>
        {modalData && (
          <XCModal
            title={modalData.title}
            subtitle={modalData.subtitle}
            titleText={modalData.titleText}
            subtitleText={modalData.subtitleText}
            onDone={() => setModalData(null)}
          />
        )}
      </>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box
        className={classes.appBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={hasIncidents ? 'space-between' : 'center'}
        p={1}
      >
        {/* SearchBox is hidden via CSS, check styles on top */}
        {hasIncidents && (
          <TextField
            id={'nameFilter'}
            size={'small'}
            label={t('incidents.search')}
            className={classes.textField}
            value={filterState.nameFilter}
            onChange={handleFilterChange}
            variant="outlined"
            type="text"
          />
        )}
      </Box>
      <IncidentsTable />
    </div>
  )
}
export default withTranslation()(withStyles(styles)(IncidentsList))
