import React, { createContext, useContext, useReducer } from 'react'
import {
  actionAcknowledge,
  actionLoadedIncidents,
  actionNoIncidents,
  actionOnAcknowledged,
  StateInitial,
} from './states-actions'
import { reducer } from '../../components/reducer'
import { actionLoadFailed } from '../../utils/states-actions'
import { ACK_SOURCE } from './data/mapper'
import { Entity } from '../entities'

export const useIncidents = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { account, user, users } = data

  const Load = (accountId) => {
    console.log('::LoadIncidents:', accountId)
    const onError = (err) => {
      console.log('::LoadIncidents llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('::LoadIncidents llOnLoad:', data)
      dispatch(
        data.length > 0 ? actionLoadedIncidents(data) : actionNoIncidents
      )
    }
    return provider.GetIncidents(accountId, mapEntities, onLoad, onError)
  }

  const doDelete = (incId) => {
    console.log('doDeleteIncident: ', incId)
  }

  const mapEntities = (ent) => {
    if (ent.entity !== Entity.user) {
      return {}
    }
    const usr = users.filter((usr) => usr.id === ent.entityId)[0] ?? {}
    return { title: usr.name, subtitle: usr.email }
  }
  const addAcknowledge = (id, serviceId, timestamp) => {
    dispatch(actionAcknowledge(id, serviceId, timestamp))
  }
  const acknowledgeIncident = (ackData) => {
    const data = {
      acknowledgedBy: {
        entityId: user.id,
        entity: Entity.user,
        source: ACK_SOURCE.web,
      },
    }
    console.log('acknowledgeIncident: ', ackData, data)
    const onError = (err) => {
      console.log('::acknowledgeIncident llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionOnAcknowledged(ackData.id, data))
    }

    return provider.Acknowledge(
      account.id,
      ackData.serviceId,
      ackData.timestamp,
      data,
      mapEntities,
      onDone,
      onError
    )
  }

  return {
    state,
    account,
    loadIncidents: Load,
    doDeleteIncident: doDelete,
    doAcknowledge: addAcknowledge,
    acknowledge: acknowledgeIncident,
  }
}

export const IncidentsContext = createContext({})
export const useIncidentsContext = () => useContext(IncidentsContext)
export const IncidentsContextProvider = (props) => {
  const hookRef = useIncidents(props.data, props.provider)

  return (
    <IncidentsContext.Provider value={hookRef}>
      {props.children}
    </IncidentsContext.Provider>
  )
}
