import React, { createContext, useContext, useReducer } from 'react'
import {
  actionCreatedSub,
  actionCreateSub,
  actionLoadedPlans,
  actionNoPlans,
  StateInitial,
} from './states-actions'
import { reducer } from '../../components/reducer'
import { actionLoadFailed } from '../../utils/states-actions'

export const usePlans = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { plan } = data

  const Load = () => {
    const onError = (err) => {
      console.log('::LoadPlans llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(data.length > 0 ? actionLoadedPlans(data) : actionNoPlans)
    }
    return provider.GetPlans(onLoad, onError)
  }

  const Create = (src) => {
    console.log('CONTEXT: createSub: ', src)
    const onError = (err) => {
      console.log('::createSub llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionCreatedSub(data))
    }

    return provider.CreateSub('', state.teamEdit, onDone, onError)
  }

  const doCreateSub = (data, cPlan, planId) => {
    actionCreateSub({ data: data, plan: cPlan, planId: planId })
  }

  return {
    state,
    plan,
    load: Load,
    doCreateSub,
    createSub: Create,
  }
}

export const PlansContext = createContext({})
export const usePlansContext = () => useContext(PlansContext)
export const PlansContextProvider = (props) => {
  const hookRef = usePlans(props.data, props.provider)

  return (
    <PlansContext.Provider value={hookRef}>
      {props.children}
    </PlansContext.Provider>
  )
}
