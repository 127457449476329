import React, { useReducer, createContext, useContext } from 'react'
import {
  actionCodeSent,
  actionOnFieldChange,
  actionPhoneVerified,
  actionRequestCode,
  actionVerifyPhone,
  StateInitial,
} from './states-actions'
import { eventToText } from '../../../../../utils/eventsExt'
import { useAppContext } from '../../../../../context/app-context'
import { reducer } from '../../../../../components/reducer'
import { actionLoadFailed } from '../../../../../utils/states-actions'

export const VerifyPhoneContext = createContext({})

export const useVerifyPhone = (data, provider) => {
  const { user } = data
  const initState = StateInitial(user)
  const accountId = data.accountId || ''
  const [state, dispatch] = useReducer(reducer, initState)
  const { appData, setAppData, updateUser } = useAppContext()
  console.log('appData, setAppData', appData, setAppData)

  const RequestCode = () => {
    console.log('will RequestCode')
    const onError = (err) => {
      console.log('::RequestCode llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onLoad = (data) => {
      console.log('RequestCode', JSON.parse(JSON.stringify(data)))
      dispatch(actionCodeSent())
    }

    return provider.doRequestCode(user.id, 'phone_number', onLoad, onError)
  }

  const VerifyPhone = (code) => {
    console.log('will VerifyPhone')
    const onError = (err) => {
      console.log('::VerifyPhone llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onLoad = (data) => {
      console.log('VerifyPhone: onLoad: ', JSON.parse(JSON.stringify(data)))
      dispatch(actionPhoneVerified(data))
      updateUser(data)
    }

    return provider.doVerifyUserAttribute(
      user.id,
      'phone_number',
      code,
      onLoad,
      onError
    )
  }

  const doRequestCode = () => {
    console.log('doRequestCode')
    dispatch(actionRequestCode())
  }

  const doVerifyPhone = () => {
    console.log('doVerifyPhone')
    dispatch(actionVerifyPhone())
  }

  const onFieldChange = (value) => {
    console.log('onFieldChange', value)
    console.log('onFieldChange type', value.target)
    const keyValPair = {}
    keyValPair[`${value.target.name || value.target.id}`] = eventToText(value)
    dispatch(actionOnFieldChange({ ...keyValPair }))
  }

  const getField = (name) => {
    const value =
      typeof state.phoneEdit[name] === 'undefined'
        ? state.phone[name]
        : state.phoneEdit[name]
    return {
      value: value || '',
      error: state.errors[name] || null,
    }
  }

  return {
    accountId,
    state,
    dispatch,
    getField,
    sendRequestCode: RequestCode,
    sendVerifyPhone: VerifyPhone,
    onRequestCode: doRequestCode,
    onVerifyPhone: doVerifyPhone,
    onFieldChange,
  }
}
export const useVerifyPhoneContext = () => useContext(VerifyPhoneContext)

export const VerifyPhoneContextProvider = (props) => {
  const hookRef = useVerifyPhone(props.data, props.provider)

  return (
    <VerifyPhoneContext.Provider value={hookRef}>
      {props.children}
    </VerifyPhoneContext.Provider>
  )
}
