import {
  CheckRateUnit,
  CheckType,
  TimeUnitInSeconds,
  TimeUnitInSecondsValues,
} from '../service-utils'

export const ServiceCheckMapperDataToDomain = (src) => {
  const config = src.checkConfig
  const rate = checkRateDataToDomain(src.checkRate)

  return {
    id: src.id,
    name: src.name,
    active: src.active,
    type: CheckType[src.type],
    url: src.url,
    createdAt: src.createdAt,
    notificationsInterval: src.notificationsInterval ?? 30,
    ssl: config.ssl,
    hostname: config.hostname,
    followRedirects: config.followRedirects,
    port: config.port,
    userAgent: config.userAgent,
    checkRateUnit: rate.unit,
    checkRate: rate.rate,
    timeout: config.timeout,
    ip: config.ip,
    dnsIp: config.dnsIp,
    beforeExpire: config.beforeExpire,
    dnsQuery: config.dnsQuery,
    acceptCName: config.acceptCName,
    quit: config.quit,
    expectedString: config.expectedString,
    smtpFQDN: config.smtpFQDN,
    smtpFrom: config.smtpFrom,
    startTLS: null,
    authPass: config.authPass,
    authUser: config.authUser,
    options: '{}',
    account: {
      id: src.accountId,
    },
    teams: (src.serviceOf ?? []).map((team) => {
      return {
        id: team,
      }
    }),
  }
}
export const ServiceCheckMapperDomainToData = (src, clearUndefined = true) => {
  console.log('ServiceCheckMapperDomainToData: ', src)
  const result = {
    checkRate: checkRateDomainToData(src),
    name: src.name,
    active: src.active,
    notificationsInterval: src.notificationsInterval,
    type: checkTypeDomainToData(src.type),
    checkConfig: {
      acceptCName: src.acceptCName,
      hostname: src.url ?? src.hostname,
      authUser: src.authUser,
      authPass: src.authPass,
      beforeExpire: src.beforeExpire,
      cookieStorage: src.cookieStorage,
      dnsQuery: src.dnsQuery,
      followRedirects: src.followRedirects,
      ip: src.ip,
      port: src.port,
      quit: src.quit,
      expectedString: src.expectedString,
      smtpFQDN: src.smtpFQDN,
      smtpFrom: src.smtpFrom,
      ssl: src.ssl,
      timeout: src.timeout,
      url: src.url,
      userAgent: src.userAgent,
    },
  }
  return clearUndefined ? removeUndefined(result) : result
}
export const checkTypeDomainToData = (domainType) => {
  const typeValues = Object.values(CheckType)
  const typeKeys = Object.keys(CheckType)
  console.log(
    'checkTypeDomainToData: ',
    domainType,
    'typeValues',
    typeValues,
    'typeKeys',
    typeKeys
  )
  if (typeof domainType === 'undefined') {
    return undefined
  }
  for (const typeKey of typeKeys) {
    if (CheckType[typeKey] === domainType) {
      return typeKey
    }
  }
  return 'UNKNOWN'
}
export const checkRateDomainToData = (src) => {
  console.log('checkRateDomainToData: ', src)
  if (
    typeof src.checkRateUnit === 'undefined' ||
    typeof src.checkRate === 'undefined'
  ) {
    return undefined
  }
  console.log('multi', TimeUnitInSecondsValues[src.checkRateUnit])
  // : src.checkRate * CheckRateUnit[src.checkRateUnit]
  // CheckRateUnit.constant is temporary not available on Api
  return src.checkRateUnit === CheckRateUnit.constant
    ? 30
    : src.checkRate * TimeUnitInSecondsValues[src.checkRateUnit]
}
export const checkRateDataToDomain = (rate) => {
  if (rate < 30) {
    return {
      rate: 0,
      unit: CheckRateUnit.constant,
    }
  }
  let diff = rate / TimeUnitInSeconds.day
  if (diff >= 1) {
    return {
      rate: 24,
      unit: CheckRateUnit.hours,
    }
  }
  diff = rate / TimeUnitInSeconds.hour
  if (diff >= 1) {
    return {
      rate: Math.floor(diff),
      unit: CheckRateUnit.hours,
    }
  }
  diff = rate / TimeUnitInSeconds.minute
  if (diff >= 1) {
    return {
      rate: Math.floor(diff),
      unit: CheckRateUnit.minutes,
    }
  }
  return {
    rate: 30,
    unit: CheckRateUnit.seconds,
  }
}

const removeUndefined = (obj) => {
  if (obj === null || obj === undefined) {
    return obj
  }
  const keys = Object.keys(obj)
  for (const index in keys) {
    const keysKey = keys[index]
    if (typeof obj[keysKey] === 'undefined') {
      delete obj[keysKey]
      continue
    }
    if (typeof obj[keysKey] === 'object') {
      obj[keysKey] = removeUndefined(obj[keysKey])
      if (obj[keysKey] && Object.keys(obj[keysKey]).length === 0) {
        delete obj[keysKey]
      }
    }
  }
  return obj
}

export const RemoveNull = (obj) => {
  if (obj === null || obj === undefined) {
    return obj
  }
  const keys = Object.keys(obj)
  for (const index in keys) {
    const keysKey = keys[index]
    if (obj[keysKey] === null) {
      delete obj[keysKey]
      continue
    }
    if (typeof obj[keysKey] === 'object') {
      obj[keysKey] = RemoveNull(obj[keysKey])
      if (obj[keysKey] && Object.keys(obj[keysKey]).length === 0) {
        delete obj[keysKey]
      }
    }
  }
  return obj
}
