import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import {
  FormGroup,
  FormLabel,
  Paper,
  RadioGroup,
  useTheme,
} from '@material-ui/core'
import { PlanType } from '../../../components/current-plan'
import { RadioItem } from '../../../components/radio-item'
import { eventToText } from '../../../utils/eventsExt'
import { PlanTemplate } from './plan-template'

const styles = (theme) => {
  console.log('theme', theme)
  return {
    root: {
      display: 'flex',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      position: 'relative',
      spacing: theme.spacing(2),
    },
    paperSelected: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      borderColor: '#0064F0',
    },
    spanHighlight: {
      color: 'secondary',
      fontWeight: 'bold',
    },
    fixedHeight: {
      height: '100%',
    },
    divFeaturesContaines: {
      paddingBottom: theme.spacing(6),
      height: '100%',
    },
    divFeatures: {
      color: theme.colors.primary,
      paddingBottom: theme.spacing(2),
    },
    planHolder: {
      display: 'flex',
      padding: '8px',
    },
    plan: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      flexDirection: 'row',
      flex: 1,
    },
    planButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      marginTop: theme.spacing(2),
    },
    planButtonCurrent: {
      marginTop: theme.spacing(2),
    },
    intervalHolder: {
      padding: theme.spacing(2),
    },
    intervalHolderLabel: {
      padding: theme.spacing(2),
    },
  }
}

const List = (props) => {
  // const { plan, state, classes, t, doCreateSub } = props
  const { plan, state, classes, t } = props
  const theme = useTheme()
  const planName = (plan && plan.name) || PlanType.free
  // const PayPalButton = window.paypal.Buttons.driver('react', {
  //   React,
  //   ReactDOM,
  // })
  const [data, setData] = useState({ interval: 'per_month' })
  const GetPlan = (props) => {
    return (
      <PlanTemplate
        isCurrentPlan={props.current === props.name}
        planName={props.name}
        price={props.price}
        classes={classes}
        t={t}
        theme={theme}
      />
    )
  }
  // const createSubscription = (data, actions, cPlan, planId) => {
  //   console.log('createSubscription for plan', cPlan, data, actions, planId)
  //   return actions.subscription.create({
  //     plan_id: planId,
  //   })
  // }
  //
  // const onApprove = (data, actions, cPlan, planId) => {
  //   console.log('onApprove for plan', cPlan, data, actions, planId)
  //   doCreateSub(data, cPlan, planId)
  //   // return actions.subscription.capture()
  // }
  const onIntervalChange = (event) => {
    console.log(eventToText(event))
    return setData({ interval: eventToText(event) })
  }
  console.log('prices: ', state.prices)
  return (
    <Paper>
      <FormGroup row className={classes.intervalHolder}>
        <FormLabel component="legend" className={classes.intervalHolderLabel}>
          {t('subscription.interval')}
        </FormLabel>
        <RadioGroup
          row
          aria-label="sub-interval"
          name="sub-interval"
          id="sub-interval"
          value={data.interval}
          onChange={onIntervalChange}
        >
          <RadioItem
            value={'per_month'}
            testId={'interval-per-month'}
            label={t('subscription.per_month').toUpperCase()}
          />
          <RadioItem
            value={'per_year'}
            testId={'interval-per-year'}
            label={t('subscription.per_year').toUpperCase()}
          />
        </RadioGroup>
      </FormGroup>
      <Paper className={classes.planHolder} flex={'true'}>
        {Object.values(PlanType)
          .filter((plnType) => plnType !== PlanType.free)
          .map((type, index) => {
            console.log('data.interval: ', data.interval)
            console.log('type: ', type)
            console.log('index: ', index)
            console.log('pppp: ', state.prices[data.interval])
            const ppInfo = state.prices[data.interval][type] ?? {
              price: 0,
              planId: 'no-plan',
            }
            console.log('ppInfo: ', JSON.parse(JSON.stringify(ppInfo)))
            return (
              <GetPlan
                key={index}
                current={planName}
                name={type}
                price={ppInfo.price}
                planId={ppInfo.planId || 'free'}
              />
            )
          })}
      </Paper>
    </Paper>
  )
}
export default withTranslation()(withStyles(styles)(List))
