import React from 'react'
import {
  buildForUpdate,
  CacheChangeType,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { updateCache } from './provider'
import { ACCOUNT_FIELDS } from './get-account'

export const UpdateAccount = (props) => {
  // console.log('UpdateAccount: ', props)
  const { data, accountId } = props
  const changed = { ...data }
  const builderData = buildForUpdate(
    Entity.account,
    ACCOUNT_FIELDS,
    { id: accountId },
    changed
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onLoad}
      onError={props.onError}
      cacheModifyBlock={(cache, updated) => {
        updateCache(
          cache,
          { id: accountId, ...updated.accounts[0] },
          CacheChangeType.update
        )
      }}
    />
  )
}

export default UpdateAccount
