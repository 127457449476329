import React from 'react'
import { buildForGet, ExecuteGQL } from '../../../utils/executeGraphQL'
import PropTypes from 'prop-types'
import { Filters } from '../../services/checks/states-actions'
import { Entity } from '../../entities'
import { LatestChecksMapperDataToDomain } from './mapper'
import { QUERY_CHECK_CONFIG_FIELDS } from '../../services/checks/data/get-service-checks'

export const FIELDS_BASE_CHECK = [
  'id',
  'accountId',
  'name',
  'awsRegion',
  'checkAttempt',
  'idAndTimestamp',
  'lamdaIPAddress',
  'info',
  'returnCode',
  'remoteIPAddress',
  'serviceOf',
  'srcGeoIP',
  'timestamp',
  'perfData',
  'owner',
  'checkConfig',
  'dstGeoIP',
  'error',
]

const FIELDS_BASE_INFO = ['code', 'message']
const FIELDS_BASE_PERF = ['size', 'time']
const FIELDS_BASE_GEO = [
  'city',
  'eu',
  'll',
  'metro',
  'region',
  'timezone',
  'country',
]
export const FIELDS_BASE_CHECK_SUB = {
  info: 'info {' + FIELDS_BASE_INFO.join('\n') + '}',
  srcGeoIP: 'srcGeoIP {' + FIELDS_BASE_GEO.join('\n') + '}',
  dstGeoIP: 'dstGeoIP {' + FIELDS_BASE_GEO.join('\n') + '}',
  perfData: 'perfData {' + FIELDS_BASE_PERF.join('\n') + '}',
  checkConfig: 'checkConfig {' + QUERY_CHECK_CONFIG_FIELDS.join('\n') + '}',
  error: 'error {' + FIELDS_BASE_INFO.join('\n') + '}',
}
export const FIELDS_CHECK_RESULT = FIELDS_BASE_CHECK.map(
  (name) => FIELDS_BASE_CHECK_SUB[name] || name
).join('\n')

export const GetCheckResults = (props) => {
  const { accountId, onLoad, onError } = props
  console.log('GetCheckResults', props)
  const filter = {
    accountId: accountId,
  }
  if (props.id) {
    filter.id = props.id
  }
  const { gql, mutationName, variables } = buildForGet(
    Entity.check,
    FIELDS_CHECK_RESULT,
    filter
  )

  console.log('QUERY: ', mutationName, variables)
  return (
    <ExecuteGQL
      justFirst={false}
      actionName={mutationName}
      pollInterval={props.id ? 0 : 30000}
      fetchPolicy={'no-cache'}
      mapper={LatestChecksMapperDataToDomain}
      forceFetch={props.forceFetch}
      variables={variables}
      onDone={onLoad}
      onError={onError}
      query={gql}
    />
  )
}
GetCheckResults.propTypes = {
  filterBy: PropTypes.oneOf([Filters.user, Filters.account, Filters.team])
    .isRequired,
  user: PropTypes.string,
  accountId: PropTypes.string,
  teamId: PropTypes.string,
  type: PropTypes.number,
}
export default GetCheckResults
