import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation, getI18n } from 'react-i18next'
import EditField from '../../../../../components/edit-field'
import SaveButton from '../../../../../components/save-button'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { Countries } from '../../../../../utils/countries'
import { Row } from 'reactstrap'

const styles = (theme) => ({
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
})

const EditAccountInfo = (props) => {
  const { getEditField, onFieldChange, hasChanges, doUpdate, t } = props
  const formatter = new Intl.DateTimeFormat(getI18n().language)
  const selCountry = getEditField('country').value || null

  return (
    <>
      <EditField
        field="name"
        label={t('account_edit.name')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditField
        fieldType="address"
        field="address"
        label={t('address.title')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditField
        fieldType="address"
        field="address_2"
        label={t('address.title_2')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditField
        fieldType="city"
        field="city"
        label={t('address.city')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditField
        fieldType="state"
        field="state"
        label={t('address.state')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <Autocomplete
        disablePortal
        id="country"
        options={Countries}
        getOptionLabel={(option) => option.label ?? option ?? null}
        onChange={(event) => {
          console.log('event.target: ', event.target)
          console.log('event.target.id: ', event.target.id)
          console.log('event.target.innerText: ', event.target.innerText)
          console.log('event.target.value: ', event.target.value)
          onFieldChange({
            target: { id: 'country', value: event.target.innerText },
          })
        }}
        label={t('address.country')}
        value={selCountry ? { label: selCountry } : null}
        getOptionSelected={(option, value) => {
          return option.label === value.label
        }}
        renderOption={(props, option) => {
          return (
            <Row>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${props.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${props.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {props.label}
            </Row>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('address.country')}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'country',
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <EditField
        fieldType="zip"
        field="zip"
        label={t('address.zip')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <EditField
        fieldType="createdAt"
        field="createdAt"
        format={(iVal) => {
          console.log('iVal: ', iVal)
          return iVal ? formatter.format(new Date(iVal)) : iVal
        }}
        label={t('account_edit.created_at')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      <SaveButton hasChanges={hasChanges} handleClick={doUpdate} />
    </>
  )
}
export default withTranslation()(withStyles(styles)(EditAccountInfo))
