import { emptyTo, isBlank } from '../../../../utils/stringExt'
import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_START,
  S_SAVING,
  StateError,
} from '../../../../utils/states-actions'
import { VerifyPhoneStates } from './verify_phone/states-actions'

export const INVITATION_SENT = 0
export const INVITATION_ACCEPTED = 1

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    user: {},
    profileEdit: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedUser = (data) => {
  return {
    type: S_LOAD_DONE,
    user: data,
    phoneState: VerifyPhoneStates.calculateFor(data),
    profileEdit: {},
    errors: {},
  }
}
export const StateCreatedUser = (data) => {
  return {
    type: S_CREATED,
    user: data,
    phoneState: VerifyPhoneStates.calculateFor(data),
    profileEdit: {},
    errors: {},
  }
}

export const StateSaving = () => {
  return {
    type: S_SAVING,
  }
}

export const actionSaveProfile = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving(),
      }
    },
  }
}
export const actionCreateProfileFailed = (data) => {
  const { code, message } = data
  return {
    reduce: (state) => {
      return { ...state, ...StateError(code, message) }
    },
  }
}
export const actionLoadedProfile = (data) => {
  const user = { ...data }
  return {
    reduce: () => {
      return StateLoadedUser(user)
    },
  }
}
export const actionCratedProfile = (data) => {
  const user = { ...data }
  return {
    reduce: () => {
      return StateCreatedUser(user)
    },
  }
}
export const actionValidationFailed = (err) => {
  const errors = err
  return {
    reduce: (state) => {
      return {
        ...state,
        errors,
      }
    },
  }
}
export const actionOnProfileChange = (data) => {
  const userSrc = { ...data }
  console.log('actionOnProfileChange userSrc', userSrc)
  return {
    reduce: (state) => {
      const changedKey = Object.keys(userSrc)[0]
      const profileEdit = { ...state.profileEdit, ...userSrc }
      const user = state.user
      if (
        (user[changedKey] === null && profileEdit[changedKey] === '') ||
        user[changedKey] === profileEdit[changedKey]
      ) {
        // Undo Name
        const name = handleUndoName(changedKey, profileEdit, user)
        if (name) {
          profileEdit.name = name
        } else {
          delete profileEdit.name
        }
        delete profileEdit[changedKey]
      } else {
        const name = handleChangeName(changedKey, profileEdit, user)
        if (name) {
          profileEdit.name = name
        } else {
          delete profileEdit.name
        }
      }
      return { ...state, profileEdit }
    },
  }
}
const handleUndoName = (changedKey, changed, original) => {
  let resultName
  if (!changed.name) {
    return resultName
  }
  if (changedKey === 'given_name') {
    if (changed.family_name) {
      resultName = original.given_name + ' ' + changed.family_name
    }
  } else if (changedKey === 'family_name') {
    if (changed.given_name) {
      resultName = changed.given_name + ' ' + original.family_name
    }
  }
  return resultName
}
const handleChangeName = (changedKey, changed, original) => {
  const resultName = undefined
  if (['given_name', 'family_name'].indexOf(changedKey) < 0) {
    return resultName
  }
  const gName = changed.given_name || ''
  const fName = changed.family_name || ''
  const name = gName + fName
  if (isBlank(name)) {
    return resultName
  }
  if (original.name === original.email && (isBlank(gName) || isBlank(fName))) {
    // First time after SignUp, possible no Given/Family names case
    return emptyTo(gName, fName)
  }
  return (
    emptyTo(gName, original.given_name) +
    ' ' +
    emptyTo(fName, original.family_name)
  )
}
