import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Subtitle from '../../../../../../components/Subtitle'
import { Button } from '@material-ui/core'
import { useVerifyPhoneContext } from '../context'
import EditField from '../../../../../../components/edit-field'
import { VerifyPhoneStates } from '../states-actions'
import Loading from '../../../../../../components/Loading'
import { S_LOAD_ERR } from '../../../../../../utils/states-actions'
import { Limits } from '../../../../../../utils/limits'

const styles = (theme) => ({
  button: {
    display: 'block',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
  },
  buttonLeft: {
    display: 'block',
    float: 'left',
  },
  label: {
    textAlign: 'center',
  },
  labelSuccess: {
    color: 'green',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  labelError: {
    color: 'red',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
})

const VerifyPhone = (props) => {
  const ctx = useVerifyPhoneContext()
  const { t, classes } = props
  const isPhoneVerified = ctx.getField('verified').value && true
  console.log(
    'VerifyPhone',
    props,
    'isPhoneVerified: ',
    isPhoneVerified ? 'true' : 'false',
    ctx.state
  )
  const phoneState = ctx.state
  const allowSendCode =
    (ctx.getField('phone_code').value ?? '').length >= Limits.phoneNumberCode

  switch (phoneState.type) {
    case VerifyPhoneStates.notVerified:
      return (
        <Button className={classes.button} onClick={ctx.onRequestCode}>
          {t('profile_edit.verify_phone')}
        </Button>
      )
    case VerifyPhoneStates.verified:
      return (
        <Subtitle className={classes.labelSuccess}>
          {t('profile_edit.phone_verified')}
        </Subtitle>
      )
    case VerifyPhoneStates.codeSending:
      return (
        <>
          {ctx.sendRequestCode()}
          <Loading />
          <Subtitle className={classes.label}>
            {t('profile_edit.sending_code')}
          </Subtitle>
          <hr />
        </>
      )
    case VerifyPhoneStates.codeSent:
      return (
        <>
          <Subtitle className={classes.label}>
            {t('profile_edit.sent_code')}
          </Subtitle>
          <EditField
            fieldType="text"
            field="phone_code"
            label={t('profile_edit.phone_code')}
            trans={t}
            getEditField={ctx.getField}
            onEditFieldChange={ctx.onFieldChange}
          />
          <Button className={classes.buttonLeft} onClick={ctx.onRequestCode}>
            {t('profile_edit.resend_code')}
          </Button>
          <Button
            disabled={!allowSendCode}
            className={classes.button}
            onClick={ctx.onVerifyPhone}
          >
            {t('profile_edit.send_code')}
          </Button>
          <hr />
        </>
      )
    case VerifyPhoneStates.verifying:
      return (
        <>
          {ctx.sendVerifyPhone(ctx.getField('phone_code').value)}
          <Loading />
          <Subtitle className={classes.label}>
            {t('profile_edit.verifying')}
          </Subtitle>
          <hr />
        </>
      )
    case S_LOAD_ERR:
      return (
        <>
          <Subtitle
            data-testid={'verify-error-label'}
            className={classes.labelError}
          >
            {phoneState.message}
          </Subtitle>
          <hr />
        </>
      )
    default:
      console.log('DEFAULT')
      return <></>
  }
}
export default withTranslation()(withStyles(styles)(VerifyPhone))
