import React from 'react'
import { OverviewContextProvider } from './context'
import OverviewViews from './views'
import { useAppContext } from '../../context/app-context'
import { GQLProviderOverview } from './data/di'

const OverviewView = (props) => {
  const { appData } = useAppContext()
  const data = {
    accountId: (appData.account || props.account).id,
    teams: appData.teams,
  }
  return (
    <OverviewContextProvider data={data} provider={GQLProviderOverview()}>
      <OverviewViews />
    </OverviewContextProvider>
  )
}

export default OverviewView
