import { Box, Button, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDashboardContext } from '../context'
import { withTranslation } from 'react-i18next'
import {
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
} from '../../../utils/states-actions'
import ErrorCmp from '../../../components/error'
import Title from '../../../components/Title'
import { Sector } from 'recharts'
import {
  CheckStatus,
  GetStatusColor,
} from '../../services/checks/service-utils'
import PieChartXc from '../../../components/pie-chart-xc'
import {
  ArrowDownward,
  ArrowUpward,
  Check,
  GroupWork,
  NetworkCheck,
  NotificationsActive,
  Person,
} from '@material-ui/icons'
import { useHistory } from 'react-router'
import { Routes } from '../../../routes'
import Subtitle from '../../../components/Subtitle'
import LoadingView from '../../../components/loading-view'

const pieRadius = 300

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
  button: {
    marginLeft: 'auto',
  },
  betaBanner: {
    marginRight: 'auto',
  },
  betaBannerHolder: {
    display: 'inline-block',
  },
  betaTitle: {
    textAlign: 'center',
    color: 'danger',
    padding: theme.spacing(2),
  },
  filtersHolder: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  analyticsHolder: {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
  },
  pieItem: {
    position: 'relative',
    margin: 20,
    flexGrow: 1,
    minWidth: pieRadius,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  analyticsItem: {
    margin: 20,
    padding: 20,
    flex: '1 1 30%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  noIssues: {
    position: 'absolute',
  },
})
const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props

  return (
    <g>
      <text x={cx} y={cy} dy={-4} textAnchor="middle" fill={fill}>
        {payload.title}
      </text>
      <text x={cx} y={cy} dy={14} textAnchor="middle" fill={fill}>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  )
}
const renderColorfulLegendText = (value, entry) => {
  const { color, payload } = entry
  return (
    <span style={{ color }}>
      {payload.title}: {payload.value}
    </span>
  )
}

const DashboardViews = (props) => {
  const hookRef = useDashboardContext()
  const { state, onPieEnter } = hookRef
  const { classes, t } = props
  const history = useHistory()
  console.log('Dashboard Index: state: ', state)
  const handleCreateService = () => {
    history.push(Routes.serviceEditRoute('new'))
  }

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.loadAnalytics(hookRef.accountId)}
            <LoadingView />
          </>
        )
      case S_LOAD_DONE:
        return (
          <>
            <Box className={classes.analyticsHolder}>
              <Paper className={classes.pieItem}>
                <PieChartXc
                  data={state.analytics.checks_pie}
                  id={'checks_pie_index'}
                  height={'300px'}
                  dataKey={'value'}
                  fillColor={(entry) =>
                    GetStatusColor(CheckStatus[entry.title])
                  }
                  legendFormatter={renderColorfulLegendText}
                  activeIndex={state.checks_pie_index ?? 0}
                  activeShape={renderActiveShape}
                  onMouseEnter={(tmp, index) =>
                    onPieEnter('checks_pie_index', tmp, index)
                  }
                />
                {state.analytics.totalChecks === 0 && (
                  <Button
                    className={classes.noIssues}
                    size={'small'}
                    autoCapitalize={'lower'}
                    variant="contained"
                    color="primary"
                    onClick={handleCreateService}
                  >
                    {t('service_checks.add')}
                  </Button>
                )}
                <Title>{t('analytics.checks')}</Title>
              </Paper>
              <Paper className={classes.pieItem}>
                <PieChartXc
                  data={state.analytics.active_incidents}
                  id={'incidents_pie_index'}
                  height={'300px'}
                  dataKey={'value'}
                  fillColor={(entry) =>
                    GetStatusColor(CheckStatus[entry.title])
                  }
                  legendFormatter={renderColorfulLegendText}
                  activeIndex={state.incidents_pie_index ?? 0}
                  activeShape={renderActiveShape}
                  onMouseEnter={(tmp, index) =>
                    onPieEnter('incidents_pie_index', tmp, index)
                  }
                />
                {state.analytics.totalActiveIncidents === 0 && (
                  <Check
                    color={'primary'}
                    fontSize={'large'}
                    className={classes.noIssues}
                  />
                )}
                <Title>{t('analytics.incidents')}</Title>
              </Paper>
              <Paper className={classes.pieItem}>
                <PieChartXc
                  data={state.analytics.incidents}
                  id={'total_incidents_index'}
                  height={'300px'}
                  dataKey={'value'}
                  fillColor={(entry) =>
                    GetStatusColor(CheckStatus[entry.title])
                  }
                  legendFormatter={renderColorfulLegendText}
                  activeIndex={state.total_incidents_index ?? 0}
                  activeShape={renderActiveShape}
                  onMouseEnter={(tmp, index) =>
                    onPieEnter('total_incidents_index', tmp, index)
                  }
                />
                {state.analytics.totalIncidents === 0 && (
                  <Check
                    color={'primary'}
                    fontSize={'large'}
                    className={classes.noIssues}
                  />
                )}
                <Title>{t('analytics.total')}</Title>
              </Paper>
            </Box>
            <Box className={classes.analyticsHolder}>
              <Paper className={classes.analyticsItem}>
                <ArrowDownward fontSize={'large'} />
                <Subtitle>{t('analytics.downtime')}</Subtitle>
                <Title>{state.analytics.downtime}</Title>
              </Paper>
              <Paper className={classes.analyticsItem}>
                <ArrowUpward fontSize={'large'} />
                <Subtitle>{t('analytics.uptime')}</Subtitle>
                <Title>{state.analytics.uptime}</Title>
              </Paper>
              <Paper className={classes.analyticsItem}>
                <Person fontSize={'large'} />
                <Subtitle>{t('analytics.users')}</Subtitle>
                <Title>{state.analytics.users}</Title>
              </Paper>
              <Paper className={classes.analyticsItem}>
                <GroupWork fontSize={'large'} />
                <Subtitle>{t('analytics.teams')}</Subtitle>
                <Title>{state.analytics.teams}</Title>
              </Paper>
              <Paper className={classes.analyticsItem}>
                <NetworkCheck fontSize={'large'} />
                <Subtitle>{t('analytics.services')}</Subtitle>
                <Title>{state.analytics.services}</Title>
              </Paper>
              <Paper className={classes.analyticsItem}>
                <NotificationsActive fontSize={'large'} />
                <Subtitle>{t('analytics.notifications')}</Subtitle>
                <Title>{state.analytics.notifications}</Title>
              </Paper>
            </Box>
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <Box className={classes.root}>
      <Paper>
        <Subtitle color={'error'} className={classes.betaTitle}>
          {t('beta_info')}
        </Subtitle>
      </Paper>
      {getByState()}
    </Box>
  )
}

export default withTranslation()(withStyles(styles)(DashboardViews))
