import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import EditField, { EditTextArea } from '../../../../../components/edit-field'
import SaveButton from '../../../../../components/save-button'
import { FormControl, FormLabel, Paper, RadioGroup } from '@material-ui/core'
import Subtitle from '../../../../../components/Subtitle'
import {
  WithIpOption,
  WithPortOption,
  WithURLOption,
  CheckRateUnit,
  CheckType,
  DNSQuery,
  WithAuthOptions,
  WithHTTPOptions,
  WithNoAdditionalOptions,
  WithNoCheckRate,
  CheckTypeValues,
  AllSupportedTypes,
} from '../../service-utils'
import { RadioItem } from '../../../../../components/radio-item'
import Toggle from '../../../../../components/toggle'
import { TeamsContextProvider } from '../../../../teams/context'
import { GQLProviderTeams } from '../../../../teams/data/provider'
import TeamsViews from '../../../../teams/views'
import ManageTeamsList from '../../../../users/manage/views/manage-teams-list'
import { useAppContext } from '../../../../../context/app-context'
import PropTypes from 'prop-types'
import { PlanType } from '../../../../../components/current-plan'
import DropDown from '../../../../../components/dropdown'
import XCSlider from '../../../../../components/slider'
import { Limits } from '../../../../../utils/limits'
import TestingReportView from './testing-report'

const styles = (theme) => ({
  buttonsHolder: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
  },
  button: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: '8px',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  rolesHolder: {
    textTransform: 'capitalize',
    marginTop: '8px',
    width: '100%',
  },
  sectionHolder: {
    marginBottom: theme.spacing(2),
  },
  optionsHolder: {
    flexDirection: 'row',
  },
  textarea: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
  },
  slider: {
    width: '100%',
  },
})
export const getRateId = (checkRate, checkRateUnit) => {
  return `service-check-rate-${checkRate}-${checkRateUnit}`
}

const RateRadio = (checkRate, checkRateUnit, label, t, disabled = false) => {
  return (
    <RadioItem
      value={`${checkRate}-${checkRateUnit}`}
      testId={getRateId(checkRate, checkRateUnit)}
      label={t(`serviceCheck_edit.rate.${label}`)}
      disabled={disabled}
    />
  )
}

const CheckTypePart = (props) => {
  const { getEditField, onChooseType, t, classes } = props
  const checkType = getEditField('type')
  const isNew = props.id === 'new'
  const types = isNew ? AllSupportedTypes : [CheckTypeValues[checkType.value]]
  return (
    <>
      <Subtitle>{t(`serviceCheck_edit.type${isNew ? '_new' : ''}`)}</Subtitle>
      <Paper className={classes.sectionHolder}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            className={classes.optionsHolder}
            aria-label="type"
            name="type"
            id="type"
            value={checkType.value || CheckType.HTTP}
            onChange={onChooseType}
          >
            {types.map((key) => {
              return (
                <RadioItem
                  key={key}
                  value={CheckType[key]}
                  testId={`service-check-type-${CheckType[key]}`}
                  label={key}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Paper>
    </>
  )
}
const DetailsPart = (props) => {
  const { getEditField, onFieldChange, onChooseRate, t, classes } = props
  const checkRate = getEditField('checkRateWithUnit')
  const checkType = getEditField('type').value
  const allowPort = WithPortOption.indexOf(checkType) >= 0
  const allowUrl = WithURLOption.indexOf(checkType) >= 0
  const allowIp = WithIpOption.indexOf(checkType) >= 0
  const allowIpDNS = checkType === CheckType.DNS
  const forSMTP = checkType === CheckType.SMTP
  const daysBeforeExpire = checkType === CheckType.CERT
  const withCheckRate = WithNoCheckRate.indexOf(checkType) < 0
  return (
    <>
      <Subtitle>{t('serviceCheck_edit.details')}</Subtitle>
      <Paper className={classes.sectionHolder}>
        <FormControl component="fieldset" className={classes.formControl}>
          {withCheckRate && (
            <>
              <FormLabel component="legend">
                {t('serviceCheck_edit.rate.title')}
              </FormLabel>
              <RadioGroup
                className={classes.optionsHolder}
                aria-label="rate"
                name="rate"
                value={checkRate.value || `5-${CheckRateUnit.minutes}`}
                onChange={onChooseRate}
              >
                {RateRadio(0, CheckRateUnit.constant, 'constant', t, true)}
                {RateRadio(30, CheckRateUnit.seconds, '30_sec', t)}
                {RateRadio(1, CheckRateUnit.minutes, '1_min', t)}
                {RateRadio(5, CheckRateUnit.minutes, '5_min', t)}
                {RateRadio(15, CheckRateUnit.minutes, '15_min', t)}
                {RateRadio(30, CheckRateUnit.minutes, '30_min', t)}
                {RateRadio(1, CheckRateUnit.hours, '1_hour', t)}
                {RateRadio(24, CheckRateUnit.hours, '24_hour', t)}
              </RadioGroup>
            </>
          )}
          <EditField
            field="name"
            label={t('serviceCheck_edit.name')}
            trans={t}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
          />
          {allowIp && (
            <EditField
              field="ip"
              label={t('serviceCheck_edit.ip')}
              trans={t}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          <EditField
            field="hostname"
            label={t('serviceCheck_edit.hostname')}
            trans={t}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
          />
          {daysBeforeExpire && (
            <XCSlider
              field="beforeExpire"
              label={t('serviceCheck_edit.beforeExpire')}
              formControlClass={classes.formControl}
              unit={t('day')}
              className={classes.slider}
              defaultValue={Limits.certExpireMin}
              trans={t}
              minimum={Limits.certExpireMin}
              maximum={Limits.certExpireMax}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          {allowIpDNS && (
            <EditField
              field="dnsIp"
              label={t('serviceCheck_edit.dnsIp')}
              trans={t}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          {allowPort && (
            <EditField
              field="port"
              maxLength={4}
              fieldType={'number'}
              label={t('serviceCheck_edit.port')}
              trans={t}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          {forSMTP && (
            <>
              <EditField
                field="smtpExpect"
                label={t('serviceCheck_edit.smtpExpect')}
                trans={t}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
              />
              <EditField
                field="smtpFrom"
                label={t('serviceCheck_edit.smtpFrom')}
                trans={t}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
              />
              <EditField
                field="smtpFQDN"
                label={t('serviceCheck_edit.smtpFQDN')}
                trans={t}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
              />
            </>
          )}
          {allowUrl && (
            <EditField
              field="url"
              label={t('serviceCheck_edit.url')}
              trans={t}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          <EditField
            field="notificationsInterval"
            label={t('serviceCheck_edit.notificationsInterval')}
            trans={t}
            fieldType={'number'}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
          />
        </FormControl>
      </Paper>
    </>
  )
}
const HttpOptionsPart = (props) => {
  const { getEditField, onFieldChange, onToggleField, t, classes } = props
  const checkType = getEditField('type')
  const withOptions =
    WithHTTPOptions.indexOf(checkType.value || CheckType.HTTP) >= 0

  return withOptions ? (
    <>
      <Subtitle>{t('serviceCheck_edit.http_options.title')}</Subtitle>
      <Paper className={classes.sectionHolder}>
        <FormControl component="fieldset" className={classes.formControl}>
          <EditField
            field="userAgent"
            label={t('serviceCheck_edit.http_options.userAgent')}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
          />
          <Toggle
            field={'ssl'}
            value={getEditField('ssl').value}
            onToggle={onToggleField}
            t={t}
            label={'serviceCheck_edit.http_options.ssl'}
            classFormControl={classes.formControl}
            classRadioGroup={classes.optionsHolder}
          />
          <Toggle
            field={'followRedirects'}
            value={getEditField('followRedirects').value}
            onToggle={onToggleField}
            t={t}
            label={'serviceCheck_edit.http_options.follow_redirects'}
            classFormControl={classes.formControl}
            classRadioGroup={classes.optionsHolder}
          />
          <EditTextArea
            field="headers"
            label={t('serviceCheck_edit.http_options.headers')}
            trans={t}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
            className={classes.textarea}
          />
          <EditField
            field="expectedString"
            label={t('serviceCheck_edit.http_options.expectedString')}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
            format={(value) => {
              return value && (value === Array ? value.join(',') : value)
            }}
          />
          {checkType.value === CheckType.HTTP && (
            <>
              <EditTextArea
                field="postData"
                label={t('serviceCheck_edit.http_options.postdata')}
                trans={t}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
                className={classes.textarea}
              />
              <EditTextArea
                field="postFormData"
                label={t('serviceCheck_edit.http_options.form_postdata')}
                trans={t}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
                className={classes.textarea}
              />
            </>
          )}
        </FormControl>
      </Paper>
    </>
  ) : null
}
const AdditionalOptionsPart = (props) => {
  const { getEditField, onFieldChange, onToggleField, t, classes } = props
  const checkType = getEditField('type').value
  const withNoAdditionalOptions =
    WithNoAdditionalOptions.indexOf(checkType) >= 0
  if (withNoAdditionalOptions) {
    return <></>
  }
  const showDNS = checkType === CheckType.DNS
  const forSMTP = checkType === CheckType.SMTP
  const withAuthOptions =
    WithAuthOptions.indexOf(checkType || CheckType.HTTP) >= 0
  return (
    <>
      <Subtitle>{t('serviceCheck_edit.add_options.title')}</Subtitle>
      <Paper className={classes.sectionHolder}>
        <FormControl component="fieldset" className={classes.formControl}>
          <EditField
            field="timeout"
            maxLength={2}
            fieldType={'number'}
            label={t('serviceCheck_edit.add_options.timeout')}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
          />
          {checkType === CheckType.TCP && (
            <EditField
              field="quit"
              label={t('serviceCheck_edit.quit')}
              trans={t}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          {showDNS && (
            <DropDown
              formControlClass={classes.formControl}
              defaultValue={DNSQuery.none}
              data={[
                { value: DNSQuery.A, text: t('serviceCheck_edit.dns_a') },
                { value: DNSQuery.AAAA, text: t('serviceCheck_edit.dns_aaaa') },
                { value: DNSQuery.SRV, text: t('serviceCheck_edit.dns_srv') },
                { value: DNSQuery.TXT, text: t('serviceCheck_edit.dns_txt') },
                { value: DNSQuery.MX, text: t('serviceCheck_edit.dns_mx') },
                { value: DNSQuery.ANY, text: t('serviceCheck_edit.dns_any') },
              ]}
              field="dnsQuery"
              label={t('serviceCheck_edit.dns_query')}
              getEditField={getEditField}
              onEditFieldChange={onFieldChange}
            />
          )}
          {showDNS && (
            <Toggle
              field={'acceptCName'}
              value={getEditField('acceptCName').value}
              onToggle={onToggleField}
              t={t}
              label={'serviceCheck_edit.dns_acceptCName'}
              classFormControl={classes.formControl}
              classRadioGroup={classes.optionsHolder}
            />
          )}
          {forSMTP && (
            <Toggle
              field={'startTLS'}
              value={getEditField('startTLS').value}
              onToggle={onToggleField}
              t={t}
              label={'serviceCheck_edit.startTLS'}
              classFormControl={classes.formControl}
              classRadioGroup={classes.optionsHolder}
            />
          )}
          {withAuthOptions && (
            <>
              <EditField
                field="authUser"
                label={t('serviceCheck_edit.add_options.authUser')}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
              />
              <EditField
                field="authPass"
                label={t('serviceCheck_edit.add_options.authPass')}
                getEditField={getEditField}
                onEditFieldChange={onFieldChange}
              />
            </>
          )}
          <EditField
            field="hostingProvider"
            label={t('serviceCheck_edit.add_options.hostingProvider')}
            trans={t}
            getEditField={getEditField}
            onEditFieldChange={onFieldChange}
          />
        </FormControl>
      </Paper>
    </>
  )
}

export const ManageServiceCheck = (props) => {
  const { doToggleTeam, state } = props
  const { appData } = useAppContext()
  const t =
    props.t ||
    ((what) => {
      return what
    })
  const planName = appData.plan.name
  if (planName === PlanType.free || planName === PlanType.singleUser) {
    return <></>
  }
  return (
    <>
      {props.teamsComponent || (
        <TeamsContextProvider
          data={{ accountId: appData.account.id }}
          provider={GQLProviderTeams()}
        >
          <Subtitle>{t('serviceCheck_edit.add_to_team')}</Subtitle>
          <TeamsViews
            teamsList={(props) => (
              <ManageTeamsList
                {...props}
                user={state.serviceCheck}
                doToggleTeam={doToggleTeam}
                manageState={state}
              />
            )}
          />
        </TeamsContextProvider>
      )}
    </>
  )
}
ManageServiceCheck.propTypes = {
  teamsComponent: PropTypes.object,
  hideAddToAccount: PropTypes.bool.isRequired,
  doToggleAccount: PropTypes.func.isRequired,
  doToggleTeam: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
}

const EditView = (props) => {
  const {
    getEditField,
    hasChanges,
    doSave,
    classes,
    t,
    state,
    doToggleTeam,
    doToggleAccount,
  } = props
  const isNew = getEditField('id').value === 'new'

  const addingTo = state.addingToAccount || {}
  const addingIds = Object.keys(addingTo)
  const isUpdating = addingIds.length > 0
  const teamsListComp = isNew ? <></> : props.teamsListComp
  return (
    <>
      <CheckTypePart {...props} />
      <DetailsPart {...props} />
      <HttpOptionsPart {...props} />
      <AdditionalOptionsPart {...props} />
      <TestingReportView />
      <div className={classes.buttonsHolder}>
        <SaveButton
          className={classes.button}
          hasChanges={hasChanges}
          handleClick={doSave}
        />
      </div>
      <br />
      <ManageServiceCheck
        t={t}
        teamsComponent={teamsListComp}
        hideAddToAccount={isNew || isUpdating}
        doToggleAccount={doToggleAccount}
        doToggleTeam={doToggleTeam}
        state={state}
      />
    </>
  )
}
export default withTranslation()(withStyles(styles)(EditView))
