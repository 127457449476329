import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
} from '../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    users: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedUsers = (data) => {
  return {
    type: S_LOAD_DONE,
    users: data,
    errors: {},
  }
}
export const StateNoUsers = {
  type: S_NO_DATA,
  users: [],
  errors: {},
}
export const actionLoadedUsers = (data) => {
  const users = [...data]
  return {
    reduce: () => {
      return StateLoadedUsers(users)
    },
  }
}
export const actionNoUsers = () => {
  return {
    reduce: (state) => {
      return { ...state, ...StateNoUsers }
    },
  }
}
