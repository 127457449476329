import { NTF_TYPE, SCH_TYPE } from '../states-actions'
import { SCHEDULE_DEFAULT } from './get'

export const NotificationMapperDataToDomain = (schedule) => {
  console.log('NotificationMapperDataToDomain: ', schedule)
  const rSchedule = ScheduleMapperDataToDomain(schedule)
  return {
    ...rSchedule,
  }
}
export const ScheduleMapperDataToDomain = (src) => {
  console.log('ScheduleMapperDataToDomain: ', src)
  if (src === SCHEDULE_DEFAULT) {
    return {
      id: SCHEDULE_DEFAULT,
      type: NTF_TYPE.allday,
      active: true,
    }
  }
  const timetable = src.timetable
  const rTimeTable = {
    monday_start: getField(timetable, 'monday', 'start'),
    monday_end: getField(timetable, 'monday', 'end'),
    tuesday_start: getField(timetable, 'tuesday', 'start'),
    tuesday_end: getField(timetable, 'tuesday', 'end'),
    wednesday_start: getField(timetable, 'wednesday', 'start'),
    wednesday_end: getField(timetable, 'wednesday', 'end'),
    thursday_start: getField(timetable, 'thursday', 'start'),
    thursday_end: getField(timetable, 'thursday', 'end'),
    friday_start: getField(timetable, 'friday', 'start'),
    friday_end: getField(timetable, 'friday', 'end'),
    saturday_start: getField(timetable, 'saturday', 'start'),
    saturday_end: getField(timetable, 'saturday', 'end'),
    sunday_start: getField(timetable, 'sunday', 'start'),
    sunday_end: getField(timetable, 'sunday', 'end'),
    global_start: getField(timetable, 'monday', 'start'),
    global_end: getField(timetable, 'monday', 'end'),
  }

  return {
    id: src.id,
    type: src.timetableType,
    active: src.active,
    ...rTimeTable,
  }
}
export const ScheduleMapperDomainToData = (src) => {
  const useGlobal = src.type === NTF_TYPE.workdays
  const result = {
    name: src.type,
    timetableType: src.type,
    type: SCH_TYPE.notification,
    active: src.active,
    entity: src.entity,
    entityId: src.entityId,
    timetable: {
      monday: [getScheduleDayData(src, 'monday', useGlobal)],
      tuesday: [getScheduleDayData(src, 'tuesday', useGlobal)],
      wednesday: [getScheduleDayData(src, 'wednesday', useGlobal)],
      thursday: [getScheduleDayData(src, 'thursday', useGlobal)],
      friday: [getScheduleDayData(src, 'friday', useGlobal)],
      saturday: [getScheduleDayData(src, 'saturday', useGlobal)],
      sunday: [getScheduleDayData(src, 'sunday', useGlobal)],
    },
  }
  if (useGlobal) {
    result.timetable.saturday = []
    result.timetable.sunday = []
  }
  return result
}
export const getField = (src, day, field) => {
  const result = (src ?? {})[day] ?? [{ start: null, end: null }]
  return result.length === 0 ? null : result[0][field]
}

export const getScheduleDayData = (src, day, forceGlobal = false) => {
  return {
    start: forceGlobal
      ? src.global_start
      : src[`${day}_start`] ?? src.global_start,
    end: forceGlobal ? src.global_end : src[`${day}_end`] ?? src.global_end,
  }
}
