import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useNotificationContext } from '../context'
import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_SAVING,
} from '../../../../../utils/states-actions'
import SavingNotification from './saving'
import ErrorCmp from '../../../../../components/error'
import { withTranslation } from 'react-i18next'
import NotificationsSettings from './edit-settings'
import LoadingView from '../../../../../components/loading-view'
import TopBar from '../../../../../components/topbar'

const styles = (theme) => ({
  root: {
    maxWidth: 700,
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  button: {
    margin: 'auto',
  },
  button2: {
    textTransform: 'unset !important',
  },
})

const NotificationViews = (props) => {
  const hookRef = useNotificationContext()
  const { state } = hookRef
  const { classes, t } = props

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return <LoadingView />
      case S_LOAD_DONE:
      case S_CREATED:
        return <NotificationsSettings {...hookRef} />
      case S_SAVING:
        return (
          <>
            {hookRef.save()}
            <SavingNotification />
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar titleText={t('notification_edit.title')} hideBack={true} />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(NotificationViews))
