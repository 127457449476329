import { UserRoles } from '../../../manage/roles'

export const UserMapperDataToDomain = (src) => {
  console.log('UserMapperDataToDomain: ', src)
  const cognito = src.cognitoDetails
  return {
    id: src.id,
    accountId: src.accountId,
    email: cognito.email,
    nickname: cognito.nickname,
    name: cognito.given_name + ' ' + cognito.family_name,
    given_name: cognito.given_name,
    family_name: cognito.family_name,
    mobile: cognito.phone_number,
    phone_verified: cognito.phone_number_verified ?? false,
    teams: (src.memberOf ?? []).map((team) => {
      return { id: team }
    }),
    timezone: cognito.zoneinfo ?? 'UTC',
    role: src.role,
    ntf_email_enabled: src.notifications.email_enabled,
    ntf_email_schedule: src.notifications.email_schedule,
    ntf_sms_enabled: src.notifications.sms_enabled,
    ntf_sms_schedule: src.notifications.sms_schedule,
  }
}
export const UserMapperDomainToData = (src) => {
  const result = {
    accountId: src.accountId,
    id: src.id,
    role: src.role ?? UserRoles.viewer,
    cognitoDetails: {
      email: src.email,
      family_name: src.family_name,
      given_name: src.given_name,
      nickname: src.nickname,
      phone_number: src.mobile,
      zoneinfo: src.timezone,
    },
    notifications: UserNotificationMapperDomainToData(src),
  }
  if (
    Object.values(result.cognitoDetails).filter((val) => {
      return typeof val !== 'undefined'
    }).length === 0
  ) {
    delete result.cognitoDetails
  }
  if (
    Object.values(result.notifications).filter((val) => {
      return typeof val !== 'undefined'
    }).length === 0
  ) {
    delete result.notifications
  }
  return result
}
export const UserNotificationMapperDomainToData = (src) => {
  return {
    email_enabled: src.ntf_email_enabled,
    email_schedule: src.ntf_email_schedule,
    sms_enabled: src.ntf_sms_enabled,
    sms_schedule: src.ntf_sms_schedule,
  }
}
