import {
  buildForUpdate,
  ExecuteGQLWithBuilderAsync,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_USER } from '../../../../users/user/edit/data/get-user'

export const UpdateUserNotification = (props) => {
  console.log('UpdateUserNotification:: props', props)
  const { data, id } = props
  const changed = { notifications: { ...data } }
  const builderData = buildForUpdate(
    Entity.user,
    FIELDS_USER,
    { id: id },
    changed
  )

  ExecuteGQLWithBuilderAsync({
    builderData: builderData,
    onDone: props.onLoad,
    onError: props.onError,
  })
}

export default UpdateUserNotification
