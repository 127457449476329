import { gql } from '@apollo/client'
import React from 'react'
import { ExecuteGQL } from '../../../utils/executeGraphQL'
import { Entities } from '../../entities'
import { FIELDS_USER } from '../user/edit/data/get-user'

export const QUERY_PLAN_FIELDS = `
      id
      name
      amount
      cancelAtPeriodEnd
      canceledAt
      payed
      paymentType
      startDate
      endDate
      status
`
export const QUERY_PLAN_FIELDS_NEW = `
      id
      active
      createdAt
      description
      features {
        description
        id
        title
      }
      name
      price
      priceId
      type
      updatedAt
`

const QUERY = gql`
  query getUsers($fil: UserWhere) {
    ${Entities.users}(where: $fil) {
      ${FIELDS_USER}
    }
  }
`
export const GetUsers = (props) => {
  const { accountId, onLoad, onError } = props
  return (
    <ExecuteGQL
      actionName={Entities.users}
      variables={{
        fil: { member: { id: accountId } },
      }}
      onDone={onLoad}
      onError={onError}
      query={QUERY}
    />
  )
}

export default GetUsers
