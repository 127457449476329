import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import XHR from 'i18next-xhr-backend'
import languageEN from './en/translate.json'
import languageSR from './sr/translate.json'
import i18n from 'i18next'

const KEY_LANG = 'i18nextLng'
const lastLang = localStorage.getItem(KEY_LANG) || 'en'
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      sr: languageSR,
    },
    /* default language when load the website in browser */
    lng: lastLang,
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: 'en',
    /* debugger For Development environment */
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  })

export const SetLanguage = (code) => {
  localStorage.setItem(KEY_LANG, code)
  i18n.changeLanguage(code)
}

export default i18n
