import React from 'react'
import { WelcomeContextProvider } from './context'
import WelcomeViews from './views'
import WelcomeProvider from './data/provider'

const WelcomeView = (props) => {
  console.log('WelcomeView', JSON.stringify(props))
  const data = { ...props }
  const href =
    window.location.hash || window.location.search
      ? window.location.href
      : undefined
  return (
    <WelcomeContextProvider data={data} provider={WelcomeProvider()}>
      <WelcomeViews href={href} />
    </WelcomeContextProvider>
  )
}

export default WelcomeView
