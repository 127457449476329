import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useOverviewContext } from '../context'
import OverviewMapView from './map'
import ErrorCmp from '../../../components/error'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
  S_POLL,
} from '../../../utils/states-actions'
import { useAppContext } from '../../../context/app-context'
import { UserRoles } from '../../users/manage/roles'
import { DefaultFilter, StateInitial } from '../states-actions'
import { withTranslation } from 'react-i18next'
import OverviewList from './list'
import Subtitle from '../../../components/Subtitle'
import { Refresh } from '@material-ui/icons'
import LoadingView from '../../../components/loading-view'
import TopBar from '../../../components/topbar'

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
  button: {
    marginLeft: 'auto',
  },
  filtersHolder: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  asMapHolder: {
    marginLeft: theme.spacing(3),
  },
})

const OverviewViews = (props) => {
  const hookRef = useOverviewContext()
  const { appData } = useAppContext()
  const { user, account } = appData
  const { state, teams, doToggleMap, onForceRefresh } = hookRef
  const { classes, t } = props
  console.log('Check Index: state: ', state)
  const teamsData = (user.role !== UserRoles.viewer ? teams : user.teams) || []
  const teamFilter = teamsData.length > 0 ? teamsData[0] : ''
  const filter =
    state.filter || StateInitial(teamFilter).filter || DefaultFilter()
  const hasData = state.checks.length > 0
  console.log('Check Index: FILTER: ', filter)

  const getByState = () => {
    const forceFetch = filter.forceFetch === true
    const stateType = forceFetch ? S_LOAD_START : state.type
    console.log('OVAJ state', state)
    switch (stateType) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.loadOverview(
              filter.by,
              user.id,
              account.id,
              filter.team,
              filter.type,
              forceFetch
            )}
            <LoadingView />
          </>
        )
      case S_DELETE:
      case S_NO_DATA:
      case S_LOAD_DONE:
      case S_POLL:
        return (
          <>
            {state.type === S_DELETE &&
              hookRef.deleteServiceCheck(state.forDeletion)}
            {(props.checksList && props.checksList(hookRef)) ||
              (filter.asMap ? (
                <OverviewMapView {...hookRef} />
              ) : (
                <OverviewList {...hookRef} />
              ))}
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar titleText={t('overview.title')} hideBack={true} />
      <Paper className={classes.filtersHolder}>
        <FormGroup row>
          <Button size={'small'} onClick={onForceRefresh}>
            <Refresh fontSize={'small'} />
            <Subtitle>{t('refresh')}</Subtitle>
          </Button>
          {hasData && (
            <FormControlLabel
              className={classes.asMapHolder}
              control={
                <Checkbox
                  size={'small'}
                  checked={state.filter.asMap}
                  onClick={doToggleMap}
                  inputProps={{
                    'data-testid': 'check-show-map',
                  }}
                />
              }
              label={<Subtitle>{t('service_checks.filter.as_map')}</Subtitle>}
            />
          )}
        </FormGroup>
      </Paper>
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(OverviewViews))
