import React from 'react'
import {
  buildForUpdate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_SCHEDULE } from './get'

export const UpdateNotification = (props) => {
  console.log('updateProps', JSON.parse(JSON.stringify(props)))
  const { data, id } = props
  const builderData = buildForUpdate(
    Entity.schedule,
    FIELDS_SCHEDULE,
    { id: id },
    data
  )
  console.log('builderData', builderData)
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onLoad}
      onError={props.onError}
    />
  )
}

export default UpdateNotification
