import React from 'react'
import GetCheckResults from '../../data/get'
import { Filters } from '../../../services/checks/states-actions'

export const GQLProviderCheckResults = () => {
  const Get = (accountId, checkId, onLoad, onError) => {
    return (
      <GetCheckResults
        accountId={accountId}
        id={checkId}
        onLoad={onLoad}
        onError={onError}
        filterBy={Filters.user}
      />
    )
  }

  return {
    GetCheckResults: Get,
  }
}
