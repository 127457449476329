import React from 'react'
import { FIELDS_BASE_USER } from '../../user/edit/data/get-user'
import UpdateUserRole from './update-user-role'
import AddUserToTeam from './add-to-team'
import RemoveUserFromTeam from './remove-from-team'
import { CacheModifyFor } from '../../../../utils/executeGraphQL'
import { UserMapperDataToDomain } from '../../user/edit/data/mapper'

export const GQLProviderManageUser = () => {
  const UpdateRole = (id, role, onDone, onError) => {
    return (
      <UpdateUserRole
        id={id}
        role={role}
        onError={onError}
        onDone={(updated) => {
          onDone(UserMapperDataToDomain(updated))
        }}
      />
    )
  }
  const AddToTeam = (userId, teamId, onDone, onError) => {
    return (
      <AddUserToTeam
        userId={userId}
        teamId={teamId}
        onError={onError}
        onDone={onDone}
      />
    )
  }
  const RemoveFromTeam = (userId, teamId, onDone, onError) => {
    return (
      <RemoveUserFromTeam
        userId={userId}
        teamId={teamId}
        onError={onError}
        onDone={onDone}
      />
    )
  }

  return {
    UpdateRole,
    AddToTeam,
    RemoveFromTeam,
  }
}

/**
 *
 * @param cache object
 * @param updated object
 * @param cacheChangeType CacheChangeType
 * @returns {*[]}
 */
export const updateCacheUsers = (cache, updated, cacheChangeType) => {
  cache.modify({
    fields: {
      users(existing = []) {
        return CacheModifyFor(
          cacheChangeType,
          existing,
          updated,
          FIELDS_BASE_USER
        )
      },
    },
  })
}
