import React, { useEffect, useRef, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Loading from '../../../components/Loading'
import { S_DELETE } from '../../../utils/states-actions'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import Arrow from '../../../assets/img/arrow_blue_wh.png'
import ImgStart from '../../../assets/img/logo_small.png'
import ImgDest from '../../../assets/img/server.png'
import { point, along, bearing, lineDistance } from 'turf'
import { Routes } from '../../../routes'
import { useHistory } from 'react-router'
import {
  GetColorFromText,
  GetStatusColor,
} from '../../services/checks/service-utils'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  dashMap: {
    width: '100%',
    height: '600px',
  },
  marker: {
    backgroundImage: "url('mapbox-icon.png')",
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  popup: {
    cursor: 'pointer',
  },
  mapboxglPopup: {
    maxWidth: '200px',
  },
  mapboxglPopupContent: {
    textAlign: 'center',
    fontFamily: "'Open Sans', sans-serif",
  },
})

const animPathSteps = 20
const ResultToGeo = (result) => {
  const origin = [result.src_geoip.longitude, result.src_geoip.latitude]
  const destination = [result.dst_geoip.longitude, result.dst_geoip.latitude]
  const route = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [origin, destination],
        },
      },
    ],
  }
  const pointData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: origin,
        },
      },
    ],
  }
  const lineDistanceData = lineDistance(route.features[0])
  const arc = []
  const stepper = lineDistanceData / animPathSteps
  for (let i = 1; i < lineDistanceData; i += stepper) {
    const segment = along(route.features[0], i)
    arc.push(segment.geometry.coordinates)
  }
  arc.push(destination)
  route.features[0].geometry.coordinates = arc

  return {
    id: result.id,
    name: result.name,
    origin,
    destination,
    route,
    returncode: result.returncode,
    pointData,
  }
}

const addLayerToMap = (map, check, layoutData, step, doRotate) => {
  const pointId = `point-${check.id}`
  const pointIdN = `${pointId}-${step}`
  console.log('addLayerToMap: pointIdN', pointIdN)
  const coordinates = check.route.features[0].geometry.coordinates
  const start = coordinates[step >= animPathSteps ? step - 1 : step]
  const end = coordinates[step >= animPathSteps ? step : step + 1]
  if (!start || !end) return

  const cPointData = check.pointData.features[0]
  cPointData.geometry.coordinates = coordinates[step]
  if (step === animPathSteps - 1) {
    cPointData.properties.title = check.name
  }
  if (doRotate) {
    cPointData.properties.bearing = bearing(point(start), point(end))
  }

  map.addSource(pointIdN, {
    type: 'geojson',
    data: check.pointData,
  })

  map.addLayer({
    id: pointIdN,
    source: pointIdN,
    type: 'symbol',
    layout: layoutData,
  })
}
const addCheckToMap = (classes, map, check, layoutData, handleOpen) => {
  const routeId = `route-${check.id}`
  const route = { ...check.route }
  console.log('adding map source: ', routeId, check, {
    type: 'geojson',
    data: route,
  })
  map.addSource(routeId, {
    type: 'geojson',
    data: route,
  })
  map.addLayer({
    id: routeId,
    source: routeId,
    type: 'line',
    paint: {
      'line-width': 2,
      'line-color': GetColorFromText(GetStatusColor(check.returncode)),
    },
  })

  const stepper = Math.ceil(animPathSteps / 3)
  addLayerToMap(map, check, layoutData.src, 0, false)

  for (let step = stepper; step < animPathSteps; step += stepper) {
    addLayerToMap(map, check, layoutData.point, step, true)
  }
  addLayerToMap(map, check, layoutData.dest, animPathSteps, false)
  addpopupsToMap(classes, map, check, handleOpen)
}
const addpopupsToMap = (classes, map, check, openResults) => {
  const coordinates = check.route.features[0].geometry.coordinates
  if (!coordinates[animPathSteps]) {
    return
  }
  const routeId = `route-${check.id}`
  const route = { ...check.route }
  console.log('adding map source: ', routeId, check, {
    type: 'geojson',
    data: route,
  })

  const popupEl = document.createElement('h3')
  popupEl.innerText = check.name
  popupEl.className = classes.popup
  const popHtml = popupEl.outerHTML.replace(
    'class',
    'onclick="openDetails(\'' + check.id + '\');" class'
  )
  window.openDetails = (checkId) => {
    openResults(checkId)
  }
  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popHtml)
  const mrkEl = document.createElement('div')
  mrkEl.id = `mrk-${routeId}`
  mrkEl.className = classes.marker
  new mapboxgl.Marker(mrkEl)
    .setLngLat(coordinates[animPathSteps])
    .setPopup(popup)
    .addTo(map)
}

const OverviewMapView = (props) => {
  const { state, classes } = props
  const history = useHistory()
  const handleOpen = (id) => {
    history.push(Routes.checkResults(id))
  }
  const MapState = {
    INIT: 1,
    MAP_LOADED: 2,
    ADD_IMAGE: 3,
    IMAGE_LOADED: 4,
    POPULATE: 5,
    ALL_DONE: 6,
  }
  const hasData = state.checks.length > 0
  const OverviewMap = () => {
    console.log('OverviewMap opeeeeet')
    const mapContainer = useRef(null)
    const mapRef = useRef(null)
    const [mapState, setMapState] = useState({
      type: MapState.INIT,
      images: [
        { src: ImgStart, name: 'logo' },
        { src: Arrow, name: 'arrow' },
        { src: ImgDest, name: 'dest' },
      ],
    })
    const populateMap = (map, data) => {
      console.log('populateMap map', map, data)
      const baseLayout = {
        'icon-size': 0.5,
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      }
      const baseLayoutRot = { ...baseLayout, 'icon-rotate': ['get', 'bearing'] }
      const src = { ...baseLayout, 'icon-image': 'logo' }
      const point = { ...baseLayoutRot, 'icon-image': 'arrow' }
      const dest = {
        ...baseLayout,
        'icon-image': 'dest',
        'icon-size': 0.6,
      }
      for (let ind = 0; ind < data.length; ind++) {
        console.log(' about to addCheckToMap: ', ind, data[ind])
        addCheckToMap(classes, map, data[ind], { dest, point, src }, handleOpen)
      }
    }
    const init = () => {
      console.log('init map', mapState)
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [0, 0],
        zoom: 1,
      })
      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl())
      mapRef.current = map
      map.on('load', () => {
        console.log('MAP.ON LOAD', mapState)
        setMapState({ ...mapState, type: MapState.MAP_LOADED })
      })
      return () => {
        mapContainer.current = null
        mapRef.current = null
      }
    }
    useEffect(init, [])
    console.log('switttt', mapState)
    const imgsToLoad = mapState.images.filter((img) => !img.loaded)
    switch (mapState.type) {
      case MapState.INIT:
        break
      case MapState.MAP_LOADED: {
        // Using case LOADED wrapped in block { } because of >> const imgsToLoad << and no-case-declarations rule
        if (mapState.images.length === 0) {
          break
        }
        console.log('setMapState to ADD_IMAGE: ', mapState)
        setMapState({
          ...mapState,
          type: MapState.ADD_IMAGE,
          img: mapState.images[0],
        })
        break
      }
      case MapState.ADD_IMAGE:
        console.log('in ADD_IMAGE BLOCK: ', imgsToLoad)
        if (imgsToLoad.length === 0) {
          break
        }
        mapRef.current.loadImage(mapState.img.src, (error, image) => {
          if (error) throw error
          mapRef.current.addImage(mapState.img.name, image)
          const imgs = [...mapState.images]
          imgs[imgs.indexOf(mapState.img)].loaded = true
          console.log('setMapState to MapState.IMAGE_LOADED: ', mapState)
          setMapState({
            ...mapState,
            type: MapState.IMAGE_LOADED,
            images: imgs,
          })
        })
        break
      case MapState.IMAGE_LOADED: {
        // Using case LOADED wrapped in block { } because of >> const imgsToLoad << and no-case-declarations rule
        console.log(
          'in MapState.IMAGE_LOADED imgsToLoad.length',
          imgsToLoad.length,
          'mapState.type: ',
          mapState.type
        )
        if (imgsToLoad.length === 0) {
          const data = state.checks.map((check) => ResultToGeo(check))
          const mState = { ...mapState }
          delete mState.img
          console.log(
            'setMapState to MapState.POPULATE data',
            data,
            'mapState: ',
            mapState
          )
          setMapState({
            ...mState,
            type: MapState.POPULATE,
            data,
          })
          break
        }
        console.log('setMapState to ADD_IMAGE: ', mapState)
        setMapState({
          ...mapState,
          type: MapState.ADD_IMAGE,
          img: imgsToLoad[0],
        })
        break
      }
      case MapState.POPULATE:
        populateMap(mapRef.current, mapState.data)
        console.log('setMapState to MapState.ALL_DONE: ', mapState)
        setMapState({ ...mapState, type: MapState.ALL_DONE })
        break
      case MapState.ALL_DONE:
        break
      default:
        throw new Error('Unknown map state')
    }

    if (!hasData) {
      return null
    }
    return <div ref={mapContainer} className={classes.dashMap} />
  }

  return (
    <div className={classes.tableHolder}>
      {hasData && <OverviewMap />}
      {state.type === S_DELETE && (
        <Loading className={classes.loader} onClick={() => {}} />
      )}
    </div>
  )
}
export default withTranslation()(withStyles(styles)(OverviewMapView))
