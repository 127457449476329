import React from 'react'

import { GQLProviderPlans } from './data/di'
import { PlansContextProvider } from './context'
import { useAppContext } from '../../context/app-context'
import PlansViews from './views'

const PlansView = (props) => {
  const { appData } = useAppContext()
  const data = {
    plan: appData.plan || props.plan,
    account: appData.account || props.account,
    user: appData.user || props.user,
  }
  console.log('data', data)
  return (
    <PlansContextProvider data={data} provider={GQLProviderPlans()}>
      <PlansViews />
    </PlansContextProvider>
  )
}

export default PlansView
