import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
  S_POLL,
} from '../../utils/states-actions'
import { Filters } from '../services/checks/states-actions'
export const DefaultFilter = (defaultTeam = '', asMap = false) => {
  return {
    by: Filters.user,
    team: defaultTeam,
    forceFetch: false,
    asMap: asMap,
  }
}
export const StateInitial = (defaultTeam) => {
  return {
    type: S_LOAD_START,
    checks: {},
    filter: DefaultFilter(defaultTeam),
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoaded = (data) => {
  console.log('StateLoaded: ', data)
  return {
    type: S_LOAD_DONE,
    filter: {
      by: Filters.user,
      team: '',
      forceFetch: false,
    },
    checks: data,
    errors: {},
  }
}
export const StateNoData = {
  type: S_NO_DATA,
  checks: [],
  errors: {},
}
export const actionLoaded = (data) => {
  const checks = [...data]
  return {
    reduce: (state) => {
      const dataForMap = checks.filter(
        (item) => item.src_geoip && item.dst_geoip
      )
      return {
        ...StateLoaded(state.filter.asMap ? dataForMap : checks),
        filter: { ...state.filter, forceFetch: false },
      }
    },
  }
}
export const actionPolling = (data) => {
  const checks = [...data]
  return {
    reduce: (state) => {
      const loaded = actionLoaded(checks)
      return {
        ...loaded.reduce(state),
        type: S_POLL,
      }
    },
  }
}
export const actionFilterChange = (data) => {
  const filter = data
  return {
    reduce: (state) => {
      return { ...state, type: S_LOAD_START, ...filter }
    },
  }
}
