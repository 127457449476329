import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedUser,
  actionOnUserChange,
  actionSaveUser,
  StateInitial,
  actionToggleTeam,
  actionOnAddedToTeam,
  actionOnRemovedFromTeam,
} from './states-actions'
import { eventToText } from '../../../utils/eventsExt'
import { actionLoadFailed } from '../../../utils/states-actions'
import { reducer } from '../../../components/reducer'

export const useManageUser = (data, provider) => {
  const { id, accountId } = data
  const [state, dispatch] = useReducer(reducer, StateInitial())

  const onChooseRole = (event) => {
    dispatch(actionOnUserChange({ role: event.target.value }))
  }
  const doToggleUserTeam = (teamId) => {
    dispatch(actionToggleTeam(teamId, state.user.teams))
  }
  const toggleUserTeam = (teamId) => {
    const shouldAddTo = state.addingToTeam[teamId]
    const onError = (err) => {
      console.log('::toggleUserTeam llOnError:', err)
      // TODO: Handle Failed case
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(
        shouldAddTo ? actionOnAddedToTeam(data) : actionOnRemovedFromTeam(data)
      )
    }

    return shouldAddTo
      ? provider.AddToTeam(id, teamId, onDone, onError)
      : provider.RemoveFromTeam(id, teamId, onDone, onError)
  }

  const onFieldChange = (value) => {
    const keyValPair = {}
    keyValPair[`${value.target.id}`] = eventToText(value)
    dispatch(actionOnUserChange({ ...keyValPair }))
  }

  const getEditField = (name) => {
    const value =
      typeof state.userEdit[name] === 'undefined'
        ? state.user[name]
        : state.userEdit[name]
    return {
      value: value || '',
      error: state.errors[name] || null,
    }
  }

  const Load = (userId) => {
    const onError = (err) => {
      console.log('::LoadUser llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(actionLoadedUser(data[0]))
    }
    provider.GetUsers(accountId, userId, onLoad, onError)
  }

  const updateRole = () => {
    const onError = (err) => {
      console.log('::UpdateRole llOnError:', err)
      // TODO: Handle Failed case
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionLoadedUser(data))
    }

    return provider.UpdateRole(id, state.userEdit.role, onDone, onError)
  }

  const hasChanges = () => {
    return Object.keys(state.userEdit).length > 0
  }

  const doSave = () => {
    dispatch(actionSaveUser())
  }

  return {
    state,
    id,
    accountId,
    load: Load,
    updateRole,
    hasChanges,
    onChooseRole,
    doSave,
    doToggleUserTeam,
    toggleUserTeam,
    getEditField,
    onFieldChange,
  }
}

export const ManageUserContext = createContext({})
export const useManageUserContext = () => useContext(ManageUserContext)
export const ManageUserContextProvider = (props) => {
  const hookRef = useManageUser(props.data, {
    ...props.provider,
    ...(props.providerUser || {}),
  })

  return (
    <ManageUserContext.Provider value={hookRef}>
      {props.children}
    </ManageUserContext.Provider>
  )
}
