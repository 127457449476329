export const reducer = (state, action) => {
  try {
    return action.reduce(state)
  } catch (exc) {
    console.error(
      'Reduce failed for action',
      action,
      ' with message: ',
      exc.message
    )
    throw new Error(`Unknown action: ${JSON.stringify(action)}`)
  }
}
