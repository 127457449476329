/**
 * Takes changed field value, compares it with original and if it's different accepts it.
 * That way if user changes one field and then reverts it or original we don't send that change
 * @param data Example: {'name': 'Djura'}
 * @param state
 * @param field (key like team, account, profile)
 * @returns {*} Updated state
 */
export const getDiff = (data, state, field) => {
  const changedKey = Object.keys(data)[0]
  const editHolderKey = `${field}Edit`
  const dataEdit = { ...state[editHolderKey], ...data }
  const origValue = state[field][changedKey]
  const newValue = dataEdit[changedKey]
  if ((origValue === null && newValue === '') || origValue === newValue) {
    console.log('NOTHING CHANGED: ', changedKey, origValue)
    delete dataEdit[changedKey]
  }
  const result = { ...state }
  result[editHolderKey] = dataEdit
  return result
}
