import React from 'react'
import {
  buildForUpdate,
  CacheChangeType,
  ExecuteGQLWithBuilder,
} from '../../../../utils/executeGraphQL'
import { Entity } from '../../../entities'
import { FIELDS_USER } from '../../user/edit/data/get-user'
import { updateCacheUsers } from './di'

export const UpdateUserRole = (props) => {
  const { role, id } = props
  const builderData = buildForUpdate(
    Entity.user,
    FIELDS_USER,
    { id: id },
    { role: role }
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onDone}
      onError={props.onError}
      cacheModifyBlock={(cache, updated) => {
        updateCacheUsers(
          cache,
          { id: id, ...updated.users[0] },
          CacheChangeType.update
        )
      }}
    />
  )
}

export default UpdateUserRole
