import React from 'react'
import EditField from '../../../../../components/edit-field'
import DropDown from '../../../../../components/dropdown'
import { Days, NTF_TYPE } from '../states-actions'
import { Row } from 'reactstrap/lib'
import { Checkbox, FormControlLabel, FormLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import Subtitle from '../../../../../components/Subtitle'

export const notificationStyles = (theme) => ({
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  startEndHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'start',
    padding: '8px',
  },
  startEnd: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '8px',
  },
})

export const StartEnd = (props) => {
  const { getEditField, onFieldChange, t, classes } = props
  const fieldStart = getEditField(`${props.field}_start`)
  const fieldEnd = getEditField(`${props.field}_end`)
  const hasErrors =
    (fieldStart.error && fieldStart.error.length > 0) ||
    (fieldEnd.error && fieldEnd.error.length > 0)
  const errorMessage1 =
    hasErrors && fieldStart.error && fieldStart.error.length > 0
      ? fieldStart.error
      : null
  const errorMessage2 =
    hasErrors && fieldEnd.error && fieldEnd.error.length > 0
      ? fieldEnd.error
      : null

  return (
    <>
      {props.groupLabel && (
        <FormLabel
          component="label"
          htmlFor={`${props.field}_start`}
          error={hasErrors}
          disabled={props.disabled}
        >
          {t(`notification_edit.${props.field}`)}
          {hasErrors && ` (${t(errorMessage1 || errorMessage2)})`}
        </FormLabel>
      )}
      <Row className={classes.startEndHolder}>
        <EditField
          field={`${props.field}_start`}
          label={t('notification_edit.start')}
          disabled={props.disabled}
          getEditField={getEditField}
          onEditFieldChange={onFieldChange}
          fullWidth={false}
          className={classes.startEnd}
          asRow={false}
          inputLabelProps={{
            shrink: true,
          }}
          timestep={1800}
          fieldType={'time'}
        />
        <EditField
          field={`${props.field}_end`}
          label={t('notification_edit.end')}
          disabled={props.disabled}
          getEditField={getEditField}
          onEditFieldChange={onFieldChange}
          fullWidth={false}
          className={classes.startEnd}
          asRow={false}
          inputLabelProps={{
            shrink: true,
          }}
          timestep={1800}
          fieldType={'time'}
        />
      </Row>
    </>
  )
}

StartEnd.propTypes = {
  classes: PropTypes.object,
  groupLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.oneOf([
    'global',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]),
}

const NotificationsScheduleEdit = (props) => {
  const { getEditField, onFieldChange, t, classes, isCustomTemplate } = props
  const notificationType = getEditField('type').value
  const showPickers = notificationType !== NTF_TYPE.allday
  const showGlobalTimes = notificationType !== NTF_TYPE.custom

  return (
    <>
      <DropDown
        formControlClass={classes.formControl}
        defaultValue={NTF_TYPE.allday}
        data={[
          { value: NTF_TYPE.allday, text: t('notification_edit.allday') },
          { value: NTF_TYPE.everyday, text: t('notification_edit.everyday') },
          { value: NTF_TYPE.workdays, text: t('notification_edit.workdays') },
          { value: NTF_TYPE.custom, text: t('notification_edit.custom') },
        ]}
        disabled={!isCustomTemplate}
        field="type"
        label={t('notification_edit.allow_notifications')}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
      />
      {showPickers && (
        <>
          {showGlobalTimes ? (
            <StartEnd
              field={'global'}
              t={t}
              getEditField={getEditField}
              onFieldChange={onFieldChange}
              disabled={!isCustomTemplate}
              classes={classes}
            />
          ) : (
            [...Days].map((day, index) => (
              <StartEnd
                key={index}
                groupLabel={true}
                field={day}
                t={t}
                disabled={!isCustomTemplate}
                getEditField={getEditField}
                onFieldChange={onFieldChange}
                classes={classes}
              />
            ))
          )}
        </>
      )}
    </>
  )
}

export const NotificationSchedule = (props) => {
  const { t, getEditField, onFieldChange } = props
  console.log('poppppp', props)
  const isActive = getEditField('active', false).value ?? true
  const notificationType = getEditField('type').value
  const showActive = notificationType !== NTF_TYPE.allday

  return (
    <>
      <Subtitle>{t('notification_edit.schedule')}</Subtitle>
      {showActive && (
        <FormControlLabel
          control={
            <Checkbox
              id={'active'}
              name={'active'}
              size={'small'}
              checked={isActive}
              onClick={onFieldChange}
              inputProps={{
                'data-testid': 'check-active',
              }}
            />
          }
          label={<Subtitle>{t('team_edit.active')}</Subtitle>}
        />
      )}
      {(!showActive || isActive) && (
        <NotificationsScheduleEdit {...props} isCustomTemplate={true} />
      )}
    </>
  )
}
