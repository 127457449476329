import React from 'react'
import { withTranslation } from 'react-i18next'
import Title from './Title'
import { Row } from 'reactstrap'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(1),
    width: '100%',
  },
  message: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    margin: 'auto',
  },
  button: {
    marginLeft: 'auto',
  },
})

const NoDataView = (props) => {
  const { t, classes } = props
  return (
    <Row className={classes.root}>
      <Title className={classes.message}>{t(props.message ?? 'No data')}</Title>
    </Row>
  )
}
export default withTranslation()(withStyles(styles)(NoDataView))

NoDataView.propTypes = {
  message: PropTypes.string,
}
