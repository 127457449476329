import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
  S_SAVING,
} from '../../utils/states-actions'
import { PlanType } from '../../components/current-plan'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    plans: {},
    prices: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedPlans = (data, prices) => {
  return {
    type: S_LOAD_DONE,
    plans: data,
    prices: prices,
    errors: {},
  }
}
export const StateNoPlans = {
  type: S_NO_DATA,
  plans: [],
  prices: {},
  errors: {},
}
export const StateSaving = {
  type: S_SAVING,
}
export const StateCreated = (data) => {
  return {
    type: S_CREATED,
    plan: data,
    planEdit: {},
    errors: {},
  }
}
const PlanNameToDomainPlanType = (dataPlanName) => {
  switch (dataPlanName) {
    case 'Single User':
      return PlanType.singleUser
    case 'Single Team':
      return PlanType.singleTeam
    default:
      return PlanType.multipleTeams
  }
}
export const actionLoadedPlans = (data) => {
  const plans = [...data]
  return {
    reduce: () => {
      const prices = {
        per_month: {},
        per_year: {},
      }
      plans.forEach((ppp) => {
        console.log('ppp', ppp)
        const pName = PlanNameToDomainPlanType(ppp.name)
        prices.per_month[pName] = {
          planId: ppp.id_monthly,
          price: ppp.price_monthly,
        }
        prices.per_year[pName] = {
          planId: ppp.id_annual,
          price: ppp.price_annual,
        }
      })
      return StateLoadedPlans(plans, prices)
    },
  }
}
export const actionNoPlans = {
  reduce: (state) => {
    return { ...state, ...StateNoPlans }
  },
}
export const actionCreateSub = (data) => {
  const ddd = data
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving,
        create: { ...ddd },
      }
    },
  }
}
export const actionCreatedSub = (data) => {
  const sub = { ...data }
  return {
    reduce: (state) => {
      console.log('createdSub', sub)
      return state
    },
  }
}
