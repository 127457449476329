import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import SaveButton from '../../../../../components/save-button'
import { NotificationSchedule, notificationStyles } from './edit-schedule'
import EditField from '../../../../../components/edit-field'
import Subtitle from '../../../../../components/Subtitle'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import { Entity } from '../../../../entities'
import { HookTypes } from '../../../../teams/team/edit/data/mapper'
import { USER_NTF_TYPE } from '../states-actions'
import DropDown from '../../../../../components/dropdown'

export const NotificationHook = (props) => {
  const { getEditField, onFieldChange, t, classes, hookType, fieldType } = props
  const isEnabled = getEditField(`hook_${hookType}_active`, false).value
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'stretch'}
      p={1}
    >
      <Checkbox
        id={`hook_${hookType}_active`}
        size={'small'}
        key={`check-${hookType}`}
        checked={isEnabled}
        onClick={onFieldChange}
        inputProps={{ 'data-testid': `check-${hookType}-value` }}
      />
      <EditField
        field={`${hookType}_value`}
        label={t(`notification_edit.${hookType}`)}
        disabled={!isEnabled}
        asRow={false}
        fullWidth={true}
        getEditField={getEditField}
        onEditFieldChange={onFieldChange}
        className={classes.startEnd}
        fieldType={fieldType}
      />
    </Box>
  )
}

export const NotificationHooks = (props) => {
  const { getEditField, onFieldChange, t, classes } = props
  return (
    <>
      <Subtitle>{t('notification_edit.hooks')}</Subtitle>
      <NotificationHook
        getEditField={getEditField}
        onFieldChange={onFieldChange}
        t={t}
        classes={classes}
        hookType={HookTypes.slackWebhook}
        fieldType={'url'}
      />
      <NotificationHook
        getEditField={getEditField}
        onFieldChange={onFieldChange}
        t={t}
        classes={classes}
        hookType={HookTypes.voiceOnCall}
        fieldType={'text'}
      />
    </>
  )
}
export const NotificationContactHook = (props) => {
  const { doToggleContactHook, t, useEmail, useSMS } = props
  const fieldEmail = 'ntf_email_enabled'
  const fieldSMS = 'ntf_sms_enabled'

  return (
    <>
      <Subtitle>{t('notification_edit.notify_via')}</Subtitle>
      <FormControlLabel
        control={
          <Checkbox
            id={fieldEmail}
            size={'small'}
            checked={useEmail}
            onClick={doToggleContactHook}
            inputProps={{
              'data-testid': 'check-use-email',
            }}
          />
        }
        label={<Subtitle>{t('notification_edit.use_email')}</Subtitle>}
      />
      <FormControlLabel
        control={
          <Checkbox
            id={fieldSMS}
            size={'small'}
            checked={useSMS}
            onClick={doToggleContactHook}
            inputProps={{
              'data-testid': 'check-use-sms',
            }}
          />
        }
        label={<Subtitle>{t('notification_edit.use_sms')}</Subtitle>}
      />
      <br />
    </>
  )
}

const EditNotificationsWithHooks = (props) => {
  const { hasChanges, doSave } = props

  return (
    <>
      <NotificationSchedule {...props} />
      <SaveButton hasChanges={hasChanges} handleClick={doSave} />
    </>
  )
}

const EditNotificationsWithContact = (props) => {
  const { getScheduleField, onScheduleChange, hasChanges, doSave, t, classes } =
    props

  return (
    <>
      <DropDown
        formControlClass={classes.formControl}
        defaultValue={USER_NTF_TYPE.email}
        data={[
          {
            value: USER_NTF_TYPE.email,
            text: t('notification_edit.scheduleSource.email'),
          },
          {
            value: USER_NTF_TYPE.sms,
            text: t('notification_edit.scheduleSource.sms'),
          },
        ]}
        field="current_schedule"
        label={t('notification_edit.choose_schedule')}
        getEditField={getScheduleField}
        onEditFieldChange={onScheduleChange}
      />
      <NotificationSchedule {...props} />
      <SaveButton hasChanges={hasChanges} handleClick={doSave} />
    </>
  )
}

const NotificationsSettings = (props) => {
  const { forEntity } = props
  console.log('NotificationsSettings: props', props)
  switch (forEntity) {
    case Entity.account:
    case Entity.team:
      return <EditNotificationsWithHooks {...props} />
    default:
      return <EditNotificationsWithContact {...props} />
  }
}
export default withTranslation()(
  withStyles(notificationStyles)(NotificationsSettings)
)
