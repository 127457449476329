import React from 'react'
import RequestCode from './request_code'
import VerifyUserAttribute from './verify_user_attribute'
import { UserMapperDataToDomain } from '../../data/mapper'

export const GraphQLProviderVerifyCode = () => {
  const requestCode = (profileId, attributeName, onLoad, onError) => {
    return (
      <RequestCode
        profileId={profileId}
        attributeName={attributeName}
        onError={onError}
        onDone={onLoad}
      />
    )
  }
  const verifyAttr = (profileId, attributeName, code, onLoad, onError) => {
    return (
      <VerifyUserAttribute
        profileId={profileId}
        attributeName={attributeName}
        code={code}
        onError={onError}
        onDone={onLoad}
        mapper={UserMapperDataToDomain}
      />
    )
  }

  return {
    doRequestCode: requestCode,
    doVerifyUserAttribute: verifyAttr,
  }
}
