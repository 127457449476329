import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useWelcomeContext } from '../context'
import {
  S_NO_DATA,
  S_LOAD_ERR,
  S_LOAD_START,
} from '../../../utils/states-actions'
import ErrorCmp from '../../../components/error'
import { S_LOGGED_IN } from '../states-actions'
import StoreCognitoUser from './store-cognito-user'
import cognitoUtils from '../../../cognito/lib/cognitoUtils'
import LoadingView from '../../../components/loading-view'

const styles = () => ({
  root: {
    maxWidth: 700,
    overflowX: 'auto',
    margin: 'auto',
  },
})

const WelcomeViews = (props) => {
  const hookRef = useWelcomeContext()
  const { state } = hookRef
  const { classes, href } = props
  console.log(state)

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.checkSession(href)}
            <LoadingView />
          </>
        )
      case S_NO_DATA:
        window.location.href = cognitoUtils.getCognitoSignInUri()
        return <></>
      case S_LOGGED_IN:
        return <StoreCognitoUser />
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return <Paper className={classes.root}>{getByState()}</Paper>
}

export default withStyles(styles)(WelcomeViews)
