import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useIncidentsContext } from '../context'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../utils/states-actions'
import IncidentsList from './list'
import ErrorCmp from '../../../components/error'
import { withTranslation } from 'react-i18next'
import LoadingView from '../../../components/loading-view'
import NoDataView from '../../../components/no-data-view'
import TopBar from '../../../components/topbar'

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  button: {
    marginLeft: 'auto',
  },
})

const IncidentsViews = (props) => {
  const hookRef = useIncidentsContext()
  const { state, account } = hookRef
  const { classes, t } = props
  // console.log(state)
  const addingIds = [...state.ackInProgress]

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.loadIncidents(account.id)}
            <LoadingView />
          </>
        )
      case S_DELETE:
      case S_LOAD_DONE:
        return (
          <>
            {addingIds.length > 0 && hookRef.acknowledge(addingIds.pop())}
            {(props.incidentsList && props.incidentsList(hookRef)) || (
              <IncidentsList {...hookRef} actionsTitle={t('actions')} />
            )}
          </>
        )
      case S_NO_DATA:
        return <NoDataView message={t('incidents.no_data')} />
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar titleText={t('incidents.title')} hideBack={true} />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(IncidentsViews))
