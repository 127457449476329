import React from 'react'
import UpdateNotification from './update'
import CreateSchedule from './create'
import { useAppContext } from '../../../../../context/app-context'
import GetNotificationSettings, { SCHEDULE_DEFAULT } from './get'
import { useTeamContext } from '../../../../teams/team/edit/context'
import { useAccountContext } from '../../../../accounts/account/edit/context'
import {
  NotificationMapperDataToDomain,
  ScheduleMapperDataToDomain,
} from './mapper'
import { Entity } from '../../../../entities'
import UpdateUserNotification from './update-user-notification'
import {
  UserMapperDataToDomain,
  UserNotificationMapperDomainToData,
} from '../../../../users/user/edit/data/mapper'

export const getEntity = (forEntity, entityId) => {
  const entityHook = getEntityHook(forEntity)
  switch (forEntity) {
    case Entity.account:
      return entityHook.state.account
    case Entity.team:
      return entityHook.state.team
    case Entity.user:
      return (
        entityHook.appData.users.filter((usr) => usr.id === entityId)[0] ?? {}
      )
    default:
      return null
  }
}
export const getEntityHook = (forEntity) => {
  switch (forEntity) {
    case Entity.account:
      return useAccountContext()
    case Entity.team:
      return useTeamContext()
    case Entity.user:
      return useAppContext()
    default:
      return null
  }
}
export const GQLProviderNotification = () => {
  const Get = async (props) => {
    const { forEntity, entityId, accountId, scheduleId, onLoad, onError } =
      props
    console.log(
      'forEntity, entityId, accountId: ',
      forEntity,
      entityId,
      accountId
    )
    if (scheduleId === SCHEDULE_DEFAULT) {
      onLoad(NotificationMapperDataToDomain(SCHEDULE_DEFAULT))
      return
    }
    GetNotificationSettings({
      justFirst: true,
      accountId: accountId,
      scheduleId: scheduleId,
      mapper: NotificationMapperDataToDomain,
      onLoad: onLoad,
      onError: onError,
    })
  }
  const update = (id, data, onLoad, onError) => {
    return (
      <UpdateNotification
        id={id}
        data={data}
        onError={onError}
        onLoad={(updated) => {
          onLoad(ScheduleMapperDataToDomain(updated))
        }}
      />
    )
  }
  const updateUserNotification = (userId, user, onLoad, onError) => {
    console.log('updateUserNotification:: userId, user', userId, user)
    UpdateUserNotification({
      id: userId,
      data: UserNotificationMapperDomainToData(user),
      onError: onError,
      onLoad: (updated) => {
        onLoad(UserMapperDataToDomain(updated))
      },
    })
  }
  const create = (forEntity, entityId, data, onLoad, onError) => {
    return (
      <CreateSchedule
        data={data}
        onError={onError}
        onDone={(created) => {
          onLoad(ScheduleMapperDataToDomain(created))
        }}
      />
    )
  }

  return {
    GetNotification: Get,
    UpdateNotification: update,
    UpdateNotificationForUser: updateUserNotification,
    CreateNotification: create,
  }
}
