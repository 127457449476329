import { S_LOAD_START, S_NO_DATA } from '../../utils/states-actions'

export const S_LOGGED_IN = 300

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    message: 'Please wait',
  }
}
export const StateNotLoggedIn = () => {
  return {
    type: S_NO_DATA,
    message: '',
  }
}
export const StateLoggedIn = (data) => {
  return {
    type: S_LOGGED_IN,
    session: data,
  }
}
export const actionNoSession = () => {
  return {
    reduce: () => {
      return StateNotLoggedIn()
    },
  }
}
export const actionOnSession = (data) => {
  const user = { ...data }
  return {
    reduce: () => {
      return StateLoggedIn(user)
    },
  }
}
