import React from 'react'
import { GQLProviderServiceCheck } from './data/provider'
import { ServiceCheckContextProvider } from './context'
import ServiceCheckViews from './views'
import { useAppContext } from '../../../../context/app-context'

const ServiceCheckView = (props) => {
  const { appData } = useAppContext()
  const data = {
    id: props.match.params.checkId,
    groupId: props.match.params.groupId,
    userId: appData.user.id,
    accountId: appData.account.id,
    plan: appData.plan,
  }
  return (
    <ServiceCheckContextProvider
      data={data}
      provider={GQLProviderServiceCheck()}
    >
      <ServiceCheckViews />
    </ServiceCheckContextProvider>
  )
}

export default ServiceCheckView
