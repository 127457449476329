import { Button } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

const styles = () => ({
  button: {
    display: 'block',
    marginLeft: 'auto',
  },
})
const SaveButton = (props) => {
  const { classes, hasChanges, handleClick, t } = props
  if (!hasChanges()) {
    return null
  }
  return (
    <Button
      id={'btn-save'}
      className={props.className || classes.button}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      {t('save')}
    </Button>
  )
}

export default withTranslation()(withStyles(styles)(SaveButton))
