import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedSubscription,
  actionNoSubscription,
  StateInitial,
} from './states-actions'
import { reducer } from '../../../components/reducer'
import { actionLoadFailed } from '../../../utils/states-actions'

export const useSubscription = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { plan, user } = data

  const Load = () => {
    const onError = (err) => {
      console.log('::LoadSubscription llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(data ? actionLoadedSubscription(data) : actionNoSubscription)
    }
    return provider.GetInitData(user.id, onLoad, onError)
  }

  return {
    state,
    plan,
    load: Load,
  }
}

export const SubscriptionContext = createContext({})
export const useSubscriptionContext = () => useContext(SubscriptionContext)
export const SubscriptionContextProvider = (props) => {
  const hookRef = useSubscription(props.data, props.provider)

  return (
    <SubscriptionContext.Provider value={hookRef}>
      {props.children}
    </SubscriptionContext.Provider>
  )
}
