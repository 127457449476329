import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'

const DropDown = (props) => {
  const { getEditField, onEditFieldChange, data } = props
  const field = getEditField(props.field)
  const lblId = `${props.field}-label`
  // console.log(
  //   'defValue',
  //   props.defaultValue,
  //   'props.field',
  //   props.field,
  //   'field',
  //   field
  // )
  return (
    <Row>
      <FormControl className={props.formControlClass} disabled={props.disabled}>
        <InputLabel id={lblId}>
          {props.label || props.field.replaceAll(/_/g, ' ')}
        </InputLabel>
        <Select
          labelId={lblId}
          id={props.field}
          name={props.field}
          defaultValue={props.defaultValue}
          value={props.format ? props.format(field.value) : field.value || ''}
          onChange={onEditFieldChange}
          disabled={props.disabled}
          error={field.error && field.error.length > 0}
          inputProps={{
            'data-testid': props.field,
          }}
        >
          {data.map((item, index) => (
            <MenuItem key={index} value={item.value ?? item}>
              {item.text ?? item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Row>
  )
}
export default DropDown

DropDown.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  data: PropTypes.array.isRequired,
  formControlClass: PropTypes.string,
  format: PropTypes.func,
  trans: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onEditFieldChange: PropTypes.func,
  getEditField: PropTypes.func,
}
