import { Entity } from '../../entities'

export const ShouldAllowAcknowledge = (incident, userId) => {
  if (!incident.acknowledged) {
    return true
  }
  for (const ackBy of incident.acknowledgedBy) {
    if (ackBy.entity === Entity.user && ackBy.entityId === userId) {
      return false
    }
  }
  return true
}
