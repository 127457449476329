import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import TabPanel from '../../components/tab-panel'
import AccountEditView from './account/edit'
import TeamsView from '../teams'
import { useHistory } from 'react-router'
import { Routes } from '../../routes'
import UsersView from '../users'

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  tabPanel: {
    maxWidth: '100%',
    overflowX: 'auto',
    margin: 'auto',
  },
  tab: {
    color: '#2f4f4f',
    borderBottom: '1px solid #2f4f4f',
    textTransform: 'capitalize',
  },
})

const a11yProps = (index) => ({
  id: `account-tab-${index}`,
  'aria-controls': `account-tabpanel-${index}`,
})

const pathToTab = (path) => {
  if (!path) {
    return undefined
  }
  if (path.indexOf(Routes.teams) >= 0) {
    return 1
  } else if (path.indexOf(Routes.users) >= 0) {
    return 2
  } else if (path.indexOf(Routes.account) >= 0) {
    return 0
  } else {
    return undefined
  }
}

export const AccountView = (props) => {
  const { t, classes } = props
  const currentTab =
    pathToTab(props.match && props.match.path) || props.preselectedTab || 0
  const history = useHistory()

  const handleChange = (event, tab) => {
    switch (tab) {
      case 1:
        history.push(Routes.teams)
        break
      case 2:
        history.push(Routes.users)
        break
      default:
        history.push(Routes.account)
        break
    }
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="account tabs"
        >
          <Tab
            className={classes.tab}
            label={t('account.title')}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label={t('teams.title')}
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tab}
            label={t('users.title')}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <TabPanel className={classes.tabPanel} value={currentTab} index={0}>
        {props.tabBodyAccount || <AccountEditView />}
      </TabPanel>

      <TabPanel className={classes.tabPanel} value={currentTab} index={1}>
        {props.tabBodyTeams || <TeamsView />}
      </TabPanel>

      <TabPanel className={classes.tabPanel} value={currentTab} index={2}>
        {props.tabBodyUsers || <UsersView />}
      </TabPanel>
    </div>
  )
}

export default withTranslation()(withStyles(styles)(AccountView))
