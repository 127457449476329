import { PlanType } from './components/current-plan'
import { Entity } from './features/entities'

export const Routes = {
  index: '/',
  businesses: '/businesses',
  profile: '/profile',
  account: '/accounts',
  teams: '/accounts/teams',
  auth: '/auth',
  team: (id = ':id') => `/accounts/teams/${id}`,
  incidents: '/incidents',
  incident: (id = ':serviceId', ts = ':timestamp') => `/incidents/${id}/${ts}`,
  teamNew: '/accounts/teams/new',
  userManage: (account = ':account', userId = ':id') =>
    `/accounts/${account}/manage/${userId}`,
  users: '/accounts/users',
  userNew: (account = ':account', id = ':id') =>
    `/accounts/${account}/users/${id}`,
  subscription: '/subscription',
  subscriptions: '/subscriptions',
  services: '/services',
  serviceGroupLegacy: (groupId = ':groupId') => `/services/${groupId}`,
  serviceGroup: () => '/services',
  serviceEditRoute: (checkId = ':checkId') => `/services/${checkId}`,
  overview: '/overview',
  checkResults: (checkId = ':checkId') => `/checks/${checkId}`,
  notificationsAccount: (entityId = ':entityId', scheduleId = ':scheduleId') =>
    `/notifications/${Entity.account}/${entityId}/${scheduleId}`,
  notificationsTeam: (entityId = ':entityId', scheduleId = ':scheduleId') =>
    `/notifications/${Entity.team}/${entityId}/${scheduleId}`,
  notificationsUser: (entityId = ':entityId') =>
    `/notifications/${Entity.user}/${entityId}`,
  canAccess: (route, planName) => {
    switch (route) {
      case Routes.users:
      case Routes.teams:
        return planName !== PlanType.free && planName !== PlanType.singleUser
      default:
        return false
    }
  },
  isSubscribed: (route, plan) => {
    console.log('isSubscribed route: ', route, plan)
    if (plan.status !== 'active') {
      return Routes.subscriptions
    }
    return route
  },
}
