import React from 'react'
import GetPlans from './get'
import PropTypes from 'prop-types'
import CreateSubscription from './create'

export const GQLProviderPlans = () => {
  const get = (onLoad, onError) => {
    return <GetPlans onLoad={onLoad} onError={onError} />
  }

  get.propTypes = {
    planName: PropTypes.string.isRequired,
    accountId: PropTypes.string,
    userId: PropTypes.string,
  }
  const create = (onLoad, onError) => {
    return (
      <CreateSubscription onLoad={onLoad} onError={onError} planName={''} />
    )
  }

  return {
    GetPlans: get,
    CreateSub: create,
  }
}
