const baseUrl = process.env.REACT_APP_BASE || 'http://www.dev.xcmonitor.com'
export default {
  region: 'us-east-1',
  userPool: 'us-east-1_Z0lrr1BeJ',
  userPoolBaseUri: 'https://auth.dev.xcmonitor.com',
  clientId:
    process.env.REACT_APP_COGNITO_CLIENT_ID || '5tlet51654sb0je2r6ssjt3fev',
  callbackUri: baseUrl + '/auth/callback/',
  signoutUri: baseUrl + '/auth/signout/',
  tokenScopes: [
    'openid',
    'email',
    'phone',
    'profile',
    'https://auth.dev.xcmonitor.com/hello-world.all',
  ],
  apiUri: 'https://api.dev.xcmonitor.com',
}
