import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import SaveButton from '../../../../components/save-button'
import { UserRoles } from '../roles'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { TeamsContextProvider } from '../../../teams/context'
import { GQLProviderTeams } from '../../../teams/data/provider'
import TeamsViews from '../../../teams/views'
import ManageTeamsList from './manage-teams-list'
import Avatar from '@material-ui/core/Avatar'
import { stringAvatar } from '../../../../components/UserMenu'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  appBarAvatar: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
  },
  userHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'start',
    padding: '8px',
  },
  userHolderClickable: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '8px',
  },
  rolesHolder: {
    textTransform: 'capitalize',
    marginTop: '8px',
  },
})

export const ProfileView = (props) => {
  const classes = makeStyles(styles)()

  const dataId = props.id ?? ''
  return (
    <Paper
      className={
        props.onClick ? classes.userHolderClickable : classes.userHolder
      }
      onClick={props.onClick}
    >
      {props.withAvatar && (
        <Avatar
          data-testid={`image-${dataId}`}
          className={classes.appBarAvatar}
          {...stringAvatar(props.title ?? '')}
        />
      )}
      <div className={classes.header}>
        <div data-testid={`title-${dataId}`}>{props.title ?? ''}</div>
        <div data-testid={`title-${dataId}`}>{props.subtitle ?? ''}</div>
      </div>
    </Paper>
  )
}
ProfileView.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  withAvatar: PropTypes.bool,
  onClick: PropTypes.func,
}

const ManageUserInfo = (props) => {
  const {
    state,
    accountId,
    getEditField,
    onChooseRole,
    doToggleUserTeam,
    hasChanges,
    doSave,
    classes,
    t,
  } = props
  const role = getEditField('role') || UserRoles.owner
  console.log('role', role)

  return (
    <>
      <ProfileView
        id={state.user.id}
        withAvatar={true}
        title={state.user.name}
        subtitle={state.user.email}
      />
      <FormControl component="fieldset" className={classes.rolesHolder}>
        <FormLabel component="legend">{t('manage_user.role')}</FormLabel>
        <RadioGroup
          aria-label="role"
          name="role"
          value={role.value || UserRoles.owner}
          onChange={onChooseRole}
        >
          <FormControlLabel
            value={UserRoles.owner}
            control={<Radio inputProps={{ 'data-testid': 'role-owner' }} />}
            label={UserRoles.owner}
          />
          <FormControlLabel
            value={UserRoles.admin}
            control={<Radio inputProps={{ 'data-testid': 'role-admin' }} />}
            label={UserRoles.admin}
          />
          <FormControlLabel
            value={UserRoles.viewer}
            control={<Radio inputProps={{ 'data-testid': 'role-viewer' }} />}
            label={UserRoles.viewer}
          />
        </RadioGroup>
      </FormControl>

      <SaveButton hasChanges={hasChanges} handleClick={doSave} />
      {props.teamsListComp || (
        <TeamsContextProvider
          data={{ accountId: accountId }}
          provider={GQLProviderTeams()}
        >
          <TeamsViews
            teamsList={(props) => (
              <ManageTeamsList
                {...props}
                user={state.user}
                doToggleTeam={doToggleUserTeam}
                manageState={state}
              />
            )}
          />
        </TeamsContextProvider>
      )}
    </>
  )
}
export default withTranslation()(withStyles(styles)(ManageUserInfo))
