import { Link as MUILink, Typography } from '@material-ui/core'
import React from 'react'

export const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <MUILink color="inherit" href="https://thextracode.com/">
      THE XTRACODE, Inc
    </MUILink>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
)
