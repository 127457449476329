import React from 'react'
import Title from '../../../../../components/Title'
import Loading from '../../../../../components/Loading'
import { withTranslation } from 'react-i18next'

const SavingProfile = (props) => {
  const { t } = props

  return (
    <>
      <Loading />
      <Title className={'profile-saving'}>{t('saving')}</Title>
    </>
  )
}
export default withTranslation()(SavingProfile)
