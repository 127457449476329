import { getDiff } from '../../../utils/diff'
import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_SAVING,
} from '../../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    user: {},
    userEdit: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedUser = (data) => {
  return {
    type: S_LOAD_DONE,
    user: data,
    userEdit: {},
    errors: {},
  }
}
export const StateSaving = () => {
  return {
    type: S_SAVING,
  }
}

export const actionSaveUser = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving(),
      }
    },
  }
}
export const actionLoadedUser = (data) => {
  const user = data
  return {
    user,
    reduce: () => {
      return StateLoadedUser({ ...user })
    },
  }
}
export const actionOnUserChange = (data) => {
  const usr = data
  return {
    reduce: (state) => {
      return { ...getDiff(usr, state, 'user') }
    },
  }
}
export const actionToggleTeam = (teamId, teams) => {
  const isInTeam =
    Object.values(teams || []).findIndex((team) => {
      return team.id === teamId
    }) >= 0
  return {
    reduce: (state) => {
      const addingToTeam = { ...state.addingToTeam }
      addingToTeam[teamId] = !isInTeam
      return { ...state, addingToTeam }
    },
  }
}
export const actionOnAddedToTeam = (team) => {
  const org = team
  return {
    reduce: (state) => {
      return updateTeams(state, org, true)
    },
  }
}
export const actionOnRemovedFromTeam = (team) => {
  const org = team
  return {
    reduce: (state) => {
      return updateTeams(state, org, false)
    },
  }
}

const updateTeams = (state, team, addTeam) => {
  console.log('updateTeams', state, team, addTeam)
  const result = { ...state }
  if (result.addingToTeam[team.id] !== undefined) {
    delete result.addingToTeam[team.id]
  }
  const user = { ...result.user }
  user.teams = (user.teams || []).filter((existing) => {
    return existing.id !== team.id
  })
  result.user = user
  if (addTeam) {
    result.user.teams.push(team)
  }
  return result
}
