import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedEvents,
  actionNoEvents,
  StateInitial,
} from './states-actions'
import { reducer } from '../../../components/reducer'
import { actionLoadFailed } from '../../../utils/states-actions'

export const useEvents = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { account, serviceId, timestamp } = data

  const Load = (accountId, serviceId, timestamp) => {
    console.log('::LoadEvents:', accountId)
    const onError = (err) => {
      console.log('::LoadEvents llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('::LoadEvents llOnLoad:', data)
      dispatch(data.length > 0 ? actionLoadedEvents(data) : actionNoEvents)
    }
    return provider.GetEvents(accountId, serviceId, timestamp, onLoad, onError)
  }

  const doDelete = (incId) => {
    console.log('doDeleteEvent: ', incId)
  }

  return {
    state,
    account,
    serviceId,
    timestamp,
    loadEvents: Load,
    doDeleteEvent: doDelete,
  }
}

export const EventsContext = createContext({})
export const useEventsContext = () => useContext(EventsContext)
export const EventsContextProvider = (props) => {
  const hookRef = useEvents(props.data, props.provider)

  return (
    <EventsContext.Provider value={hookRef}>
      {props.children}
    </EventsContext.Provider>
  )
}
