import React from 'react'
import { ExecuteGQLDummy } from '../../../utils/executeGraphQL'
import { Entities } from '../../entities'

// const QUERY = gql`
//   query {
//     ${Entities.plans} {
//       ${QUERY_PLAN_FIELDS}
//       paypal_id
//       paypal_id_year
//       price_m
//       price_y
//       description
//       features
//     }
//   }
// `
const GetPlans = (props) => {
  const { onLoad, onError } = props
  return (
    <ExecuteGQLDummy
      actionName={Entities.plans}
      fakeSuccess={[
        {
          id: 'uid-1',
          name: 'Single User',
          id_monthly: 'price_1LWd4xITO1Z68eF92QWQ9ETk',
          id_annual: 'price_1LWd68ITO1Z68eF9MSdDBZ2I',
          price_monthly: 2500,
          price_annual: 22800,
        },
        {
          id: 'uid-1',
          name: 'Single Team',
          id_monthly: 'price_1LWd7hITO1Z68eF9KnVyWbu6',
          id_annual: 'price_1LWd86ITO1Z68eF9Smhk1E2V',
          price_monthly: 6500,
          price_annual: 70800,
        },
        {
          id: 'uid-1',
          name: 'Multiple Teams',
          id_monthly: 'price_1LWd9kITO1Z68eF99iIlN056',
          id_annual: 'price_1LWdBnITO1Z68eF93dW6fDWZ',
          price_monthly: 17000,
          price_annual: 178800,
        },
      ]}
      variables={{}}
      // mapper={(src) => AnalyticsMapperDataToDomain(src)}
      onDone={onLoad}
      onError={onError}
    />
  )
}

export default GetPlans
