import React from 'react'
import {
  buildForConnection,
  ExecuteGQLWithBuilder,
} from '../../../../utils/executeGraphQL'
import { Entity } from '../../../entities'
import { FIELDS_USER } from '../../user/edit/data/get-user'

export const RemoveUserFromTeam = (props) => {
  const team = { id: props.teamId }
  const builderData = buildForConnection(
    Entity.user,
    { id: props.userId },
    Entity.team,
    team,
    FIELDS_USER,
    false
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={() => {
        props.onDone(team)
      }}
      onError={props.onError}
      cacheModifyBlock={(cache, added) => {
        console.log(
          'AddUserToTeam response',
          cache,
          JSON.parse(JSON.stringify(added))
        )
      }}
    />
  )
}

export default RemoveUserFromTeam
