import { withTranslation } from 'react-i18next'
import Title from './Title'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Box, Modal } from '@material-ui/core'
import Subtitle from './Subtitle'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  body: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    padding: theme.spacing(2),
    border: '2px solid #000',
    boxShadow: 24,
  },
})

const XCModal = (props) => {
  const { classes } = props
  return (
    <>
      <Modal
        open={true}
        onClose={props.onDone ?? (() => {})}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.body}>
          <Title id="modal-modal-title">{props.title ?? props.titleText}</Title>
          <Subtitle id="modal-modal-description">
            {props.subtitle ?? props.subtitleText}
          </Subtitle>
        </Box>
      </Modal>
    </>
  )
}
XCModal.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
  onDone: PropTypes.func,
}

export default withTranslation()(withStyles(styles)(XCModal))
