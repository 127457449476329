import React from 'react'
import GetServiceChecks from './get-service-checks'
import DeleteServiceCheck from './delete-check'
import { ServiceCheckMapperDataToDomain } from './mapper'
import UpdateServiceCheck from '../edit/data/update'

export const GQLProviderServiceChecks = () => {
  const Get = (
    filterBy,
    user = null,
    accountId = null,
    teamId = null,
    type = null,
    onLoad,
    onError,
    forceFetch
  ) => {
    GetServiceChecks({
      justFirst: false,
      accountId: accountId,
      mapper: ServiceCheckMapperDataToDomain,
      onLoad: onLoad,
      onError: onError,
      forceFetch: forceFetch,
    })
  }
  const Delete = (check, onDone, onError) => {
    console.log('DELETE, check: ', JSON.parse(JSON.stringify(check)))
    return (
      <DeleteServiceCheck id={check.id} onError={onError} onDone={onDone} />
    )
  }
  const Toogle = (id, activate, onDone, onError) => {
    console.log('Toogle, check: ', id, activate)
    return (
      <UpdateServiceCheck
        id={id}
        data={{ active: activate }}
        onError={onError}
        onDone={(data) => {
          onDone(ServiceCheckMapperDataToDomain(data))
        }}
      />
    )
  }
  return {
    GetChecks: Get,
    DeleteCheck: Delete,
    ToogleCheck: Toogle,
  }
}
