import React from 'react'
import {
  buildForUpdate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { ServiceCheckMapperDomainToData } from '../../data/mapper'
import { FIELDS_CHECK } from '../../data/get-service-checks'

export const UpdateServiceCheck = (props) => {
  const { data, id } = props
  const changed = { ...data }
  const builderData = buildForUpdate(
    Entity.service,
    FIELDS_CHECK,
    { id: id },
    ServiceCheckMapperDomainToData(changed)
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onDone}
      onError={props.onError}
    />
  )
}

export default UpdateServiceCheck
