import { ExecuteGQLWithBuilder } from '../../../../../../utils/executeGraphQL'
import React from 'react'
import PropTypes from 'prop-types'

const VerifyUserAttribute = (props) => {
  const { attributeName, code, profileId } = props
  const gql = `
  mutation SendCode($input: verifyUserAttributeInput!, $where: UserWhereMutation!) {
    verifyUserAttribute(input: $input, where: $where) {
      users {
        accountId
        cognitoDetails {
          phone_number_verified
          phone_number
        }
      }
    }
  }
`
  const variables = {
    input: {
      attributeName: attributeName,
      code: code,
    },
    where: { id: profileId },
  }
  const mutationName = 'verifyUserAttribute'

  return (
    <ExecuteGQLWithBuilder
      builderData={{ gql, variables, mutationName }}
      onDone={props.onDone}
      onError={props.onError}
      mapper={props.mapper}
      justFirst={true}
      // fakeError={{
      //   code: 0,
      //   message: 'VerificationFailed: Bla bla verification message sent at...',
      // }}
      // fakeSuccess={{
      //   cognitoDetails: {
      //     phone_number: '+38163359877',
      //     phone_number_verified: true,
      //   },
      //   notifications: {},
      // }}
    />
  )
}
VerifyUserAttribute.propTypes = {
  attributeName: PropTypes.oneOf(['phone_number', 'email']).isRequired,
  code: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onDone: PropTypes.func,
}

export default VerifyUserAttribute
