import React from 'react'
import DeleteUser from './delete-user'
import GetUser from '../user/edit/data/get-user'
import { UserMapperDataToDomain } from '../user/edit/data/mapper'

export const GQLProviderUsers = () => {
  const Get = (accountId, id, onLoad, onError) => {
    console.log('Get users: accountId: ', accountId)
    GetUser({
      justFirst: false,
      id: id,
      accountId: accountId,
      onLoad: onLoad,
      mapper: UserMapperDataToDomain,
      onError: onError,
    })
  }
  const Delete = (id, onDone, onError) => {
    return <DeleteUser id={id} onError={onError} onDone={onDone} />
  }

  return {
    GetUsers: Get,
    DeleteUser: Delete,
  }
}
