import React from 'react'
import PropTypes from 'prop-types'
import GetInit from '../../../welcome/data/get-init'

export const GQLProviderInit = () => {
  const get = (sessionUserId, onLoad, onError) => {
    return (
      <GetInit
        sessionUserId={sessionUserId}
        onDone={onLoad}
        onError={onError}
      />
    )
  }

  get.propTypes = {
    sessionUserId: PropTypes.string.isRequired,
    onLoad: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  }

  return {
    GetInitData: get,
  }
}
