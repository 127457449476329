import {
  isValid,
  validEmail,
  validName,
  validPhone,
} from '../../../../../utils/validations'
import { Limits } from '../../../../../utils/limits'

export const ProfileValidator = (data, original = {}, requiredField = []) => {
  console.log('ProfileValidator', data)
  const errors = {}
  isValid(data, 'given_name', validName, 'errors.bad_name', errors)
  isValid(data, 'family_name', validName, 'errors.bad_name', errors)
  isValid(data, 'email', validEmail, 'errors.bad_email', errors)
  if (data.given_name) {
    if (data.given_name.length > Limits.user.name.given.max) {
      errors.given_name = 'errors.name_max_length'
    } else if (data.given_name.length < Limits.user.name.given.min) {
      errors.given_name = 'errors.name_min_length'
    }
  }
  if (data.family_name) {
    if (data.family_name.length > Limits.user.name.family.max) {
      errors.family_name = 'errors.name_max_length'
    } else if (data.family_name.length < Limits.user.name.family.min) {
      errors.family_name = 'errors.name_min_length'
    }
  }
  if (data.mobile) {
    isValid(data, 'mobile', validPhone, 'errors.bad_phone', errors)
  }
  requiredField.forEach((field) => {
    if (typeof data[`${field}`] === 'undefined') {
      errors[`${field}`] = 'errors.required'
    }
  })
  return errors
}
