import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedUsers,
  actionNoUsers,
  StateInitial,
} from './states-actions'
import { reducer } from '../../components/reducer'
import {
  actionDelete,
  actionDeleted,
  actionLoadFailed,
} from '../../utils/states-actions'

export const useUsers = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { accountId } = data

  const Load = (accountId) => {
    console.log('::LoadUsers accountId:', accountId)
    const onError = (err) => {
      console.log('::LoadUsers llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(data.length > 0 ? actionLoadedUsers(data) : actionNoUsers())
    }
    provider.GetUsers(accountId, undefined, onLoad, onError)
  }

  const deleteUser = (id) => {
    const onError = (err) => {
      console.log('::deleteUsers llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onDone = (data) => {
      dispatch(actionDeleted('users', data))
    }
    return provider.DeleteUser(id, onDone, onError)
  }

  const doDelete = (key) => {
    dispatch(actionDelete(key))
  }

  return {
    state,
    accountId,
    load: Load,
    doDeleteUser: doDelete,
    deleteUser,
  }
}

export const UsersContext = createContext({})
export const useUsersContext = () => useContext(UsersContext)
export const UsersContextProvider = (props) => {
  const hookRef = useUsers(props.data, {
    ...props.provider,
    ...(props.providerUser || {}),
  })

  return (
    <UsersContext.Provider value={hookRef}>
      {props.children}
    </UsersContext.Provider>
  )
}
