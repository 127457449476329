import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useManageUserContext } from '../context'
import {
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_SAVING,
} from '../../../../utils/states-actions'
import ManageUserInfo from './manage-user-info'
import SavingUser from './saving-manage-user'
import ErrorCmp from '../../../../components/error'
import { useHistory } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Routes } from '../../../../routes'
import LoadingView from '../../../../components/loading-view'
import TopBar from '../../../../components/topbar'

const styles = (theme) => ({
  root: {
    maxWidth: 700,
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  button: {
    margin: 'auto',
  },
})

const ManageUserViews = (props) => {
  const hookRef = useManageUserContext()
  const history = useHistory()
  const { state } = hookRef
  const { classes, t } = props
  // console.log(state)

  const handleOnCreated = () => {
    history.replace(Routes.users)
  }
  const addingIds = Object.keys(state.addingToTeam || {})

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        hookRef.load(hookRef.id)
        return (
          <>
            <LoadingView />
          </>
        )
      case S_LOAD_DONE:
        return (
          <>
            {addingIds.length > 0 && hookRef.toggleUserTeam(addingIds.pop())}
            <ManageUserInfo {...hookRef} />
          </>
        )
      case S_SAVING:
        return (
          <>
            {hookRef.updateRole()}
            <SavingUser />
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar titleText={t('manage_user.title')} onBack={handleOnCreated} />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(ManageUserViews))
