import React from 'react'
import loading from './../assets/img/loading40px.svg'
import { useTranslation } from 'react-i18next'
import Title from './Title'

const Loading = (props) => {
  return (
    <div
      className={(props && props.className) || 'spinner'}
      data-testid={(props && props['data-testid']) || ''}
      onClick={props && props.onClick}
      hidden={props && props.hidden}
      style={{
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <img src={loading} alt="Loading" />
    </div>
  )
}

export const LoadingForState = (props) => {
  const { t } = useTranslation()
  return (
    <>
      <Loading />
      <Title className={'loading-profile'}>
        {t(props.message || 'please_wait')}
      </Title>
    </>
  )
}

export default Loading
