import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
  S_SAVING,
} from '../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    incidents: [],
    ackInProgress: [],
    ackInProgressIndexed: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedIncidents = (data) => {
  return {
    type: S_LOAD_DONE,
    incidents: data,
    ackInProgress: [],
    ackInProgressIndexed: {},
    errors: {},
  }
}
export const StateNoIncidents = {
  type: S_NO_DATA,
  incidents: [],
  ackInProgress: [],
  ackInProgressIndexed: {},
  errors: {},
}
export const StateSaving = {
  type: S_SAVING,
}
export const actionLoadedIncidents = (data) => {
  const incidents = [...data]
  return {
    reduce: () => {
      return StateLoadedIncidents(incidents)
    },
  }
}
export const actionNoIncidents = {
  reduce: (state) => {
    return { ...state, ...StateNoIncidents }
  },
}
export const actionCreateSub = (data) => {
  const ddd = data
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving,
        create: { ...ddd },
      }
    },
  }
}
export const actionAcknowledge = (id, serviceId, timestamp) => {
  return {
    reduce: (state) => {
      const ackInProgress = [...state.ackInProgress]
      ackInProgress.push({
        id: id,
        serviceId: serviceId,
        timestamp: timestamp,
      })
      const ackInProgressIndexed = { ...(state.ackInProgressIndexed ?? {}) }
      ackInProgressIndexed[id] = ackInProgress.length - 1
      return { ...state, ackInProgress, ackInProgressIndexed }
    },
  }
}
export const actionOnAcknowledged = (id, ackBy) => {
  const data = {
    id: id,
    incident: ackBy,
  }
  return {
    reduce: (state) => {
      console.log('actionOnAcknowledged', state, data)
      const result = { ...state }
      const idIndex = result.ackInProgressIndexed[data.id]
      // .map((inc) => {
      //   return inc.id
      // })
      // .indexOf(data.id)
      if (idIndex >= 0) {
        result.ackInProgress.splice(idIndex, 1)
        delete result.ackInProgressIndexed[data.id]
      }
      result.incidents = (result.incidents ?? []).map((existing) => {
        if (existing.id !== data.id) {
          console.log('actionOnAcknowledged existing', existing)
          return existing
        }
        console.log('actionOnAcknowledged existing >> ', data.incident)
        return data.incident
      })
      return result
    },
  }
}
