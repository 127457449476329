export const LatestChecksMapperDataToDomain = (src) => {
  return {
    id: src.id,
    accountId: src.accountId,
    returncode: src.returnCode,
    name: src.name,
    timestamp: (src.timestamp ?? 0) * 1000,
    loadtime: LoadTimeRound((src.perfData ?? {}).time),
    serviceId: src.serviceOf[0],
    info: {
      code: src.info.code ?? 0,
      message: src.info.message ?? '',
    },
    src_geoip: {
      latitude: src.srcGeoIP.ll[0],
      longitude: src.srcGeoIP.ll[1],
      city_name: src.srcGeoIP.city,
      region_name: src.srcGeoIP.region,
      country_name: src.srcGeoIP.country,
    },
    dst_geoip: {
      latitude: src.dstGeoIP.ll[0],
      longitude: src.dstGeoIP.ll[1],
      city_name: src.dstGeoIP.city,
      region_name: src.dstGeoIP.region,
      country_name: src.dstGeoIP.country,
    },
  }
}

export const LoadTimeRound = (src) => {
  const result = parseFloat(src ?? '0s').toFixed(2)
  return `${result}s`
}
