import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { getI18n, withTranslation } from 'react-i18next'
import Title from '../../../../components/Title'
import Subtitle from '../../../../components/Subtitle'
import { ChangePlan } from './index'

const styles = (theme) => ({
  root: {
    maxWidth: 700,
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
    spacing: theme.spacing(2),
  },
  paperSelected: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderColor: '#0064F0',
  },
  spanHighlight: {
    color: 'secondary',
    fontWeight: 'bold',
  },
  fixedHeight: {
    height: '100%',
  },
  divFeaturesContaines: {
    paddingBottom: theme.spacing(6),
    height: '100%',
  },
  divFeatures: {
    color: theme.colors.primary,
    paddingBottom: theme.spacing(2),
  },
  planHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
  },
  planIcon: {
    minWidth: 'auto',
  },
  plan: {
    flex: '0 0 auto',
    flexDirection: 'row',
    padding: '8px',
  },
})

const EditSubscription = (props) => {
  const { plan, state, classes, t } = props
  console.log('::EditSubscriptionInfo state', state)
  const subscription = state.subscription
  const formatter = new Intl.DateTimeFormat(getI18n().language)
  const createdAt = formatter.format(Date.parse(subscription.startDate))
  const expiresAt = subscription.endDate
    ? formatter.format(Date.parse(subscription.endDate))
    : t('beta_info')

  return (
    <>
      <Title>{t('subscription_edit.active_since')}</Title>
      <Subtitle>{createdAt}</Subtitle>
      <br />
      <Title>{t('subscription_edit.expires')}</Title>
      <Subtitle>{expiresAt}</Subtitle>
      <ChangePlan plan={plan} t={t} classes={classes} />
    </>
  )
}
export default withTranslation()(withStyles(styles)(EditSubscription))
