export const AnalyticsMapperDataToDomain = (src) => {
  const getActive = (values) => {
    return values
      .map((itm) => {
        return {
          title: itm[0],
          value: itm[1] ?? 0,
        }
      })
      .filter((item) => {
        return item.value >= 0
      })
  }

  const checksPie = getActive([
    ['ok', src.checks_ok],
    ['warning', src.checks_warning],
    ['critical', src.checks_critical],
    ['unknown', src.checks_unknown],
  ])
  const activePie = getActive([
    ['active', src.active_incidents],
    ['warning', src.active_incidents_warning],
    ['critical', src.active_incidents_critical],
    ['unknown', src.active_incidents_unknown],
  ])
  const totalIncidentsPie = getActive([
    ['warning', src.total_incidents_warning],
    ['critical', src.total_incidents_critical],
    ['unknown', src.total_incidents_unknown],
  ])
  let down = src.downtime ?? 0
  let up = src.uptime ?? 0
  const total = down + up
  down = ((down * 100) / total).toFixed(3)
  up = ((up * 100) / total).toFixed(3)
  if (!down || down === 'NaN') {
    down = 0
  }
  if (!up || up === 'NaN') {
    up = 0
  }

  return {
    accountId: src.accountId,
    totalChecks: src.checks,
    checks_pie_index: 0,
    checks_pie: checksPie,
    active_incidents_index: 0,
    active_incidents: activePie,
    totalActiveIncidents: src.active_incidents,
    downtime: `${down} %`,
    notifications: src.notifications ?? 0,
    services: src.services ?? 0,
    teams: src.teams ?? 0,
    incidents: totalIncidentsPie,
    totalIncidents: src.total_incidents,
    total_incidents_index: 0,
    uptime: `${up} %`,
    users: src.users ?? 0,
  }
}
