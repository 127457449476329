import React from 'react'
import {
  buildForUpdate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_TEAM } from '../../../data/get'

export const UpdateTeam = (props) => {
  const { data, id } = props
  const changed = { ...data }
  const builderData = buildForUpdate(
    Entity.team,
    FIELDS_TEAM,
    { id: id },
    changed
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onLoad}
      onError={props.onError}
      cacheModifyBlock={(cache, updated) => {
        console.log('u kesu', cache, updated)
        // updateCacheTeams(
        //   cache,
        //   { id: id, ...updated.teams[0] },
        //   CacheChangeType.update
        // )
      }}
    />
  )
}

export default UpdateTeam
