import React from 'react'
import GetAccount, { ACCOUNT_FIELDS_BASE } from './get-account'
import UpdateAccount from './update-account'
import { CacheModifyFor } from '../../../../../utils/executeGraphQL'
import { AccountMapperDataToDomain, AccountMapperDomainToData } from './mapper'

export const GQLProviderAccount = () => {
  const Get = (accountId, onLoad, onError) => {
    GetAccount({
      id: accountId,
      onLoad: onLoad,
      nError: onError,
      mapper: AccountMapperDataToDomain,
    })
  }
  const updateAccount = (accountId, data, onLoad, onError) => {
    const onDone = (data) => {
      onLoad(AccountMapperDataToDomain(data))
    }
    return (
      <UpdateAccount
        accountId={accountId}
        data={AccountMapperDomainToData(data)}
        onError={onError}
        onLoad={onDone}
      />
    )
  }

  return {
    GetAccount: Get,
    UpdateAccount: updateAccount,
  }
}

/**
 *
 * @param cache object
 * @param updated object
 * @param cacheChangeType CacheChangeType
 * @returns {*[]}
 */
export const updateCache = (cache, updated, cacheChangeType) => {
  cache.modify({
    fields: {
      accounts(existing = []) {
        console.log(
          'updateCache account: existing: ',
          JSON.stringify(existing),
          JSON.stringify(updated)
        )
        return CacheModifyFor(
          cacheChangeType,
          existing,
          updated,
          ACCOUNT_FIELDS_BASE
        )
      },
    },
  })
}
