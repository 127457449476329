import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { eventToText } from '../../../../utils/eventsExt'
import { useHistory } from 'react-router'
import { Delete, Pause, PlayArrow } from '@material-ui/icons'
import Loading from '../../../../components/Loading'
import { Routes } from '../../../../routes'
import { S_DELETE } from '../../../../utils/states-actions'
import { GetCheckTypeName } from '../service-utils'
import Subtitle from '../../../../components/Subtitle'
import { useAppContext } from '../../../../context/app-context'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
    padding: theme.spacing(3),
  },
  button: {
    textTransform: 'unset !important',
    marginLeft: theme.spacing(1),
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  actionHolder: {
    textAlign: 'center',
    padding: 0,
  },
  action: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
})

const ServiceChecksList = (props) => {
  const { state, t, classes, doDeleteServiceCheck, doToggleServiceCheck } =
    props
  const history = useHistory()
  const { getTeam } = useAppContext()
  const [filterState, setFilterState] = useState({ nameFilter: '' })
  const handleOpen = (id) => {
    history.push(Routes.serviceEditRoute(id))
  }
  const handleOpenTeam = (id) => {
    history.push(Routes.team(id))
  }
  const handleFilterChange = (event) => {
    const newFilter = { ...filterState }
    newFilter[`${event.target.id}`] = eventToText(event)
    setFilterState(newFilter)
  }
  const hasOrgs = state.serviceChecks.length > 0
  const ServiceRow = (props) => {
    const item = props.item
    const openAction = () => handleOpen(item.id)
    const deleteAction = () => doDeleteServiceCheck(item.id)
    const toggleAction = () => doToggleServiceCheck(item.id, !item.active)
    const isInProgress = state.forDeletion === item.id
    return (
      <TableRow key={item.id} className={classes.row}>
        <TableCell onClick={openAction}>
          {GetCheckTypeName(item.type)}
        </TableCell>
        <TableCell onClick={openAction}>{item.name}</TableCell>
        <TableCell onClick={openAction}>
          {item.ip ?? item.url ?? item.hostname}
        </TableCell>
        <TableCell onClick={openAction}>
          {[
            ...(item.teams ?? []),
            ...(item.teams ?? []),
            ...(item.teams ?? []),
            ...(item.teams ?? []),
            ...(item.teams ?? []),
            ...(item.teams ?? []),
          ].map((team) => {
            const openTeam = (ev) => {
              ev.stopPropagation()
              handleOpenTeam(team.id)
            }
            return (
              <Button
                key={team.id}
                size={'small'}
                autoCapitalize={'lower'}
                data-testid={`${item.id}-team-${team.id}`}
                variant="text"
                color="primary"
                onClick={openTeam}
              >
                {(getTeam(team.id) ?? { name: team.id }).name}
              </Button>
            )
          })}
        </TableCell>
        <TableCell className={classes.actionHolder}>
          <IconButton
            data-testid={`delete-${item.id}`}
            disabled={isInProgress}
            size={'small'}
            className={classes.action}
            onClick={deleteAction}
          >
            <Delete color={'error'} />
          </IconButton>
          <IconButton
            data-testid={`toggle-${item.id}`}
            disabled={isInProgress}
            size={'small'}
            className={classes.action}
            onClick={toggleAction}
          >
            {item.active ? (
              <Pause color={'action'} />
            ) : (
              <PlayArrow color={'primary'} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
  const ServiceChecksTable = () => {
    if (!hasOrgs) {
      return null
    }
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell key="type">{t('service_checks.type')}</TableCell>
            <TableCell key="name">{t('service_checks.name')}</TableCell>
            <TableCell key="target">{t('service_checks.target')}</TableCell>
            <TableCell key="teams">{t('service_checks.teams')}</TableCell>
            <TableCell key="actions">
              {props.actionsTitle || t('service_checks.actions')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.serviceChecks.map((item) => (
            <ServiceRow key={item.id} item={item} />
          ))}
        </TableBody>
      </Table>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box
        className={classes.appBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={hasOrgs ? 'space-between' : 'center'}
        p={1}
      >
        {/* SearchBox is hidden via CSS, check styles on top */}
        {hasOrgs ? (
          <TextField
            id={'nameFilter'}
            size={'small'}
            label={t('service_checks.search')}
            className={classes.textField}
            value={filterState.nameFilter}
            onChange={handleFilterChange}
            variant="outlined"
            type="text"
          />
        ) : (
          <Subtitle>{t('service_checks.no_data')}</Subtitle>
        )}
      </Box>
      <ServiceChecksTable />
      {state.type === S_DELETE && (
        <Loading className={classes.loader} onClick={() => {}} />
      )}
    </div>
  )
}
export default withTranslation()(withStyles(styles)(ServiceChecksList))
