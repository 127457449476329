import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import Loading from '../../../../components/Loading'
import { Routes } from '../../../../routes'
import { S_SAVING } from '../../../../utils/states-actions'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    textAlign: 'right',
    paddingTop: '8px',
    paddingRight: '8px',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  table: {
    zIndex: 10,
  },
  loader: {
    zIndex: 11,
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
  },
})

const ManageTeamsList = (props) => {
  const { state, manageState, doToggleTeam, user, t, classes } = props
  const history = useHistory()
  const handleCreateOrg = () => {
    history.push(Routes.teamNew)
  }
  const hasTeams = state.teams.length > 0
  console.log('manageState: ', manageState)
  console.log('state: ', state)
  const TeamsTable = () => {
    if (!hasTeams) {
      return null
    }
    const addingTo = manageState.addingToTeam || {}
    const addingIds = Object.keys(addingTo)
    const isUpdating = addingIds.length > 0
    return (
      <>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell key="name">{t('teams.name')}</TableCell>
              <TableCell key="actions" className={classes.action}>
                {t('manage_user.is_member')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.teams.map((team) => {
              const addToTeam = () => doToggleTeam(team.id)
              const isMember =
                (user.teams || []).filter((memberOf) => {
                  return memberOf.id === team.id
                }).length > 0
              return (
                <TableRow
                  onClick={isUpdating ? null : addToTeam}
                  data-testid={`org-${team.id}`}
                  key={team.id}
                  className={classes.row}
                >
                  <TableCell data-testid={`pick-org-name-${team.id}`}>
                    {team.name}
                  </TableCell>
                  <TableCell className={classes.action}>
                    {isUpdating ? null : (
                      <Checkbox
                        size={'small'}
                        key={`check-${team.id}`}
                        checked={isMember}
                        disabled={true}
                        inputProps={{
                          'data-testid': `check-${team.id}`,
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Loading
          hidden={!isUpdating}
          className={classes.loader}
          onClick={() => {}}
          data-testid={`adding-${addingIds[0]}`}
        />
      </>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box className={classes.appBar}>
        <Button
          size={'small'}
          autoCapitalize={'lower'}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleCreateOrg}
        >
          {t('teams.add')}
        </Button>
      </Box>
      <TeamsTable />
      {state.type === S_SAVING && (
        <Loading className={classes.loader} onClick={() => {}} />
      )}
    </div>
  )
}
export default withTranslation()(withStyles(styles)(ManageTeamsList))
