import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedTeams,
  actionNoTeams,
  StateInitial,
} from './states-actions'
import { reducer } from '../../components/reducer'
import {
  actionLoadFailed,
  actionDelete,
  actionDeleted,
} from '../../utils/states-actions'

export const useTeams = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { accountId } = data

  const Load = (accountId) => {
    const onError = (err) => {
      console.log('::LoadTeams llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(data.length > 0 ? actionLoadedTeams(data) : actionNoTeams)
    }
    provider.GetTeams(undefined, accountId, onLoad, onError)
  }

  const deleteTeam = (key) => {
    const onError = (err) => {
      console.log('::deleteTeams llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onDone = (data) => {
      dispatch(actionDeleted('teams', data))
    }
    return provider.DeleteTeam(key, onDone, onError)
  }

  const doDelete = (id) => {
    dispatch(actionDelete(id))
  }

  return {
    state,
    accountId,
    load: Load,
    doDeleteTeam: doDelete,
    deleteTeam,
  }
}

export const TeamsContext = createContext({})
export const useTeamsContext = () => useContext(TeamsContext)
export const TeamsContextProvider = (props) => {
  const hookRef = useTeams(props.data, props.provider)

  return (
    <TeamsContext.Provider value={hookRef}>
      {props.children}
    </TeamsContext.Provider>
  )
}
