import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom'
import EditProfileView from './features/users/user/edit'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, CssBaseline } from '@material-ui/core'
import { useAppContext } from './context/app-context'
import AppBarAndDrawer, { DrawerWidth } from './components/appbar_drawer'
import AccountView from './features/accounts'
import TeamEditView from './features/teams/team/edit'
import { Routes } from './routes'
import ManageUserView from './features/users/manage'
import ServiceCheckView from './features/services/checks/edit'
import NotificationEditView from './features/notifications/notification/edit'
import DashboardView from './features/dashboard'
import SubscriptionView from './features/subscriptions/subscription'
import PlansView from './features/subscriptions'
import CheckResultsView from './features/overview/details'
import ServiceChecksView from './features/services/checks'
import tawkTo from './components/tawk-to'
import WelcomeView from './features/welcome'
import { Copyright } from './components/copyright'
import cognitoUtils from './cognito/lib/cognitoUtils'
import IncidentsView from './features/incidents'
import EventsView from './features/incidents/events'
import OverviewView from './features/overview'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    flexShrink: 0,
    height: '100vh',
    width: 'calc(100% - 60px)',
    marginLeft: '60px',
  },
  contentMob: {
    flexGrow: 1,
    flexShrink: 0,
    height: '100vh',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  appBarImage: {
    maxHeight: '50px',
    paddingRight: '20px',
  },
}))

const App = () => {
  const { appData, refetchInit } = useAppContext()
  const isSmallScreen = window.innerWidth / 3 <= DrawerWidth
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const logoutWithRedirect = () => {
    cognitoUtils.signOutCognitoSession()
  }
  useEffect(() => {
    // This is necessary to make App rerender when appContext.user info changes
    // like when you change users name to autoupdate it near avatar
    tawkTo('60befce74ae6dd0abe7d0a4a', '1f7l0nq2k')
    window.Tawk_API.onLoad = () => {
      console.log('window.Tawk_API.onLoad', appData.user.name)
      window.Tawk_API.setAttributes(
        {
          name: appData.user.name,
          email: appData.user.email,
          hash: appData.user.id,
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }, [appData])

  console.log('location', location)
  useEffect(() => {
    console.log('uso u change: pathname: ', location.pathname)
    refetchInit()
    const plan = appData.plan
    if (!plan) {
      return
    }
    const result = Routes.isSubscribed(location.pathname, plan)
    console.log('uso u change: result ', result)
    if (result === location.pathname) {
      return
    }
    history.push(result)
  }, [location.pathname, refetchInit])
  console.log('appData', appData)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarAndDrawer
        user={appData?.user || {}}
        plan={appData?.plan || { name: 'free' }}
        logout={logoutWithRedirect}
      />
      <main className={isSmallScreen ? classes.contentMob : classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route exact path={Routes.auth} component={WelcomeView} />
            <Route path={'/'}>
              <Switch>
                <Route
                  exact
                  path={Routes.services}
                  component={ServiceChecksView}
                />
                <Route
                  exact
                  path={Routes.serviceGroup()}
                  component={ServiceChecksView}
                />
                <Route
                  exact
                  path={Routes.serviceEditRoute()}
                  component={ServiceCheckView}
                />
                <Route
                  exact
                  path={Routes.checkResults()}
                  component={CheckResultsView}
                />
                <Route
                  exact
                  path={Routes.profile}
                  component={EditProfileView}
                />
                <Route exact path={Routes.account} component={AccountView} />
                <Route exact path={Routes.teams} component={AccountView} />
                <Route exact path={Routes.team()} component={TeamEditView} />
                <Route exact path={Routes.teamNew} component={TeamEditView} />
                <Route exact path={Routes.users} component={AccountView} />
                <Route
                  key={`user-route-${new Date().getTime()}`}
                  path={Routes.notificationsUser()}
                  sensitive={true}
                  strict={true}
                  component={NotificationEditView}
                />
                <Route
                  key={`account-route-${new Date().getTime()}`}
                  path={Routes.notificationsAccount()}
                  sensitive={true}
                  strict={true}
                  component={NotificationEditView}
                />
                <Route
                  key={`team-route-${new Date().getTime()}`}
                  path={Routes.notificationsTeam()}
                  sensitive={true}
                  strict={true}
                  component={NotificationEditView}
                />
                <Route
                  exact
                  path={Routes.userNew()}
                  component={EditProfileView}
                />
                <Route
                  exact
                  path={Routes.userManage()}
                  component={ManageUserView}
                />
                <Route
                  exact
                  path={Routes.subscription}
                  component={SubscriptionView}
                />
                <Route
                  exact
                  path={Routes.subscriptions}
                  component={PlansView}
                />
                <Route
                  exact
                  path={Routes.incidents}
                  component={IncidentsView}
                />
                <Route exact path={Routes.incident()} component={EventsView} />
                <Route exact path={Routes.overview} component={OverviewView} />
                <Route component={DashboardView} />
              </Switch>
            </Route>
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  )
}
export default withRouter(App)
