import React, { useReducer, createContext, useContext } from 'react'
import {
  StateInitial,
  actionCratedProfile,
  actionCreateProfileFailed,
  actionLoadedProfile,
  actionOnProfileChange,
  actionSaveProfile,
  actionValidationFailed,
} from './states-actions'
import { eventToText } from '../../../../utils/eventsExt'
import { useAppContext } from '../../../../context/app-context'
import { reducer } from '../../../../components/reducer'
import { actionLoadFailed, S_LOAD_DONE } from '../../../../utils/states-actions'
import { ProfileValidator } from './data/validations'

export const EPContext = createContext({})

export const useEditProfile = (data, provider) => {
  const initState = StateInitial()
  const { id } = data
  const accountId = data.accountId || ''
  if (id === 'new') {
    initState.type = S_LOAD_DONE
    initState.user = { id: id }
  }
  const [state, dispatch] = useReducer(reducer, initState)
  const { appData, setAppData } = useAppContext()

  const LoadProfile = (accountId, profileId) => {
    console.log('::LoadProfile pid:', accountId, profileId)
    const onError = (err) => {
      console.log('::LoadProfile llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('::LoadProfile onLoad:', data)
      dispatch(actionLoadedProfile(data))
    }
    provider.Get(accountId, profileId, onLoad, onError)
  }

  const UpdateProfile = () => {
    console.log('will updateProfile')
    const onError = (err) => {
      console.log('::UpdateProfile llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onLoad = (data) => {
      console.log('data', JSON.parse(JSON.stringify(data)))
      // localStorage.setItem(LOCAL_KEY_USER, JSON.stringify(data))
      const tmpData = { ...appData }
      tmpData.user = data
      dispatch(actionLoadedProfile(data))
      setAppData(tmpData)
    }

    return provider.Update(state, onLoad, onError)
  }

  const CreateUser = () => {
    console.log('will createUser')
    if (accountId === '') {
      dispatch(
        actionCreateProfileFailed({
          code: 0,
          message: 'Account id is not set',
        })
      )
      return null
    }
    const onError = (err) => {
      console.log('::CreateUser llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionCratedProfile(data))
    }
    const data = { ...state.profileEdit }
    delete data.id

    return provider.CreateUser(accountId, data, onDone, onError)
  }

  const Save = () => {
    console.log('id', id)
    return id === 'new' ? CreateUser() : UpdateProfile()
  }

  const hasChanges = () => {
    return Object.keys(state.profileEdit).length > 0
  }

  const doUpdateProfile = () => {
    const errors = ProfileValidator(
      state.profileEdit,
      state.user,
      id === 'new' ? ['email', 'given_name', 'family_name'] : []
    )
    console.log('errors', errors)
    if (Object.keys(errors).length > 0) {
      dispatch(actionValidationFailed(errors))
      return
    }
    dispatch(actionSaveProfile())
  }

  const onProfileFieldChange = (value) => {
    console.log('onProfileFieldChange', value)
    console.log('onProfileFieldChange type', value.target)
    const keyValPair = {}
    keyValPair[`${value.target.name || value.target.id}`] = eventToText(value)
    dispatch(actionOnProfileChange({ ...keyValPair }))
  }

  const getProfileField = (name) => {
    const value =
      typeof state.profileEdit[name] === 'undefined'
        ? state.user[name]
        : state.profileEdit[name]
    return {
      value: value || '',
      error: state.errors[name] || null,
    }
  }

  const getGivenName = () => {
    return {
      value: state.profileEdit.given_name || state.user.given_name || '',
      error: state.errors.given_name || null,
    }
  }

  return {
    id,
    accountId,
    state,
    dispatch,
    hasChanges,
    loadProfile: LoadProfile,
    updateProfile: UpdateProfile,
    createUser: CreateUser,
    save: Save,
    doUpdateProfile,
    onProfileFieldChange,
    getProfileField,
    getGivenName,
  }
}
export const useEditProfileContext = () => useContext(EPContext)

export const EPContextProvider = (props) => {
  const hookRef = useEditProfile(props.data, props.provider)

  return (
    <EPContext.Provider value={hookRef}>{props.children}</EPContext.Provider>
  )
}
