import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedServiceCheck,
  actionOnServiceCheckChange,
  actionSaveServiceCheck,
  StateInitial,
  actionCreatedServiceCheck,
  actionValidationFailed,
  actionToggleTeam,
  actionOnAddedToTeam,
  actionOnRemovedFromTeam,
  actionToggleAccount,
  actionRunServiceCheckTest,
  actionServiceCheckTestDone,
} from './states-actions'
import { eventToText } from '../../../../utils/eventsExt'
import { actionLoadFailed } from '../../../../utils/states-actions'
import { ServiceCheckNodeTypes } from './data/create'
import { NodeTypes } from '../../../../utils/FieldTypes'
import { CheckStatus, CheckType } from '../service-utils'
import { reducer } from '../../../../components/reducer'
import { ServiceCheckValidator } from './validations'

export const useServiceCheck = (data, provider) => {
  const { id, userId, accountId, groupId } = data
  const initState = StateInitial(id)
  const [state, dispatch] = useReducer(reducer, initState)

  const onFieldChange = (value) => {
    const keyValPair = {}
    const key = value.target.id || value.target.name
    let result = eventToText(value)
    switch (ServiceCheckNodeTypes[key]) {
      case NodeTypes.Int:
      case NodeTypes.IntReq:
        result = result * 1
        break
      case NodeTypes.Boolean:
        result = result || result === 'true'
        break
      default:
        break
    }
    keyValPair[`${key}`] = result
    dispatch(actionOnServiceCheckChange({ ...keyValPair }))
  }
  const onChooseType = (value) => {
    const keyValPair = { type: eventToText(value) * 1 }
    if (
      keyValPair.type === CheckType.SMTP &&
      getEditField('port').value === ''
    ) {
      // TODO: Ref this :/
      keyValPair.port = 25
    }
    dispatch(actionOnServiceCheckChange({ ...keyValPair }))
  }
  const onToggleField = (field, value) => {
    const keyValPair = {}
    keyValPair[`${field}`] = eventToText(value) === 'true'
    dispatch(actionOnServiceCheckChange({ ...keyValPair }))
  }
  const onChooseRate = (value) => {
    const rateWithUnit = eventToText(value)
    const rateWithUnitSep = rateWithUnit.split('-')
    const keyValPair = {
      checkRate: rateWithUnitSep[0] * 1,
      checkRateUnit: rateWithUnitSep[1] * 1,
      checkRateWithUnit: rateWithUnit,
    }
    dispatch(actionOnServiceCheckChange({ ...keyValPair }))
  }
  const doToggleTeam = (teamId) => {
    console.log('teamId', teamId)
    dispatch(actionToggleTeam(teamId, state.serviceCheck.teams))
  }
  const toggleServiceCheckTeam = (serviceCheckId, teamId) => {
    const shouldAddTo = state.addingToTeam[teamId]
    console.log('toggleServiceCheckTeam', teamId, 'shouldAddTo', shouldAddTo)
    const onError = (err) => {
      console.log('::toggleServiceCheckTeam llOnError:', err)
      // TODO: Handle Failed case
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(
        shouldAddTo
          ? actionOnAddedToTeam(data, teamId)
          : actionOnRemovedFromTeam(teamId)
      )
    }

    return shouldAddTo
      ? provider.AddToTeam(serviceCheckId, teamId, onDone, onError)
      : provider.RemoveFromTeam(serviceCheckId, teamId, onDone, onError)
  }
  const doToggleAccount = () => {
    const checked = state.serviceCheck.account && state.serviceCheck.account.id
    console.log(`doToggleAccount checked: ${checked}`)
    dispatch(actionToggleAccount(!checked, accountId))
  }
  const getEditField = (name) => {
    const value =
      typeof state.serviceCheckEdit[name] === 'undefined'
        ? state.serviceCheck[name]
        : state.serviceCheckEdit[name]
    return {
      value: value || '',
      error: state.errors[name] || null,
    }
  }

  const Load = (serviceId) => {
    const onError = (err) => {
      console.log('::LoadServiceCheck llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(actionLoadedServiceCheck(data))
    }
    provider.GetCheck(serviceId, accountId, onLoad, onError)
  }

  const Save = () => {
    return id === 'new' ? Create() : Update()
  }

  const Update = () => {
    const changed = prepareChanged(false, { ...state.serviceCheckEdit })
    const onError = (err) => {
      console.log('::UpdateServiceCheck llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionLoadedServiceCheck(data))
    }

    return provider.UpdateCheck(
      state.serviceCheck.id,
      accountId,
      changed,
      onDone,
      onError
    )
  }

  const Create = () => {
    const changed = prepareChanged(true, { ...state.serviceCheckEdit })
    const onError = (err) => {
      console.log('::createServiceCheck llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionCreatedServiceCheck(data))
    }
    // const serviceId = GetServiceId(plan, changed.type)
    return provider.CreateCheck(changed, onDone, onError)
  }

  const runTest = () => {
    const dataToTest = { ...state.serviceCheck, ...state.serviceCheckEdit }
    const onError = (err) => {
      console.log('::runTest llOnError:', err)
      dispatch(
        actionServiceCheckTestDone({
          returncode: CheckStatus.unknown,
          error: {
            code: 0,
            message: err.message,
          },
        })
      )
    }

    const onDone = (data) => {
      console.log('::runTest llOnSuccess:', data)
      dispatch(actionServiceCheckTestDone(data))
    }

    return provider.RunTest(dataToTest, onDone, onError)
  }

  const prepareChanged = (isNew, data) => {
    const tmpData = { ...data }
    if (isNew) {
      tmpData.createdAt = new Date()
      if (tmpData.type === CheckType.DNS) {
        tmpData.acceptCName = tmpData.acceptCName !== false
      }
      if (tmpData.type === CheckType.SMTP) {
        tmpData.port = tmpData.port || 25
      }
    }
    delete tmpData.checkRateWithUnit
    if (tmpData.headers) {
      try {
        tmpData.headers = JSON.stringify(JSON.parse(tmpData.headers))
      } catch (e) {
        console.error('Bad HEADERS', e)
        delete tmpData.headers
      }
    }
    if (tmpData.postData) {
      try {
        tmpData.postData = JSON.stringify(JSON.parse(tmpData.postData))
      } catch (e) {
        console.error('Bad postData', e)
        delete tmpData.postData
      }
    }
    if (tmpData.postFormData) {
      try {
        tmpData.postFormData = JSON.stringify(JSON.parse(tmpData.postFormData))
      } catch (e) {
        console.error('Bad postFormData', e)
        delete tmpData.postFormData
      }
    }
    return tmpData
  }

  const hasChanges = () => {
    return Object.keys(state.serviceCheckEdit).length > 0
  }

  const doSave = () => {
    console.log('CONTEXT: doSave: ', state.serviceCheckEdit)
    const errors = ServiceCheckValidator(
      state.serviceCheckEdit,
      state.serviceCheck
    )
    if (Object.keys(errors).length > 0) {
      dispatch(actionValidationFailed(errors))
      return
    }
    dispatch(actionSaveServiceCheck())
  }

  const doRunTest = () => {
    console.log('CONTEXT: doRunTest: ', state.serviceCheckEdit)
    const errors = ServiceCheckValidator(
      state.serviceCheckEdit,
      state.serviceCheck
    )
    if (Object.keys(errors).length > 0) {
      dispatch(actionValidationFailed(errors))
      return
    }
    dispatch(actionRunServiceCheckTest())
  }

  return {
    state,
    id,
    userId,
    groupId,
    load: Load,
    save: Save,
    runTest,
    hasChanges,
    doSave,
    doRunTest,
    doToggleTeam,
    toggleServiceCheckTeam,
    doToggleAccount,
    getEditField,
    onFieldChange,
    onChooseType,
    onChooseRate,
    onToggleField,
    prepareChanged,
  }
}

export const ServiceCheckContext = createContext({})
export const useServiceCheckContext = () => useContext(ServiceCheckContext)
export const ServiceCheckContextProvider = (props) => {
  const hookRef = useServiceCheck(props.data, props.provider)

  return (
    <ServiceCheckContext.Provider value={hookRef}>
      {props.children}
    </ServiceCheckContext.Provider>
  )
}
