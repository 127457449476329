import { getDiff } from '../../../../utils/diff'
import {
  S_LOAD_START,
  S_LOAD_DONE,
  S_CREATED,
  S_SAVING,
} from '../../../../utils/states-actions'
import { CheckRateUnit, CheckType } from '../service-utils'
import { Entity } from '../../../entities'
import { Limits } from '../../../../utils/limits'

export const S_READY_TO_TEST = 100
export const S_TESTING = 101
export const S_TESTING_DONE = 102

export const StateInitial = (id) => {
  const result = {
    type: S_LOAD_START,
    serviceCheck: {},
    serviceCheckEdit: {},
    message: 'Please wait',
    errors: {},
  }
  if (id !== 'new') {
    return result
  }
  result.type = S_LOAD_DONE
  result.serviceCheck = {
    id: id,
    notificationsInterval: Limits.notificationsInterval.default,
    checkRateWithUnit: `1-${CheckRateUnit.hours}`,
  }
  result.serviceCheckEdit = {
    type: CheckType.HTTP,
    checkRate: 1,
    checkRateUnit: CheckRateUnit.hours,
    notificationsInterval: Limits.notificationsInterval.default,
    ssl: false,
    followRedirects: true,
  }
  return result
}
export const StateLoadedServiceCheck = (data) => {
  return {
    type: S_LOAD_DONE,
    serviceCheck: data,
    serviceCheckEdit: {},
    errors: {},
  }
}
export const StateCreatedServiceCheck = (data) => {
  return {
    type: S_CREATED,
    serviceCheck: data,
    serviceCheckEdit: {},
    errors: {},
  }
}
export const StateSaving = () => {
  return {
    type: S_SAVING,
  }
}
export const StateTestingServiceCheck = () => {
  return {
    subtype: S_TESTING,
  }
}
export const StateTestingDoneServiceCheck = (data) => {
  return {
    subtype: S_TESTING_DONE,
    ...data,
  }
}

export const actionValidationFailed = (err) => {
  const errors = err
  return {
    reduce: (state) => {
      return {
        ...state,
        errors,
      }
    },
  }
}
export const actionSaveServiceCheck = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving(),
      }
    },
  }
}
export const actionRunServiceCheckTest = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateTestingServiceCheck(),
      }
    },
  }
}
export const actionServiceCheckTestDone = (data) => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateTestingDoneServiceCheck({
          result: data,
        }),
      }
    },
  }
}
export const actionLoadedServiceCheck = (data) => {
  if (data.checkRateUnit === CheckRateUnit.minutes) {
    if (data.checkRate > 30) {
      data.checkRateUnit = CheckRateUnit.hours
      data.checkRate = data.checkRate / 60
    } else if (data.checkRate < 1) {
      data.checkRateUnit = CheckRateUnit.seconds
      data.checkRate = data.checkRate * 60
    }
  }
  const serviceCheck = {
    ...data,
    checkRateWithUnit: `${data.checkRate}-${data.checkRateUnit}`,
  }
  return {
    reduce: () => {
      return StateLoadedServiceCheck({ ...serviceCheck })
    },
  }
}
export const actionCreatedServiceCheck = (data) => {
  const serviceCheck = { ...data }
  return {
    reduce: () => {
      return StateCreatedServiceCheck({ ...serviceCheck })
    },
  }
}
export const actionOnServiceCheckChange = (data) => {
  const serviceCheck = { ...data }
  return {
    reduce: (state) => {
      return getDiff(serviceCheck, state, Entity.serviceCheck)
    },
  }
}
export const actionToggleAccount = (addIt, accountId) => {
  console.log('actionToggleAccount addIt', addIt)
  const accId = accountId
  return {
    reduce: (state) => {
      const addingToAccount = { ...state.addingToAccount }
      addingToAccount[accId] = addIt
      return { ...state, addingToAccount }
    },
  }
}
export const actionOnAddedToAccount = (account) => {
  const acc = account
  return {
    reduce: (state) => {
      return updateAccount(state, acc, true)
    },
  }
}
export const actionOnRemovedFromAccount = (account) => {
  const acc = account
  return {
    reduce: (state) => {
      return updateAccount(state, acc, false)
    },
  }
}
export const actionToggleTeam = (teamId, teams) => {
  const orgId = teamId
  const isInTeam =
    Object.values(teams || []).findIndex((org) => {
      return org.id === orgId
    }) >= 0
  return {
    reduce: (state) => {
      const addingToTeam = { ...state.addingToTeam }
      addingToTeam[orgId] = !isInTeam
      return { ...state, addingToTeam }
    },
  }
}
export const actionOnAddedToTeam = (teams, teamId) => {
  const tms = teams
  const tId = teams
  return {
    reduce: (state) => {
      console.log('onAddedToTeam', tId, tms)
      return updateTeams(state, tms, true)
    },
  }
}
export const actionOnRemovedFromTeam = (teamId) => {
  const tId = teamId
  return {
    reduce: (state) => {
      console.log('onRemovedFromTeam', tId)
      return updateTeams(state, { id: tId }, false)
    },
  }
}
const updateTeams = (state, team, addTeam) => {
  const result = { ...state }
  if (result.addingToTeam[team.id] !== undefined) {
    delete result.addingToTeam[team.id]
  }
  const serviceCheck = { ...result.serviceCheck }
  serviceCheck.teams = (serviceCheck.teams || []).filter((existing) => {
    return existing.id !== team.id
  })
  result.serviceCheck = serviceCheck
  if (addTeam) {
    result.serviceCheck.teams.push(team)
  }
  console.log('updateTeams', JSON.parse(JSON.stringify(result)))
  return result
}

const updateAccount = (state, account, addToAccount) => {
  const result = { ...state }
  if (result.addingToAccount[account.id] !== undefined) {
    delete result.addingToAccount[account.id]
  }
  result.serviceCheck.account = {}
  if (addToAccount) {
    result.serviceCheck.account = account
  }
  return result
}
