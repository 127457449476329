import { Entity } from '../../../../entities'
import {
  buildForGet,
  ExecuteGQLAsync,
} from '../../../../../utils/executeGraphQL'
import { NotificationDays } from '../states-actions'

/**
 * Default schedule is 24/7 a.k.a. NTF_TYPE.all_day
 * @type {string}
 */
export const SCHEDULE_DEFAULT = 'default'

export const FIELDS_BASE_NTF_OPTS_USER = [
  'email_enabled',
  'email_schedule',
  'sms_enabled',
  'sms_schedule',
]
export const FIELDS_BASE_NTF_HOOKS = ['enabled', 'name', 'value']
export const FIELDS_BASE_NTF_SUB = {
  hooks: 'hooks {' + FIELDS_BASE_NTF_HOOKS.join('\n') + '}',
}
export const FIELDS_BASE_NTF = ['hooks', 'schedule', 'scheduleForce']
export const FIELDS_NTF_TEAM = FIELDS_BASE_NTF.map(
  (name) => FIELDS_BASE_NTF_SUB[name] || name
)

const FIELDS_BASE_TIME = NotificationDays.filter((item) => {
  return item !== 'global'
})
const FIELDS_BASE_TIME_DAY = ['start', 'end']
const FIELDS_BASE_TIME_SUB = FIELDS_BASE_TIME.map((name) => {
  return `${name} {\n${FIELDS_BASE_TIME_DAY.join('\n')}\n}`
})
const FIELDS_BASE_SCHEDULE = [
  'id',
  'createdAt',
  'updatedAt',
  'accountId',
  'name',
  'active',
  'description',
  'exceptions',
  'type',
  'timetableType',
  'timetable',
]
const FIELDS_BASE_SCHEDULE_TIME = {
  timetable: 'timetable {\n' + FIELDS_BASE_TIME_SUB.join('\n') + '\n}',
  exceptions: 'exceptions {\n' + FIELDS_BASE_TIME_DAY.join('\n') + '\n}',
}
export const FIELDS_SCHEDULE = FIELDS_BASE_SCHEDULE.map(
  (name) => FIELDS_BASE_SCHEDULE_TIME[name] || name
).join('\n')

export const GetNotificationSettings = (props) => {
  const { scheduleId, accountId, onLoad, onError } = props
  console.log('props', props)
  // const { forEntity, scheduleId, accountId, onLoad, onError } = props
  // const { onLoad } = props
  const { gql, mutationName, variables } = buildForGet(
    Entity.schedule,
    FIELDS_SCHEDULE,
    {
      accountId: accountId,
      id: scheduleId,
    }
  )
  ExecuteGQLAsync({
    justFirst: props.justFirst ?? true,
    actionName: mutationName,
    variables: variables,
    onDone: onLoad,
    onError: onError,
    mapper: props.mapper,
    query: gql,
  })
}

export default GetNotificationSettings
