import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Box, TextField } from '@material-ui/core'
import { eventToText } from '../../../../utils/eventsExt'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab'
import { EVENT_TYPE } from '../data/mapper'
import { ArrowDownward, Call, Chat, Email, Sms } from '@material-ui/icons'
import { DateFormatter } from '../../../../utils/date-utils'
import { Row } from 'reactstrap'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  timeline: {
    textAlign: 'end',
  },
  timelineOpposite: {
    textAlign: 'start',
  },
  timelineMiddle: {
    flex: 0,
  },
  eventIcon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
})

const EventIcon = (props) => {
  const getBy = (type) => {
    switch (type) {
      case EVENT_TYPE.slack:
        return <Chat {...props} />
      case EVENT_TYPE.email:
        return <Email {...props} />
      case EVENT_TYPE.voice:
        return <Call {...props} />
      case EVENT_TYPE.sms:
        return <Sms {...props} />
    }
  }

  return <>{getBy(props.type)}</>
}

const EventItem = (props) => {
  const { event, classes, isLast, index } = props
  console.log('index', index)
  const data = [
    <Row key={'dd-' + index}>{DateFormatter.format(event.timestamp)}</Row>,
    <>
      <Row>{event.event}</Row>
      <Row>
        {event.type === EVENT_TYPE.slack ? 'slack' : event.target.join(',')}
      </Row>
    </>,
  ]
  return (
    <TimelineItem>
      <TimelineContent className={classes.timeline}>{data[0]}</TimelineContent>
      <TimelineSeparator className={classes.timelineMiddle}>
        <TimelineConnector />
        <EventIcon type={event.type} className={classes.eventIcon} />
        {!isLast && (
          <>
            <TimelineConnector />
            <ArrowDownward />
          </>
        )}
      </TimelineSeparator>
      <TimelineOppositeContent
        className={classes.timelineOpposite}
        sx={{ py: '12px', px: 2 }}
      >
        {data[1]}
      </TimelineOppositeContent>
    </TimelineItem>
  )
}

const EventsList = (props) => {
  const { state, t, classes } = props
  const [filterState, setFilterState] = useState({ nameFilter: '' })
  const handleFilterChange = (event) => {
    const newFilter = { ...filterState }
    newFilter[`${event.target.id}`] = eventToText(event)
    setFilterState(newFilter)
  }
  const hasEvents = state.events.length > 0
  console.log('events state', state)
  const EventsTable = () => {
    if (!hasEvents) {
      return null
    }
    return (
      <>
        <Timeline position="alternate">
          {state.events.map((event, index) => (
            <EventItem
              key={event.id + '-' + event.timestamp}
              event={event}
              index={index}
              isLast={index === state.events.length - 1}
              classes={props.classes}
            />
          ))}
        </Timeline>
      </>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box
        className={classes.appBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={hasEvents ? 'space-between' : 'center'}
        p={1}
      >
        {/* SearchBox is hidden via CSS, check styles on top */}
        {hasEvents && (
          <TextField
            id={'nameFilter'}
            size={'small'}
            label={t('events.search')}
            className={classes.textField}
            value={filterState.nameFilter}
            onChange={handleFilterChange}
            variant="outlined"
            type="text"
          />
        )}
      </Box>
      <EventsTable />
    </div>
  )
}
export default withTranslation()(withStyles(styles)(EventsList))
