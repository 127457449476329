import { gql } from '@apollo/client'
import { ExecuteGQLAsync } from '../../../../utils/executeGraphQL'
import PropTypes from 'prop-types'
import { Filters } from '../states-actions'
import { Entities } from '../../../entities'

export const QUERY_SERVICE_CHECK_FIELDS = [
  'checkRate',
  'createdAt',
  'description',
  'id',
  'name',
  'type',
  'checkConfig',
  'accountId',
  'active',
  'downtime',
  'downtimeSchedule',
  'lastState',
  'notificationsInterval',
  'schedule',
  'serviceOf',
  'updatedAt',
]

export const QUERY_CHECK_CONFIG_FIELDS = [
  'acceptCName',
  'hostname',
  'authPass',
  'authUser',
  'beforeExpire',
  'cookieStorage',
  'dnsQuery',
  'followRedirects',
  'ip',
  'port',
  'quit',
  'expectedString',
  'smtpFQDN',
  'smtpFrom',
  'ssl',
  'timeout',
  'url',
  'userAgent',
]
export const FIELDS_BASE_CHECK_SUB = {
  checkConfig: 'checkConfig {' + QUERY_CHECK_CONFIG_FIELDS.join('\n') + '}',
}
export const FIELDS_CHECK = QUERY_SERVICE_CHECK_FIELDS.map(
  (name) => FIELDS_BASE_CHECK_SUB[name] || name
).join('\n')
export const QUERY_SERVICE_CHECKS = `
  query getServiceChecks($where: ServiceWhere!) {
    ${Entities.services}(where: $where) {
      items {
        ${FIELDS_CHECK}
      }
    }
  }
`
export const QUERY_SERVICE_CHECKS_TYPE = gql`
  query getChecks($user: ID, $accountId: ID, $teamId: ID, $type: Int) {
    ${Entities.serviceChecks}(where: {
      type: $type
      OR: [
        { user: { id: $user } }
        { account: { id: $accountId } }
        { teams: { id: $teamId } }
      ] 
    }) {
        ${QUERY_SERVICE_CHECK_FIELDS}
    }
  }
`
export const GetServiceChecks = (props) => {
  const { onLoad, onError } = props
  // const useType = props.type !== null
  // const vars = {
  //   user: filterBy === Filters.user ? props.user : null,
  //   accountId: filterBy === Filters.account ? props.accountId : null,
  //   teamId: filterBy === Filters.team ? props.teamId : null,
  // }
  // if (useType) {
  //   vars.type = props.type
  // }
  ExecuteGQLAsync({
    actionName: Entities.services,
    variables: { where: { accountId: props.accountId } },
    forceFetch: props.forceFetch,
    onDone: onLoad,
    onError: onError,
    mapper: props.mapper,
    query: QUERY_SERVICE_CHECKS,
  })
}
GetServiceChecks.propTypes = {
  filterBy: PropTypes.oneOf([Filters.user, Filters.account, Filters.team])
    .isRequired,
  user: PropTypes.string,
  accountId: PropTypes.string,
  teamId: PropTypes.string,
  type: PropTypes.number,
}
export const ModifyServiceCheckCache = (useType, cache, check, isDeleted) => {
  try {
    const user = check.user && check.user.id
    const variables = { user, accountId: null, teamId: null }
    if (useType) {
      variables.type = check.type
    }
    const query = useType ? QUERY_SERVICE_CHECKS_TYPE : QUERY_SERVICE_CHECKS
    const data = cache.readQuery({
      query,
      variables,
    })
    // console.log('variables', variables)
    // console.log('query', query)
    cache.evict({
      fieldName: Entities.serviceChecks,
      broadcast: false,
    })
    cache.writeQuery({
      query,
      variables,
      data: {
        serviceChecks: isDeleted
          ? [...data.serviceChecks].filter((value) => {
              return value.id !== check.id
            })
          : [...(data ? data.serviceChecks : []), check],
      },
      broadcast: false,
    })
  } catch (e) {
    // readQuery can throw exception if query is not cached yet
    console.error(e)
  }
}

export default GetServiceChecks
