import React from 'react'
import {
  buildForConnection,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_CHECK } from '../../data/get-service-checks'

export const AddTeamToCheck = (props) => {
  const team = { id: props.teamId }
  const builderData = buildForConnection(
    Entity.service,
    { id: props.serviceCheckId },
    Entity.team,
    team,
    FIELDS_CHECK,
    true
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={() => {
        props.onDone(team)
      }}
      onError={props.onError}
    />
  )
}

export default AddTeamToCheck
