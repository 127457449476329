import React from 'react'
import EditProfileViews from './views'
import { useAppContext } from '../../../../context/app-context'
import { EPContextProvider } from './context'
import { GraphQLProviderProfile } from './data/provider'

const EditProfileView = (props) => {
  const { appData } = useAppContext()
  const { account, user } = appData
  console.log('EditProfileView props', props, appData, user)
  const params = (props.match && props.match.params) || {}
  const accountId = params.account || account.id
  const userId = params.id
  console.log('EditProfileView params', params)

  if (!user && !accountId) {
    return null
  }
  const data = { id: userId || user.id, accountId }
  console.log('EditProfileView data', data)
  return (
    <EPContextProvider data={data} provider={GraphQLProviderProfile()}>
      <EditProfileViews />
    </EPContextProvider>
  )
}

export default EditProfileView
