import React, { createContext, useContext, useReducer } from 'react'
import {
  actionNoSession,
  actionOnSession,
  StateInitial,
} from './states-actions'
import { reducer } from '../../components/reducer'
import { actionLoadFailed } from '../../utils/states-actions'
import { useAppContext } from '../../context/app-context'
import { useHistory, useLocation } from 'react-router'
import { Routes } from '../../routes'

export const useWelcome = (data, provider, initState = StateInitial()) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const { storeInit } = useAppContext()
  const { accountId } = data
  const history = useHistory()
  const location = useLocation()

  const checkSession = (href) => {
    console.log('::checkSession::')
    const onError = (err) => {
      console.log('::checkSession llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onDone = (data) => {
      console.log('checkSession:Done', data)
      dispatch(data ? actionOnSession(data) : actionNoSession())
    }
    provider.CheckSession(href, onDone, onError)
    return <></>
  }
  const storeUser = (session) => {
    console.log('::storeUser: href:', session)
    const onError = (err) => {
      console.log('::storeUser llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('storeUser:Load', data)
      console.log('storeUser:pathname', location.pathname)
      storeInit(data)
      if (location.pathname.startsWith(Routes.auth)) {
        const plan = data.plan ?? {}
        history.push(Routes.isSubscribed(Routes.index, plan))
      }
    }

    console.log('StoreUser', session)
    const userId = session.idToken.payload['cognito:username']
    console.log('session userId', userId)
    provider.StoreResult(userId, onLoad, onError)
  }

  return {
    state,
    accountId,
    checkSession,
    storeUser,
  }
}

export const WelcomeContext = createContext({})
export const useWelcomeContext = () => useContext(WelcomeContext)
export const WelcomeContextProvider = (props) => {
  const hookRef = useWelcome(props.data, props.provider)

  return (
    <WelcomeContext.Provider value={hookRef}>
      {props.children}
    </WelcomeContext.Provider>
  )
}
