import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTeamContext } from '../context'
import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_SAVING,
} from '../../../../../utils/states-actions'
import EditTeamInfo from './edit-info'
import SavingTeam from './saving'
import ErrorCmp from '../../../../../components/error'
import { useHistory } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Routes } from '../../../../../routes'
import { S_WITH_NOTIFICATIONS } from '../states-actions'
import TopBar from '../../../../../components/topbar'
import LoadingView from '../../../../../components/loading-view'

const styles = (theme) => ({
  root: {
    maxWidth: 700,
    overflowX: 'auto',
    margin: 'auto',
    padding: theme.spacing(3),
  },
  button: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  navHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px',
  },
})

const TeamViews = (props) => {
  const hookRef = useTeamContext()
  const history = useHistory()
  const { state } = hookRef
  const { classes, t } = props
  console.log('TeamViews state', state)

  const handleOnCreated = () => {
    history.replace(Routes.teams)
  }

  // const goToNotifications = () => {
  //   history.replace(
  //     Routes.notificationsTeam(
  //       hookRef.id,
  //       hookRef.state.team.notification.schedule.id
  //     )
  //   )
  // }

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.load(hookRef.id)}
            <LoadingView />
          </>
        )
      case S_LOAD_DONE:
      case S_WITH_NOTIFICATIONS:
        return <EditTeamInfo {...hookRef} />
      case S_CREATED:
        return <></>
      case S_SAVING:
        return (
          <>
            {hookRef.save()}
            <SavingTeam />
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }
  const isNew = hookRef.getEditField('id').value === 'new'

  return (
    <>
      <TopBar
        titleText={t(`team_edit.title${isNew ? '_new' : ''}`)}
        onBack={handleOnCreated}
      />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(TeamViews))
