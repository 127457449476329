import React from 'react'
import { GQLProviderNotification } from './data/provider'
import { NotificationContextProvider } from './context'
import NotificationViews from './views'
import { SCHEDULE_DEFAULT } from './data/get'

const NotificationEditView = (props) => {
  const data = {
    onNewSchedule: props.onNewSchedule,
    forEntity: props.forEntity ?? props.match.path.split('/')[2],
    entityId: props.entityId ?? props.match.params.entityId,
    scheduleId:
      props.scheduleId ?? props.match.params.scheduleId ?? SCHEDULE_DEFAULT,
  }
  console.log('NotificationEditView data', data)
  return (
    <NotificationContextProvider
      data={data}
      provider={GQLProviderNotification()}
    >
      <NotificationViews />
    </NotificationContextProvider>
  )
}

export default NotificationEditView
