import { ExecuteGQLWithBuilder } from '../../../../../../utils/executeGraphQL'
import React from 'react'
import PropTypes from 'prop-types'

const RequestCode = (props) => {
  const { attributeName, profileId } = props
  const gql = `
  query SendCode($attributeName: attributeNameEnum!, $where: UserWhereMutation!) {
    sendVerificationCode(attributeName: $attributeName, where: $where) {
      CodeDeliveryDetails {
        AttributeName
        DeliveryMedium
        Destination
      }
    }
  }
`
  const variables = {
    attributeName: attributeName,
    where: { id: profileId },
  }
  const mutationName = 'sendVerificationCode'

  return (
    <ExecuteGQLWithBuilder
      builderData={{ gql, variables, mutationName }}
      onDone={props.onDone}
      onError={props.onError}
      justFirst={true}
    />
  )
}
RequestCode.propTypes = {
  attributeName: PropTypes.oneOf(['phone_number', 'email']).isRequired,
  profileId: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onDone: PropTypes.func,
}

export default RequestCode
