import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../utils/states-actions'

export const S_TOGGLE = 40

export const Filters = {
  user: 0,
  account: 1,
  team: 2,
}
export const StateInitial = (defaultTeam) => {
  return {
    type: S_LOAD_START,
    serviceChecks: [],
    filter: {
      by: Filters.user,
      team: defaultTeam,
      forceFetch: false,
    },
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoaded = (data) => {
  return {
    type: S_LOAD_DONE,
    serviceChecks: data,
    filter: {
      by: Filters.user,
      team: '',
      forceFetch: false,
    },
    errors: {},
  }
}
export const StateNoData = () => {
  return {
    type: S_NO_DATA,
    serviceChecks: [],
    errors: {},
  }
}
export const actionLoaded = (data) => {
  const serviceChecks = data
  return {
    reduce: (state) => {
      return {
        ...StateLoaded([...serviceChecks]),
        filter: { ...state.filter, forceFetch: false },
      }
    },
  }
}
export const actionNoData = () => {
  return {
    reduce: (state) => {
      return {
        ...StateNoData(),
        filter: { ...state.filter, forceFetch: false },
      }
    },
  }
}
export const actionFilterChange = (data) => {
  const filter = data
  console.log('actionFilterChange: ', JSON.parse(JSON.stringify(filter)))
  return {
    reduce: (state) => {
      return { ...state, type: S_LOAD_START, ...filter }
    },
  }
}
export const actionToggle = (id, activate) => {
  const data = `${id}`
  return {
    reduce: (state) => {
      return { ...state, type: S_TOGGLE, forToggle: data, activate: activate }
    },
  }
}
export const actionToggled = (data) => {
  const updatedData = { ...data }
  return {
    reduce: (state) => {
      console.log('reduce actionToggled id', updatedData.id)
      const result = { ...state, type: S_LOAD_DONE }
      console.log('reduce actionToggled result', result.serviceChecks)
      delete result.forDeletion
      const rrr = result.serviceChecks.map((val) => {
        return val.id === updatedData.id ? updatedData : val
      })
      console.log('reduce actionToggled result after', rrr)
      result.serviceChecks = rrr
      return result
    },
  }
}
