import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedCheckResults,
  actionNoCheckResults,
  StateInitial,
} from './states-actions'
import { reducer } from '../../../components/reducer'
import { actionLoadFailed } from '../../../utils/states-actions'

export const useCheckResults = (data, provider) => {
  const [state, dispatch] = useReducer(reducer, StateInitial())
  const { checkId, accountId } = data

  const Load = (accountId, checkId) => {
    const onError = (err) => {
      console.log('::LoadCheckResults llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      dispatch(
        data.length > 0 ? actionLoadedCheckResults(data) : actionNoCheckResults
      )
    }
    return provider.GetCheckResults(accountId, checkId, onLoad, onError)
  }

  return {
    state,
    checkId,
    accountId,
    load: Load,
  }
}

export const CheckResultsContext = createContext({})
export const useCheckResultsContext = () => useContext(CheckResultsContext)
export const CheckResultsContextProvider = (props) => {
  const hookRef = useCheckResults(props.data, props.provider)

  return (
    <CheckResultsContext.Provider value={hookRef}>
      {props.children}
    </CheckResultsContext.Provider>
  )
}
