import React from 'react'
import PropTypes from 'prop-types'
import {
  buildForCreate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { RemoveNull, ServiceCheckMapperDomainToData } from '../../data/mapper'
import { FIELDS_CHECK_RESULT } from '../../../../overview/data/get'

export const RunTest = (props) => {
  const builderData = buildForCreate(
    Entity.check,
    FIELDS_CHECK_RESULT,
    RemoveNull(
      ServiceCheckMapperDomainToData({
        ...props.dataToTest,
      })
    )
  )
  console.log('builderData', builderData)
  builderData.gql = builderData.gql
    .replace(/createChecks/g, 'testServices')
    .replace(/CheckCreateInput/g, 'ServiceCreateInput')
  builderData.mutationName = 'testServices'
  console.log('builderData.qql after', builderData.gql)
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onDone}
      onError={props.onError}
    />
  )
}
RunTest.propTypes = {
  dataToTest: PropTypes.object.isRequired,
  onDone: PropTypes.func,
  onError: PropTypes.func,
}

export default RunTest
