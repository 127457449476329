import React from 'react'
import {
  buildForCreate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import PropTypes from 'prop-types'
import { Entity } from '../../../../entities'
import { FIELDS_SCHEDULE } from './get'

export const JustCreateSchedule = (props) => {
  console.log(JSON.parse(JSON.stringify(props)))
  const builderData = buildForCreate(
    Entity.schedule,
    FIELDS_SCHEDULE,
    props.notification
  )
  console.log(builderData)
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onDone}
      onError={props.onError}
      cacheModifyBlock={(cache, added) => {
        console.log(cache, added)
      }}
    />
  )
}

export const CreateSchedule = (props) => {
  console.log('CreateNotification: ', props)
  return (
    <JustCreateSchedule
      notification={props.data}
      onDone={(data) => {
        console.log('onDone data', data)
        props.onDone(data)
      }}
      onError={props.onError}
    />
  )
}

export default CreateSchedule

CreateSchedule.propTypes = {
  data: PropTypes.object.isRequired,
}
