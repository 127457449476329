import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoaded,
  actionNoData,
  actionToggle,
  StateInitial,
  actionFilterChange,
  Filters,
  actionToggled,
} from './states-actions'
import { reducer } from '../../../components/reducer'
import {
  actionLoadFailed,
  actionDelete,
  actionDeleted,
} from '../../../utils/states-actions'
import { eventToText } from '../../../utils/eventsExt'

export const useServiceChecks = (data, provider) => {
  const { serviceGroupId, teams, account } = data
  const defaultTeam = (teams && teams[0] && teams[0].id) || 'nema'
  const [state, dispatch] = useReducer(reducer, StateInitial(defaultTeam))
  const Load = (
    filterBy,
    groupId,
    user = null,
    accountId = null,
    team = null,
    type = null,
    forceFetch = false
  ) => {
    const onError = (err) => {
      console.log('::LoadServiceChecks llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('onLoad')
      dispatch(data.length > 0 ? actionLoaded(data) : actionNoData())
    }
    provider.GetChecks(
      filterBy,
      user,
      accountId,
      team,
      type && type - 1 > 0 ? type - 1 : null,
      onLoad,
      onError,
      forceFetch
    )
  }
  const onFilterChange = (value) => {
    const keyValPair = {
      filter: {
        by: eventToText(value) * 1,
        team: defaultTeam,
        type: state.filter.type,
        forceFetch: false,
      },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const onTeamChange = (value) => {
    const keyValPair = {
      filter: {
        by: Filters.team,
        team: eventToText(value),
        type: state.filter.type,
        forceFetch: false,
      },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const onTypeChange = (value) => {
    const keyValPair = {
      filter: {
        by: state.filter.by,
        team: state.filter.team,
        type: eventToText(value),
        forceFetch: false,
      },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const onForceRefresh = () => {
    console.log('state.filter', JSON.parse(JSON.stringify(state.filter)))
    const keyValPair = {
      filter: { ...state.filter, forceFetch: true },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const deleteServiceCheck = (key) => {
    const onError = (err) => {
      console.log('::deleteServiceChecks llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onDone = (data) => {
      dispatch(actionDeleted('serviceChecks', data))
    }
    const check = state.serviceChecks.find((itm) => {
      return itm.id === key
    })
    return provider.DeleteCheck(check, onDone, onError)
  }
  const toggleServiceCheck = (id, activate) => {
    const onError = (err) => {
      console.log('::toggleServiceCheck llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onDone = (data) => {
      dispatch(actionToggled(data))
    }
    return provider.ToogleCheck(id, activate, onDone, onError)
  }

  const doDelete = (key) => {
    dispatch(actionDelete(key))
  }

  const doToggle = (id, activate) => {
    dispatch(actionToggle(id, activate))
  }

  return {
    state,
    serviceGroupId,
    loadChecks: Load,
    teams,
    account,
    onFilterChange,
    onTeamChange,
    onTypeChange,
    onForceRefresh,
    doDeleteServiceCheck: doDelete,
    doToggleServiceCheck: doToggle,
    deleteServiceCheck,
    toggleServiceCheck,
  }
}

export const ServiceChecksContext = createContext({})
export const useServiceChecksContext = () => useContext(ServiceChecksContext)
export const ServiceChecksContextProvider = (props) => {
  const hookRef = useServiceChecks(props.data, props.provider)

  return (
    <ServiceChecksContext.Provider value={hookRef}>
      {props.children}
    </ServiceChecksContext.Provider>
  )
}
