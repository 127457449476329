import React from 'react'
import { buildForGet, ExecuteGQL } from '../../../../utils/executeGraphQL'
import { Entity } from '../../../entities'
import { EventMapperDataToDomain } from './mapper'

export const FIELDS_BASE_EVENTS = [
  'eventId',
  'eventType',
  'target',
  'timestamp',
  'type',
]
export const FIELDS_BASE_EVENTS_SUB = {}
export const FIELDS_EVENTS = FIELDS_BASE_EVENTS.map(
  (name) => FIELDS_BASE_EVENTS_SUB[name] || name
).join('\n')

const GetEvents = (props) => {
  const { accountId, serviceId, timestamp, onLoad, onError } = props
  // mutationName will be renamed to queryName later
  const { gql, mutationName, variables } = buildForGet(
    Entity.event,
    FIELDS_EVENTS,
    {
      accountId: accountId,
      serviceId: serviceId,
      timestamp: timestamp * 1,
    }
  )
  return (
    <ExecuteGQL
      justFirst={false}
      actionName={mutationName}
      variables={variables}
      mapper={EventMapperDataToDomain}
      onDone={onLoad}
      onError={onError}
      query={gql}
    />
  )
}

export default GetEvents
