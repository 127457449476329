import React from 'react'
import {
  buildForCreate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import PropTypes from 'prop-types'
import { NodeTypes } from '../../../../../utils/FieldTypes'
import { FIELDS_CHECK } from '../../data/get-service-checks'
import { Entity } from '../../../../entities'
import { ServiceCheckMapperDomainToData } from '../../data/mapper'

export const ServiceCheckNodeTypes = {
  name: NodeTypes.StringReq,
  type: NodeTypes.IntReq,
  port: NodeTypes.Int,
  code: NodeTypes.Int,
  checkRate: NodeTypes.Int,
  upTime: NodeTypes.Float,
  loadTime: NodeTypes.Float,
  checkRateUnit: NodeTypes.Int,
  beforeExpire: NodeTypes.Int,
  ssl: NodeTypes.Boolean,
  followRedirects: NodeTypes.Boolean,
  expectedString: NodeTypes.String,
  timeout: NodeTypes.Int,
  createdAt: NodeTypes.Date,
  stat: NodeTypes.Int,
}

export const CreateServiceCheck = (props) => {
  const builderData = buildForCreate(
    Entity.service,
    FIELDS_CHECK,
    ServiceCheckMapperDomainToData({
      ...props.data,
    })
  )
  return (
    <ExecuteGQLWithBuilder
      builderData={builderData}
      onDone={props.onDone}
      onError={props.onError}
    />
  )
}

export default CreateServiceCheck

CreateServiceCheck.propTypes = {
  data: PropTypes.object.isRequired,
}
