import React from 'react'
import Title from '../../../../components/Title'
import Loading from '../../../../components/Loading'
import { withTranslation } from 'react-i18next'

const SavingUser = (props) => {
  const { t } = props

  return (
    <>
      <Loading />
      <Title>{t('saving')}</Title>
    </>
  )
}
export default withTranslation()(SavingUser)
