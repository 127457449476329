import {
  validEmail,
  validScheme,
  validIP,
  validJSON,
  validName,
  validUrlPart,
  isValid,
} from '../../../../utils/validations'
import { CheckType } from '../service-utils'
import { Limits } from '../../../../utils/limits'

export const ServiceCheckValidator = (data, original = {}) => {
  const errors = {}
  isValid(data, 'name', validName, 'errors.bad_name', errors)
  isValid(data, 'ip', validIP, 'errors.bad_ip', errors)
  isValid(data, 'dnsIp', validIP, 'errors.bad_ip', errors)
  isValid(data, 'hostname', validScheme, 'errors.bad_hostname', errors, true)
  if (data.url) {
    const orig = data.url
    data.url = `thextracode.com${orig}`
    isValid(data, 'url', validUrlPart, 'errors.bad_url', errors)
    data.url = orig
  }
  isValid(data, 'headers', validJSON, 'errors.bad_json', errors)
  isValid(data, 'smtpFrom', validEmail, 'errors.no_smtpFrom', errors)
  isValid(data, 'postData', validJSON, 'errors.bad_json', errors)
  isValid(data, 'postFormData', validJSON, 'errors.bad_json', errors)
  const newCheckValue = { ...original, ...data }
  if (newCheckValue.notificationsInterval) {
    const intv = newCheckValue.notificationsInterval * 1
    if (intv < Limits.notificationsInterval.min) {
      errors.notificationsInterval = 'errors.too_low'
    } else if (intv > Limits.notificationsInterval.max) {
      errors.notificationsInterval = 'errors.too_high'
    }
  }
  if (!newCheckValue.hostname && !newCheckValue.ip) {
    errors.hostname = 'errors.no_address'
  }
  if (newCheckValue.type === CheckType.TCP && !newCheckValue.port) {
    errors.port = 'errors.no_port'
  }
  if (newCheckValue.type === CheckType.SMTP) {
    if (!newCheckValue.port) {
      errors.port = 'errors.no_port'
    }
    // if (!newCheckValue['smtpFrom']) {
    //   errors['smtpFrom'] = 'errors.no_smtpFrom'
    // }
    // if (!newCheckValue['smtpFQDN']) {
    //   errors['smtpFQDN'] = 'errors.no_smtpFQDN'
    // }
  }
  if (newCheckValue.type === CheckType.CERT) {
    const days = newCheckValue.beforeExpire
    if (days > Limits.certExpireMax) {
      errors.beforeExpire = 'errors.cert_max_days'
    }
    if (days < Limits.certExpireMin) {
      errors.beforeExpire = 'errors.cert_min_days'
    }
  }
  return errors
}
