import React from 'react'
import { GQLProviderUsers } from './data/provider'
import { UsersContextProvider } from './context'
import UsersViews from './views'
import { useAppContext } from '../../context/app-context'

const UsersView = (props) => {
  const { appData } = useAppContext()
  const data = { accountId: (appData.account || props.account).id }
  return (
    <UsersContextProvider data={data} provider={GQLProviderUsers()}>
      <UsersViews />
    </UsersContextProvider>
  )
}

export default UsersView
