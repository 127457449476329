import { SCHEDULE_DEFAULT } from '../../../../notifications/notification/edit/data/get'

export const HookTypes = {
  slackWebhook: 'slackWebhook',
  voiceOnCall: 'voiceOnCall',
}
export const TeamMapperDataToDomain = (src) => {
  const ntfs = src.notifications
  return {
    id: src.id,
    accountId: src.accountId,
    name: src.name,
    description: src.description,
    members: src.members ?? [],
    active: src.active,
    createdAt: src.createdAt,
    ...HooksDataToDomain(ntfs),
    notifications: {
      schedule: {
        id: ntfs && ntfs.schedule ? ntfs.schedule : SCHEDULE_DEFAULT,
        force: ntfs && ntfs.scheduleForce ? ntfs.scheduleForce : true,
      },
    },
  }
}
export const TeamMapperDomainToData = (src) => {
  console.log('TeamMapperDomainToData src', src)
  const isNew = !src.id || src.id === 'new'
  if (isNew) {
    return {
      name: src.name,
      description: src.description,
    }
  }
  const sch = isNew
    ? { id: SCHEDULE_DEFAULT, force: false }
    : src.notifications.schedule

  const result = {
    name: src.name,
    description: src.description,
    active: src.active,
    notifications: {
      hooks: [
        {
          enabled: src.hook_slackWebhook_active,
          name: HookTypes.slackWebhook,
          value: src.slackWebhook_value,
        },
        {
          enabled: src.hook_voiceOnCall_active,
          name: HookTypes.voiceOnCall,
          value: src.voiceOnCall_value,
        },
      ],
      scheduleForce: sch.force,
      schedule: sch.id,
    },
  }
  if (isNew) {
    delete result.active
  }
  console.log('TeamMapperDomainToData result', result)
  return result
}
export const GetHookBy = (hooks, hookType) => {
  const hooksCount = !hooks ? 0 : hooks.length
  if (hooksCount === 0) {
    return {}
  }
  if (hooksCount === 1 && hooks[0].name === hookType) {
    return {}
  }
  const result = hooks.filter((hook) => {
    return hook.name === hookType
  })
  return result[0] ?? {}
}

export const HooksDataToDomain = (ntfs) => {
  const hooks = ntfs && ntfs.hooks ? ntfs.hooks ?? [] : []
  const ntf = ntfs ?? {}
  console.log('ntf', ntf)
  if (hooks.length === 0) {
    // return {
    //   hook_slackWebhook_active: true,
    //   slackWebhook_value:
    //     'https://hooks.slack.com/services/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',
    //   hook_voiceOnCall_active: true,
    //   voiceOnCall_value: '+38163359877',
    // }
    return {
      hook_slackWebhook_active: ntf.hook_slackWebhook_active ?? false,
      slackWebhook_value: ntf.slackWebhook_value ?? '',
      hook_voiceOnCall_active: ntf.hook_voiceOnCall_active ?? false,
      voiceOnCall_value: ntf.voiceOnCall_value ?? '',
    }
  }
  console.log('HooksDataToDomain:: hooks', hooks)
  const hookSlack = GetHookBy(hooks, HookTypes.slackWebhook)
  const hookVoice = GetHookBy(hooks, HookTypes.voiceOnCall)
  console.log('HooksDataToDomain:: hookSlack', hookSlack)
  console.log('HooksDataToDomain:: hookVoice', hookVoice)
  return {
    hook_slackWebhook_active: hookSlack.enabled ?? false,
    slackWebhook_value: hookSlack.value ?? '',
    hook_voiceOnCall_active: hookVoice.enabled ?? false,
    voiceOnCall_value: hookVoice.value ?? '',
  }
}
