import React from 'react'
import { GQLProviderTeam } from './data/di'
import { TeamContextProvider } from './context'
import TeamViews from './views'
import { useAppContext } from '../../../../context/app-context'
import { GQLProviderTeams } from '../../data/provider'

const TeamEditView = (props) => {
  const { appData } = useAppContext()

  const data = {
    id: props.match.params.id,
    accountId: appData.account.id,
  }
  return (
    <TeamContextProvider
      data={data}
      provider={GQLProviderTeams()}
      providerTeam={GQLProviderTeam()}
    >
      <TeamViews />
    </TeamContextProvider>
  )
}

export default TeamEditView
