import React from 'react'
import { GQLProviderAccount } from './data/provider'
import { useAppContext } from '../../../../context/app-context'
import { AccountContextProvider } from './context'
import AccountViews from './views'

const AccountEditView = (props) => {
  const { appData } = useAppContext()
  const { account } = appData

  if (!account && !props.id) {
    return null
  }
  const data = { accountId: props.id || account.id }
  return (
    <AccountContextProvider data={data} provider={GQLProviderAccount()}>
      <AccountViews />
    </AccountContextProvider>
  )
}

export default AccountEditView
