import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'
import appConfig from '../config/app-config.js'

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri
    .replace('https://', '')
    .replace('http://', '')
  console.log('createCognitoAuth', appConfig.clientId, appConfig)
  return new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri,
  })
}

// Creates a CognitoUser instance
const getCurrentUser = () => {
  const pool = createCognitoUserPool()
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () =>
  new CognitoUserPool({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
  })

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
  return `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`
}
// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const handleHref = (href) => {
  return typeof href === 'undefined'
    ? getCognitoSession()
    : parseCognitoWebResponse(href)
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href) => {
  return new Promise((resolve, reject) => {
    console.log('parseCognitoWebResponse: ')
    const auth = createCognitoAuth()
    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(new Error('Failure parsing Cognito web response: ' + err))
      },
    }
    auth.parseCognitoWebResponse(href)
  })
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCurrentUser()
    if (cognitoUser === null) {
      resolve(undefined)
      return
    }
    cognitoUser.getSession((err, result) => {
      if (err || !result) {
        reject(new Error('Failure getting Cognito session: ' + err))
        return
      }

      // Resolve the promise with the session credentials
      console.debug('Successfully got session: ' + JSON.stringify(result))
      const session = {
        credentials: {
          accessToken: result.accessToken.jwtToken,
          idToken: result.idToken.jwtToken,
          refreshToken: result.refreshToken.token,
        },
        user: {
          userName: result.idToken.payload['cognito:username'],
          email: result.idToken.payload.email,
        },
      }
      resolve(session)
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth()
  auth.signOut()
}

export default {
  handleHref,
  createCognitoAuth,
  createCognitoUser: getCurrentUser,
  createCognitoUserPool,
  getCognitoSession,
  getCurrentUser,
  getCognitoSignInUri,
  parseCognitoWebResponse,
  signOutCognitoSession,
}
