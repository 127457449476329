import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { AppProvider, useAppContext } from './context/app-context'
import { I18nextProvider } from 'react-i18next'
import i18n from './assets/translations/i18n'
import { ElasticProvider } from './context/elastic-context'
import config from './aws-exports'
import { Amplify } from 'aws-amplify'
import cognitoUtils from './cognito/lib/cognitoUtils'
import WelcomeRouterView from './features/welcome/welcome-router'

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
  cache: new InMemoryCache(),
})
const cfg = {
  ...config,
  Auth: cognitoUtils.createCognitoAuth(),
}
cfg.Auth.userPoolWebClientId = cfg.Auth.clientId
Amplify.configure(cfg)

const Main = () => {
  const { appData } = useAppContext()
  const hasSession = cognitoUtils.getCurrentUser() && appData && appData?.user
  useEffect(() => {
    console.log('appData changed: ', appData)
  }, [appData])
  return (
    <ElasticProvider>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <Router>{hasSession ? <App /> : <WelcomeRouterView />}</Router>
        </I18nextProvider>
      </ApolloProvider>
    </ElasticProvider>
  )
}

const theme = createTheme({
  mode: 'dark',
  palette: {
    primary: {
      main: '#0064F0',
      dark: '#003BBC',
      light: '#6791FF',
    },
    secondary: {
      main: '#E53935',
      dark: '#AB000D',
      light: '#FF6F60',
    },
  },
  overrides: {
    MuiButton: {
      text: {
        color: '#0064F0',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#0064F0',
      },
    },
    MuiListItem: {
      root: {
        color: '#0064F0',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#0064F0',
      },
    },
    MuiSvgIcon: {
      root: {
        color: '#0064F0',
      },
    },
    MuiPaper: {
      root: {
        color: '#0064F0',
      },
    },
  },
})

const paymentScript = document.createElement('script')
paymentScript.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js')
paymentScript.setAttribute('async', '')
document.body.append(paymentScript)

const ThemePart = () => {
  const { colorMode } = useAppContext()
  const themeWithMode = {
    ...theme,
    mode: colorMode,
    colors: {},
  }
  for (const col of Object.keys(themeWithMode.palette)) {
    themeWithMode.colors[col] = themeWithMode.palette[col][colorMode]
  }
  return (
    <MuiThemeProvider theme={themeWithMode}>
      <Main />
    </MuiThemeProvider>
  )
}

ReactDOM.render(
  <AppProvider>
    <ThemePart />
  </AppProvider>,
  document.getElementById('root')
)
registerServiceWorker()
