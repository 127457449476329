import React from 'react'
import {
  buildForCreate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { FIELDS_TEAM } from '../../../data/get'
import { Entity } from '../../../../entities'

export const CreateTeam = (props) => {
  return (
    <ExecuteGQLWithBuilder
      builderData={buildForCreate(Entity.team, FIELDS_TEAM, { ...props.data })}
      onDone={props.onDone}
      onError={props.onError}
      cacheModifyBlock={(cache, added) => {
        console.log('u kesu', cache, added)
        // updateCacheUsers(cache, added.users[0], CacheChangeType.add)
      }}
    />
  )
}

export default CreateTeam
