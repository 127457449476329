import React from 'react'
import { GetCheckResults } from './get'

export const GQLProviderOverview = () => {
  const Get = (
    filterBy,
    user = null,
    accountId = null,
    teamId = null,
    type = null,
    onLoad,
    onError,
    forceFetch
  ) => {
    console.log('GET DI', filterBy, user, accountId, teamId, type, forceFetch)
    return (
      <GetCheckResults
        filterBy={filterBy}
        user={user}
        accountId={accountId}
        teamId={teamId}
        type={type}
        onLoad={onLoad}
        onError={onError}
        forceFetch={forceFetch}
      />
    )
  }

  return {
    GetCheckResults: Get,
  }
}
