import { ExecuteGQLAsync } from '../../../../../utils/executeGraphQL'
import { Entities } from '../../../../entities'
import { FIELDS_BASE_NTF_OPTS_USER } from '../../../../notifications/notification/edit/data/get'

export const FIELDS_BASE_COGNITO = [
  'email',
  'email_verified',
  'cognito__username',
  'given_name',
  'family_name',
  'nickname',
  'phone_number',
  'phone_number_verified',
  'zoneinfo',
  'custom__slackId',
]
export const FIELDS_BASE_USER = [
  'id',
  'createdAt',
  'updatedAt',
  'accountId',
  'active',
  'role',
  'memberOf',
  'cognitoDetails',
  'notifications',
]
export const FIELDS_BASE_USER_SUB = {
  cognitoDetails: 'cognitoDetails {' + FIELDS_BASE_COGNITO.join('\n') + '}',
  notifications: 'notifications {' + FIELDS_BASE_NTF_OPTS_USER.join('\n') + '}',
}
export const FIELDS_USER = FIELDS_BASE_USER.map(
  (name) => FIELDS_BASE_USER_SUB[name] || name
).join('\n')
const GET_USER = `
  query Users($where: UserWhere!) {
    ${Entities.users}(where: $where) {
      items {
        ${FIELDS_USER}
      }
    }
  }
`
export const GetUser = (props) => {
  const { id, accountId, onLoad, onError } = props
  console.log('GetUser: id', id, ', accountId: ', accountId)
  ExecuteGQLAsync({
    justFirst: props.justFirst ?? true,
    actionName: Entities.users,
    variables: { where: { id: id, accountId: accountId } },
    onDone: onLoad,
    onError: onError,
    mapper: props.mapper,
    query: GET_USER,
  })
}

export default GetUser
