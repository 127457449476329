import React, { createContext, useContext, useState } from 'react'
import { UserRoles } from '../features/users/manage/roles'
import { PlanType } from '../components/current-plan'
import WelcomeProvider, {
  LOCAL_KEY_INIT,
  LOCAL_KEY_THEME,
} from '../features/welcome/data/provider'

const AppContext = createContext({})
export const useAppContext = () => useContext(AppContext)
export const AppProvider = ({ children }) => {
  /**
   * appData has: {account, plan, teams, user}
   */
  let initData = localStorage.getItem(LOCAL_KEY_INIT)
  let lastCheck = new Date().getTime()
  const currentTheme = localStorage.getItem(LOCAL_KEY_THEME) ?? 'light'
  const [colorMode, setColorMode] = useState(currentTheme)
  const [appData, setAppData] = useState(initData && JSON.parse(initData))
  const getTeams = () => {
    const { user, teams } = appData
    return (user.role !== UserRoles.viewer ? teams : user.teams) || []
  }
  const getTeam = (id) => {
    console.log('getteam', id)
    const result = getTeams().filter((team) => {
      return team.id === id
    })
    return result.length === 0 ? null : result[0]
  }
  const storeInit = (data) => {
    localStorage.setItem(LOCAL_KEY_INIT, JSON.stringify(data))
    setAppData(data)
  }
  const refetchInit = () => {
    const now = new Date().getTime()
    if (now - lastCheck < 30000) {
      return
    }
    lastCheck = now
    const provider = WelcomeProvider()
    const onDone = (data) => {
      console.log('refetchInit onDone: ', data)
      storeInit(data)
    }
    const onError = (err) => {
      console.log('refetchInit onError: ', err)
    }
    provider.StoreResult(appData.user.id, onDone, onError)
  }
  const updateUser = (data) => {
    console.log('appContext::updateUser, data', data)
    const currentInit = JSON.parse(initData)
    console.log('appContext::updateUser, currentInit', currentInit)
    currentInit.user = { ...currentInit.user, ...data }
    console.log('appContext::updateUser, afterInit', currentInit)
    initData = JSON.stringify(currentInit)
    localStorage.setItem(LOCAL_KEY_INIT, initData)
    setAppData(currentInit)
  }
  const areTeamsAllowed = () => {
    const { user, plan } = appData
    const teamsData = getTeams()
    return (
      plan.name !== PlanType.free &&
      (user.role !== UserRoles.viewer || (teamsData && teamsData.length > 1))
    )
  }
  console.log('Init app-context', refetchInit)
  return (
    <AppContext.Provider
      value={{
        appData,
        setAppData,
        storeInit,
        updateUser,
        getTeam,
        getTeams,
        areTeamsAllowed,
        colorMode,
        setColorMode,
        refetchInit,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
