import React from 'react'
import { ExecuteGQL } from '../../../utils/executeGraphQL'
import { Entities, Entity } from '../../entities'
import { FIELDS_USER } from '../../users/user/edit/data/get-user'
import { ACCOUNT_FIELDS } from '../../accounts/account/edit/data/get-account'
import { FIELDS_TEAM } from '../../teams/data/get'
import MapperInit from './mapper'
import PropTypes from 'prop-types'
// import { QUERY_PLAN_FIELDS } from '../../users/data/get-users'

export const FIELDS_BASE_INIT = [
  Entities.users,
  Entities.accounts,
  Entities.teams,
  // Entity.plan,
]
export const FIELDS_BASE_INIT_SUB = {
  users: `${Entities.users} {\n\t` + FIELDS_USER + '\n}',
  accounts: `${Entities.accounts} {\n\t` + ACCOUNT_FIELDS + '\n}',
  teams: `${Entities.teams} {\n\t` + FIELDS_TEAM + '\n}',
  // plan: `${Entity.plan} {\n\t` + QUERY_PLAN_FIELDS + '\n}',
}
export const FIELDS_INIT = FIELDS_BASE_INIT.map(
  (name) => FIELDS_BASE_INIT_SUB[name] || name
).join('\n')
export const GET_INIT = `
  query Init {
    init {
      ${FIELDS_INIT}
    }
  }
`
export const GetInit = (props) => {
  const { onDone, onError, sessionUserId } = props
  return (
    <ExecuteGQL
      justFirst={false}
      returnAll={true}
      actionName={Entity.init}
      variables={{}}
      mapper={(src) => MapperInit(src.init, sessionUserId)}
      onDone={onDone}
      onError={onError}
      query={GET_INIT}
    />
  )
}

GetInit.propTypes = {
  onDone: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  sessionUserId: PropTypes.string.isRequired,
}

export default GetInit
