import { Route, Switch } from 'react-router-dom'
import { Routes } from '../../routes'
import React from 'react'
import WelcomeView from './index'

const WelcomeRouterView = (props) => {
  console.log('welcome-router', props)
  return (
    <Switch>
      <Route path={Routes.auth} component={WelcomeView} />
      <Route component={WelcomeView} />
    </Switch>
  )
}

export default WelcomeRouterView
