export const emptyTo = (src, defValue) =>
  (src || '').length === 0 ? defValue : src
export const isBlank = (str) => !str || /^\s*$/.test(str)
export const Capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const ToCamelCase = (str) => {
  const tmpRes =
    str.substring(0, 1).toUpperCase() + str.substring(1, str.length)
  if (str.indexOf(/\s/g) < 0) {
    return tmpRes
  }
  return tmpRes
    .replace(/\s(.)/g, ($1) => {
      return $1.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => {
      return $1.toLowerCase()
    })
}
