import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoadedTeam,
  actionOnTeamChange,
  actionSaveTeam,
  StateInitial,
  actionCreatedTeam,
  actionShowNotifications,
} from './states-actions'
import { eventToText } from '../../../../utils/eventsExt'
import { actionLoadFailed, S_LOAD_DONE } from '../../../../utils/states-actions'
import { reducer } from '../../../../components/reducer'
import { HooksDataToDomain } from './data/mapper'

export const useTeam = (data, provider) => {
  const { id, accountId } = data
  const initState = StateInitial()
  if (id === 'new') {
    initState.type = S_LOAD_DONE
    initState.team = { id: id }
  }
  const [state, dispatch] = useReducer(reducer, initState)

  const onFieldChange = (value) => {
    const keyValPair = {}
    keyValPair[`${value.target.id}`] = eventToText(value)
    dispatch(actionOnTeamChange({ ...keyValPair }))
  }

  const getEditField = (name, defValue = '') => {
    const value =
      typeof state.teamEdit[name] === 'undefined'
        ? state.team[name]
        : state.teamEdit[name]
    return {
      value: value || defValue,
      error: state.errors[name] || null,
    }
  }

  const Load = (teamId) => {
    const onError = (err) => {
      console.log('::LoadTeam llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('onLoadTeam', data)
      dispatch(actionLoadedTeam(data[0]))
    }
    provider.GetTeams(teamId, accountId, onLoad, onError)
  }

  const Save = () => {
    console.log('CONTEXT: SaveTeam: ', state.teamEdit)
    return id === 'new' ? Create() : Update()
  }

  const Update = () => {
    console.log('CONTEXT: updateTeam: ', state.teamEdit)
    const onError = (err) => {
      console.log('::UpdateTeam llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onLoad = (data) => {
      dispatch(actionLoadedTeam(data))
    }

    return provider.UpdateTeam(
      state.team.id,
      {
        ...state.team,
        ...state.teamEdit,
      },
      onLoad,
      onError
    )
  }

  const Create = () => {
    console.log('CONTEXT: createTeam: ', state.teamEdit)
    const onError = (err) => {
      console.log('::createTeam llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (data) => {
      dispatch(actionCreatedTeam(data))
    }

    return provider.CreateTeam(state.teamEdit, onDone, onError)
  }

  const onNewSchedule = (scheduleId) => {
    console.log('CONTEXT: Team onNewSchedule: ', scheduleId)
    const ntf = state.team.notifications
    const ntfEdit = state.teamEdit.notifications ?? {}
    const hasChanges = ntfEdit !== {}
    let force = ntf.force ?? false
    if (hasChanges) {
      force = ntfEdit.force ?? force
    }
    dispatch(
      actionOnTeamChange({
        ...HooksDataToDomain(ntf),
        notifications: {
          force: force,
          schedule: scheduleId,
        },
      })
    )
    dispatch(actionSaveTeam())
  }

  const hasChanges = () => {
    return Object.keys(state.teamEdit).length > 0
  }

  const doSave = () => {
    console.log('CONTEXT: doSave: ', state.teamEdit)
    dispatch(actionSaveTeam())
  }

  const showNotifications = async () => {
    dispatch(actionShowNotifications())
  }

  return {
    state,
    id,
    accountId,
    load: Load,
    save: Save,
    hasChanges,
    doSave,
    showNotifications,
    getEditField,
    onFieldChange,
    onNewSchedule,
  }
}

export const TeamContext = createContext({})
export const useTeamContext = () => useContext(TeamContext)
export const TeamContextProvider = (props) => {
  const hookRef = useTeam(props.data, {
    ...props.provider,
    ...(props.providerTeam || {}),
  })

  return (
    <TeamContext.Provider value={hookRef}>
      {props.children}
    </TeamContext.Provider>
  )
}
