import React, { createContext, useContext, useReducer } from 'react'
import { actionLoaded, StateInitial } from './states-actions'
import { reducer } from '../../components/reducer'
import { actionLoadFailed } from '../../utils/states-actions'
import { eventToText } from '../../utils/eventsExt'
import { actionFilterChange, Filters } from '../services/checks/states-actions'

/**
 *
 * @param data: object
 * @param provider: GQLProviderOverview
 * @returns {{accountId: String, load: (function(): *|void), state: S}}
 */
export const useOverview = (data, provider) => {
  const { accountId, teams } = data
  const defaultTeam = (teams && teams[0] && teams[0].id) || 'nema'
  const [state, dispatch] = useReducer(reducer, StateInitial(defaultTeam))

  const Load = (
    filterBy,
    user = null,
    accountId = null,
    team = null,
    type = null,
    forceFetch = false,
    setPoll = null
  ) => {
    console.log('LOAD', filterBy, user, accountId, team, type, forceFetch)
    const onError = (err) => {
      console.log('::Load llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('onLOAD:', data)
      // if (setPoll) {
      //   state.checks = actionLoaded(data).reduce(state).checks
      //   setPoll(data)
      //   return
      // }
      // dispatch(actionPolling(data))
      dispatch(actionLoaded(data))
    }
    return provider.GetCheckResults(
      filterBy,
      user,
      accountId,
      team,
      type && type - 1 > 0 ? type - 1 : null,
      onLoad,
      onError,
      forceFetch
    )
  }
  const onFilterChange = (value) => {
    const keyValPair = {
      filter: {
        by: eventToText(value) * 1,
        team: defaultTeam,
        forceFetch: false,
        asMap: state.filter.asMap,
      },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const doToggleMap = () => {
    const keyValPair = {
      filter: { ...state.filter, asMap: !state.filter.asMap },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const onTeamChange = (value) => {
    const keyValPair = {
      filter: {
        by: Filters.team,
        team: eventToText(value),
        forceFetch: false,
        asMap: state.filter.asMap,
      },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const onTypeChange = (value) => {
    const keyValPair = {
      filter: {
        by: state.filter.by,
        team: state.filter.team,
        type: eventToText(value),
        forceFetch: false,
        asMap: state.filter.asMap,
      },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }
  const onForceRefresh = () => {
    console.log('state.filter', JSON.parse(JSON.stringify(state.filter)))
    const keyValPair = {
      filter: { ...state.filter, forceFetch: true },
    }
    dispatch(actionFilterChange({ ...keyValPair }))
  }

  return {
    state,
    accountId,
    loadOverview: Load,
    teams,
    onFilterChange,
    doToggleMap,
    onTeamChange,
    onTypeChange,
    onForceRefresh,
  }
}

export const OverviewContext = createContext({})
export const useOverviewContext = () => useContext(OverviewContext)
export const OverviewContextProvider = (props) => {
  const hookRef = useOverview(props.data, props.provider)

  return (
    <OverviewContext.Provider value={hookRef}>
      {props.children}
    </OverviewContext.Provider>
  )
}
