import React from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import {
  BatteryCharging20,
  BatteryCharging60,
  BatteryCharging80,
  BatteryChargingFull,
} from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

export const PlanType = {
  free: 'free',
  singleUser: 'singleUser',
  singleTeam: 'singleTeam',
  multipleTeams: 'multipleTeams',
}
const styles = (theme) => ({
  planName: {
    whiteSpace: 'break-spaces',
  },
})

const CurrentPlan = (props) => {
  const { planName, t, classes } = props
  const showPlanInfo =
    typeof props.withText === 'undefined' || props.withText === true
  return (
    <>
      <ListItemIcon className={props.planIconClassName}>
        {(planName === PlanType.free && <BatteryCharging20 />) ||
          (planName === PlanType.singleUser && <BatteryCharging60 />) ||
          (planName === PlanType.singleTeam && <BatteryCharging80 />) ||
          (true && <BatteryChargingFull />)}
      </ListItemIcon>
      {showPlanInfo && (
        <ListItemText
          className={props.planClassName || classes.planName}
          primary={t('menu_side.plan')}
          secondary={planName.toUpperCase()}
        />
      )}
    </>
  )
}

export default withTranslation()(withStyles(styles)(CurrentPlan))
