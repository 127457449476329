export const IncidentMapperDataToDomain = (src, mapEntities) => {
  return {
    id: src.id,
    accountId: src.accountId,
    serviceId: src.serviceId,
    name: src.name,
    timestamp: (src.timestamp ?? 0) * 1000,
    closedAt: (src.closedAt ?? 0) * 1000,
    duration: src.duration,
    code: src.code,
    acknowledged: src.acknowledged,
    acknowledgedBy: (src.acknowledgedBy ?? []).map((eve) =>
      IncidentAckMapperDataToDomain(eve, mapEntities)
    ),
    groupId: src.groupId,
    lastStateChange: (src.lastStateChange ?? 0) * 1000,
    type: src.type,
  }
}
export const IncidentMapperDomainToData = (src) => {
  // Map data from Domain (web-app) to data used in API
  return {
    name: src.name,
    active: src.active,
  }
}
export const IncidentAckMapperDataToDomain = (src, mapEntities) => {
  return {
    acknowledgedAt: Date.parse(src.acknowledgedAt),
    entity: src.entity,
    entityId: src.entityId,
    source: src.source,
    ...(mapEntities ? mapEntities(src) : {}),
  }
}

export const ACK_SOURCE = {
  web: 'web',
  sms: 'sms',
  email: 'email',
  voice: 'voice',
  slack: 'slack',
}
