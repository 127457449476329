import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
const styles = (theme) => ({
  button: {
    marginLeft: 'auto',
  },
})

const PieChartXc = (props) => {
  const { data } = props

  return (
    <ResponsiveContainer
      minWidth={props.width ?? '30%'}
      minHeight={props.height ?? '20%'}
    >
      <PieChart id={props.id}>
        <Pie
          activeIndex={props.activeIndex}
          activeShape={props.activeShape}
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          minAngle={10}
          fill="#8884d8"
          dataKey={props.dataKey ?? 'value'}
          onMouseEnter={data.length <= 1 ? undefined : props.onMouseEnter}
          label
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={props.fillColor(entry, index) ?? 'black'}
            />
          ))}
        </Pie>
        <Legend formatter={props.legendFormatter} />
      </PieChart>
    </ResponsiveContainer>
  )
}

PieChartXc.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  dataKey: PropTypes.string,
  fillColor: PropTypes.func,
  legendFormatter: PropTypes.func,
  activeIndex: PropTypes.number,
  activeShape: PropTypes.func,
  onMouseEnter: PropTypes.func,
}
export default withTranslation()(withStyles(styles)(PieChartXc))
