import React from 'react'

import { GQLProviderInit } from './data/di'
import { SubscriptionContextProvider } from './context'
import { useAppContext } from '../../../context/app-context'
import SubscriptionViews from './views'

const SubscriptionView = (props) => {
  const { appData } = useAppContext()
  const data = {
    plan: appData.plan || props.plan,
    account: appData.account || props.account,
    user: appData.user || props.user,
  }
  return (
    <SubscriptionContextProvider data={data} provider={GQLProviderInit()}>
      <SubscriptionViews />
    </SubscriptionContextProvider>
  )
}

export default SubscriptionView
