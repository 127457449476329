import React from 'react'
import {
  buildForCreate,
  ExecuteGQLWithBuilder,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_USER } from './get-user'

export const CreateUser = (props) => {
  return (
    <ExecuteGQLWithBuilder
      builderData={buildForCreate(Entity.user, FIELDS_USER, {
        ...props.data,
        accountId: props.accountId,
      })}
      onDone={props.onDone}
      onError={props.onError}
    />
  )
}

export default CreateUser
