import React from 'react'
import GetServiceCheck from './get-service-check'
import CreateServiceCheck from './create'
import UpdateServiceCheck from './update'
import AddTeamToCheck from './add-to-team'
import RemoveTeamFromCheck from './remove-from-team'
import RunTest from './run-test'
import { CacheModifyFor } from '../../../../../utils/executeGraphQL'
import { ServiceCheckMapperDataToDomain } from '../../data/mapper'
import { FIELDS_CHECK } from '../../data/get-service-checks'
import { LatestChecksMapperDataToDomain } from '../../../../overview/data/mapper'

export const GQLProviderServiceCheck = () => {
  const get = (id, accountId, onLoad, onError) => {
    GetServiceCheck({
      accountId: accountId,
      id: id,
      mapper: ServiceCheckMapperDataToDomain,
      onLoad: onLoad,
      onError: onError,
    })
  }
  const create = (data, onLoad, onError) => {
    return (
      <CreateServiceCheck
        data={data}
        onError={onError}
        onDone={(data) => {
          onLoad(ServiceCheckMapperDataToDomain(data))
        }}
      />
    )
  }
  const update = (id, accountId, data, onDone, onError) => {
    return (
      <UpdateServiceCheck
        id={id}
        accountId={accountId}
        data={data}
        onError={onError}
        onDone={(data) => {
          onDone(ServiceCheckMapperDataToDomain(data))
        }}
      />
    )
  }
  const performTest = (id, accountId, data, onDone, onError) => {
    return (
      <UpdateServiceCheck
        id={id}
        accountId={accountId}
        data={data}
        onError={onError}
        onDone={(data) => {
          onDone(ServiceCheckMapperDataToDomain(data))
        }}
      />
    )
  }
  const addToTeam = (serviceCheckId, teamId, onDone, onError) => {
    console.log('addToTeam', serviceCheckId, teamId)
    return (
      <AddTeamToCheck
        serviceCheckId={serviceCheckId}
        teamId={teamId}
        onError={onError}
        onDone={onDone}
      />
    )
  }
  const removeFromTeam = (serviceCheckId, teamId, onDone, onError) => {
    console.log('removeFromTeam', serviceCheckId, teamId)
    return (
      <RemoveTeamFromCheck
        serviceCheckId={serviceCheckId}
        teamId={teamId}
        onError={onError}
        onDone={onDone}
      />
    )
  }
  const runTest = (dataToTest, onDone, onError) => {
    return (
      <RunTest
        dataToTest={dataToTest}
        onDone={(data) => {
          onDone(LatestChecksMapperDataToDomain(data))
        }}
        onError={onError}
      />
    )
  }

  return {
    GetCheck: get,
    CreateCheck: create,
    UpdateCheck: update,
    TestCheck: performTest,
    AddToTeam: addToTeam,
    RemoveFromTeam: removeFromTeam,
    RunTest: runTest,
  }
}

/**
 *
 * @param cache object
 * @param updated object
 * @param cacheChangeType CacheChangeType
 * @returns {*[]}
 */
export const updateCacheCheck = (cache, updated, cacheChangeType) => {
  cache.modify({
    fields: {
      serviceChecks(existing = []) {
        console.log('updateCacheCheck: ', JSON.stringify(updated))
        return CacheModifyFor(cacheChangeType, existing, updated, FIELDS_CHECK)
      },
    },
  })
}
