import { Countries } from '../../../../../utils/countries'

export const AccountMapperDataToDomain = (src) => {
  console.log('AccountMapperDataToDomain src', src)
  let cnt = src.address.country ?? ''
  if (cnt.length > 0) {
    for (const country of Countries) {
      if (country.code === cnt) {
        cnt = country.label
        break
      }
    }
  }
  return {
    id: src.id,
    name: src.name,
    address: src.address.line1,
    address_2: src.address.line2,
    country: cnt,
    city: src.address.city,
    state: src.address.state,
    zip: src.address.zip,
    createdAt: src.createdAt,
  }
}
export const AccountMapperDomainToData = (src) => {
  console.log('AccountMapperDomainToData src', src)
  let cnt = (src.country ?? '').trim()
  if (cnt.length > 0) {
    for (const country of Countries) {
      if (country.label.trim() === cnt) {
        cnt = country.code
        break
      }
    }
  }
  const data = {
    id: src.id,
    name: src.name,
    address: {
      line1: src.address,
      line2: src.address_2,
      zip: src.zip,
      city: src.city,
      state: src.state,
    },
  }
  if (typeof src.country !== 'undefined') {
    data.address.country = cnt
  }
  return data
}
