import React from 'react'
import { DashboardContextProvider } from './context'
import DashboardViews from './views'
import { useAppContext } from '../../context/app-context'
import { GQLProviderDashboard } from './data/provider'

const DashboardView = (props) => {
  const { appData } = useAppContext()
  const data = {
    accountId: (appData.account || props.account).id,
    teams: appData.teams,
  }
  return (
    <DashboardContextProvider data={data} provider={GQLProviderDashboard()}>
      <DashboardViews />
    </DashboardContextProvider>
  )
}

export default DashboardView
