import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    subscription: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedSubscription = (data) => {
  return {
    type: S_LOAD_DONE,
    subscription: data,
    errors: {},
  }
}
export const StateNoSubscription = {
  type: S_NO_DATA,
  subscription: {},
  errors: {},
}
export const actionLoadedSubscription = (data) => {
  const subscription = { ...data.plan }
  return {
    reduce: () => {
      return StateLoadedSubscription(subscription)
    },
  }
}
export const actionNoSubscription = {
  reduce: (state) => {
    return { ...state, ...StateNoSubscription }
  },
}
