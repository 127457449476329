export const VerifyPhoneStates = {
  noPhone: 0,
  notVerified: 1,
  verified: 2,
  codeSending: 3,
  codeSent: 4,
  verifying: 5,
  badCode: 6,
  error: 6,
  calculateFor: (userDomain) => {
    if ((userDomain.mobile ?? '').length === 0) {
      return VerifyPhoneStates.noPhone
    }
    return userDomain.phone_verified
      ? VerifyPhoneStates.verified
      : VerifyPhoneStates.notVerified
  },
}

export const StateInitial = (data) => {
  return {
    type: VerifyPhoneStates.calculateFor(data),
    phone: {
      verified: data.phone_verified,
    },
    phoneEdit: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedUser = (data) => {
  return {
    type: VerifyPhoneStates.calculateFor(data),
    phone: {
      verified: data.phone_verified,
    },
    phoneEdit: {},
    errors: {},
  }
}

export const StateSaving = () => {
  return {
    type: VerifyPhoneStates.verifying,
  }
}

export const actionRequestCode = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        type: VerifyPhoneStates.codeSending,
      }
    },
  }
}

export const actionCodeSent = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        type: VerifyPhoneStates.codeSent,
      }
    },
  }
}

export const actionVerifyPhone = (code) => {
  return {
    reduce: (state) => {
      return {
        ...state,
        type: VerifyPhoneStates.verifying,
      }
    },
  }
}

export const actionPhoneVerified = (user) => {
  const data = { ...user }
  return {
    reduce: (state) => {
      const result = { ...StateInitial(data) }
      return result
    },
  }
}
export const actionOnFieldChange = (data) => {
  const phoneSrc = { ...data }
  console.log('actionOnFieldChange phoneSrc', phoneSrc)
  return {
    reduce: (state) => {
      const changedKey = Object.keys(phoneSrc)[0]
      const phoneEdit = { ...state.phoneEdit, ...phoneSrc }
      const phone = state.phone
      if (
        (phone[changedKey] === null && phoneEdit[changedKey] === '') ||
        phone[changedKey] === phoneEdit[changedKey]
      ) {
        delete phoneEdit[changedKey]
      }
      return { ...state, phoneEdit }
    },
  }
}
