import React from 'react'
import UpdateTeam from './update'
import CreateTeam from './create'
import { CacheModifyFor } from '../../../../../utils/executeGraphQL'
import { FIELDS_TEAM } from '../../../data/get'
import { TeamMapperDataToDomain, TeamMapperDomainToData } from './mapper'

export const GQLProviderTeam = () => {
  const updateTeam = (id, src, onLoad, onError) => {
    const onReadyToMap = (data) => {
      onLoad(TeamMapperDataToDomain(data))
    }
    console.log('provider updateTeam: ', src)
    return (
      <UpdateTeam
        id={id}
        data={TeamMapperDomainToData(src)}
        onError={onError}
        onLoad={onReadyToMap}
      />
    )
  }
  const createTeam = (data, onLoad, onError) => {
    const onReadyToMap = (data) => {
      onLoad(TeamMapperDataToDomain(data))
    }
    return (
      <CreateTeam
        data={TeamMapperDomainToData(data)}
        onError={onError}
        onDone={onReadyToMap}
      />
    )
  }

  return {
    UpdateTeam: updateTeam,
    CreateTeam: createTeam,
  }
}

/**
 *
 * @param cache object
 * @param updated object
 * @param cacheChangeType CacheChangeType
 * @returns {*[]}
 */
export const updateCacheTeams = (cache, updated, cacheChangeType) => {
  cache.modify({
    fields: {
      teams(existing = []) {
        return CacheModifyFor(cacheChangeType, existing, updated, FIELDS_TEAM)
      },
    },
  })
}
