import React from 'react'
import PropTypes from 'prop-types'
import GetEvents from './get'

export const GQLProviderEvents = () => {
  const get = (accountId, serviceId, timestamp, onLoad, onError) => {
    return (
      <GetEvents
        onLoad={onLoad}
        onError={onError}
        accountId={accountId}
        serviceId={serviceId}
        timestamp={timestamp}
      />
    )
  }

  get.propTypes = {
    accountId: PropTypes.string,
    serviceId: PropTypes.string,
    timestamp: PropTypes.number,
  }

  return {
    GetEvents: get,
  }
}
