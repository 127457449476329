export const EventMapperDataToDomain = (src) => {
  // Map data from API (Data) to data used in Domain (web-app)
  return {
    id: src.eventId,
    event: src.eventType,
    target: src.target,
    timestamp: src.timestamp,
    type: src.type,
  }
}
export const EVENT_TYPE = {
  web: 'web',
  sms: 'sms',
  email: 'email',
  voice: 'voice',
  slack: 'slack',
}
