import React, { createContext, useContext, useReducer } from 'react'
import {
  actionLoaded,
  actionOnChange,
  StateInitial,
  actionCreated,
  actionUpdated,
  actionSave,
  actionOnScheduleChange,
  USER_NTF_TYPE,
} from './states-actions'
import { eventToText } from '../../../../utils/eventsExt'
import {
  actionLoadFailed,
  S_LOAD_START,
} from '../../../../utils/states-actions'
import { reducer } from '../../../../components/reducer'
import { useAppContext } from '../../../../context/app-context'
import { Entity } from '../../../entities'
import { GQLProviderNotification } from './data/provider'
import { SCHEDULE_DEFAULT } from './data/get'
import { ScheduleMapperDomainToData } from './data/mapper'

export const useNotification = (data, providerIn) => {
  const { forEntity, entityId, scheduleId, onNewSchedule } = data
  const { appData, updateUser } = useAppContext()
  const provider = providerIn ?? GQLProviderNotification()
  const initState = StateInitial()
  const [state, dispatch] = useReducer(reducer, initState)

  const isForUser = forEntity === Entity.user
  const scheduleIdEmail = isForUser
    ? appData.user.ntf_email_schedule
    : scheduleId
  const scheduleIdSms = isForUser
    ? appData.user.ntf_sms_schedule
    : SCHEDULE_DEFAULT

  const Load = () => {
    const onError = (err) => {
      console.log('::LoadNotification llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('onLoad NotificationSettings: ', data)
      dispatch(actionLoaded(data))
    }
    provider.GetNotification({
      forEntity: forEntity,
      entityId: entityId,
      accountId: appData.account.id,
      scheduleId:
        state.scheduleSource === USER_NTF_TYPE.sms
          ? scheduleIdSms
          : scheduleIdEmail,
      onLoad,
      onError,
    })
  }

  const Save = () => {
    return state.notification.id === SCHEDULE_DEFAULT ? Create() : Update()
  }

  const Update = () => {
    const onError = (err) => {
      console.log('::UpdateNotification llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onLoad = (data) => {
      dispatch(actionUpdated(data))
    }
    return provider.UpdateNotification(
      state.notification.id,
      ScheduleMapperDomainToData({
        entity: forEntity,
        entityId: entityId,
        ...state.notification,
        ...state.notificationEdit,
      }),
      onLoad,
      onError
    )
  }

  const Create = () => {
    const onError = (err) => {
      console.log('::createNotification llOnError:', err)
      dispatch(actionLoadFailed(err))
    }

    const onDone = (created) => {
      dispatch(actionCreated(created))
      if (isForUser) {
        const data = { ...appData.user }
        if (state.scheduleSource === USER_NTF_TYPE.sms) {
          data.ntf_sms_schedule = created.id
          data.ntf_sms_enabled = true
        } else {
          data.ntf_email_schedule = created.id
          data.ntf_email_enabled = true
        }
        provider.UpdateNotificationForUser(
          appData.user.id,
          data,
          (updatedUser) => {
            console.log('updatedUser', updatedUser)
            updateUser(updatedUser)
          },
          (error) => {
            console.log('onError', error)
          }
        )
        return
      }
      onNewSchedule(created.id)
    }

    return provider.CreateNotification(
      forEntity,
      entityId,
      ScheduleMapperDomainToData({
        entity: forEntity,
        entityId: entityId,
        ...state.notification,
        ...state.notificationEdit,
      }),
      onDone,
      onError
    )
  }

  const hasChanges = () => {
    return Object.keys(state.notificationEdit).length > 0
  }

  const doSave = () => {
    const changed = { ...state.notificationEdit }
    console.log('changed', changed)
    dispatch(actionSave())
  }
  const setupForNew = () => {
    dispatch(actionLoaded({ id: 'new' }))
  }
  const onFieldChange = (value) => {
    const key = value.target.id || value.target.name
    console.log('onFieldChange', key)
    const keyValPair = {}
    keyValPair[`${key}`] = eventToText(value)
    dispatch(actionOnChange({ ...keyValPair }))
  }
  const onScheduleChange = (event) => {
    dispatch(actionOnScheduleChange(eventToText(event)))
  }
  const getScheduleField = () => {
    return {
      value: state.scheduleSource,
    }
  }
  const doToggleContactHook = (event) => {
    const key = event.target.id
    const keyValPair = {}
    keyValPair[`${key}`] = event.target.checked
    dispatch(actionOnChange({ ...keyValPair }))
  }
  const getEditField = (name, defValue = '') => {
    const value =
      typeof state.notificationEdit[name] === 'undefined'
        ? state.notification[name]
        : state.notificationEdit[name]
    return {
      value: value || defValue,
      error: state.errors[name] || null,
    }
  }

  if (state.type === S_LOAD_START) {
    Load()
  }

  return {
    state,
    forEntity,
    entityId,
    scheduleId,
    scheduleIdSms,
    loadNotification: Load,
    save: Save,
    hasChanges,
    setupForNew,
    doSave,
    getEditField,
    doToggleContactHook,
    onFieldChange,
    getScheduleField,
    onScheduleChange,
  }
}

export const NotificationContext = createContext({})
export const useNotificationContext = () => useContext(NotificationContext)
export const NotificationContextProvider = (props) => {
  const hookRef = useNotification(props.data, props.provider)

  return (
    <NotificationContext.Provider value={hookRef}>
      {props.children}
    </NotificationContext.Provider>
  )
}
