import React from 'react'
import {
  buildForDelete,
  ExecuteGQLWithBuilder,
} from '../../../../utils/executeGraphQL'
import { Entity } from '../../../entities'

export const DeleteServiceCheck = (props) => {
  const { id, onDone, onError } = props
  const filter = { id: id }

  return (
    <ExecuteGQLWithBuilder
      builderData={buildForDelete(Entity.service, filter, 'id')}
      onDone={() => {
        onDone(filter)
      }}
      onError={onError}
    />
  )
}

export default DeleteServiceCheck
