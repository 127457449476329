import {
  buildForGet,
  ExecuteGQLAsync,
} from '../../../../../utils/executeGraphQL'
import { Entity } from '../../../../entities'
import { FIELDS_CHECK } from '../../data/get-service-checks'

export const GetServiceCheck = (props) => {
  const { accountId, id, onLoad, onError } = props
  const { gql, mutationName, variables } = buildForGet(
    Entity.service,
    FIELDS_CHECK,
    { accountId: accountId, id: id }
  )
  ExecuteGQLAsync({
    justFirst: true,
    mapper: props.mapper,
    actionName: mutationName,
    variables: variables,
    onDone: onLoad,
    onError: onError,
    query: gql,
  })
}

export default GetServiceCheck
