import React, { createContext, useContext } from 'react'

export const ELASTIC_INDEX = {
  ServiceChecks: 'servicechecks',
}
// const querystring = require('querystring')

// const objectToQuery = (src) => {
//   console.log('src', JSON.stringify(src))
//   return Object.entries(src)
//     .map((pair) => {
//       console.log(' map1 pair', JSON.parse(JSON.stringify(pair)))
//       const value = pair[1]
//       console.log('   value', JSON.parse(JSON.stringify(value)))
//       if (typeof value == 'object') {
//         return [pair[0], objectToQuery(value)].join('=')
//       }
//       return pair.map(encodeURIComponent).join('=')
//       // return pair
//       //   .map((value) => {
//       //     console.log('   map2 value', JSON.parse(JSON.stringify(value)))
//       //     if (typeof value == 'object') {
//       //       return [pair[0], objectToQuery(value)]
//       //     }
//       //     return pair.map(encodeURIComponent)
//       //   })
//       //   .join('=')
//     })
//     .join('&')
// }

const ElasticContext = createContext({})
export const useElasticContext = () => useContext(ElasticContext)
export const ElasticProvider = (props) => {
  const url = `${process.env.REACT_APP_ELASTIC_PROXY}/`
  const { children } = props
  const request = (params) => {
    const tmpParams = { ...params.body }
    delete tmpParams.index
    const requestOptions = {
      method: 'GET',
    }
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify(params.body),
    // const queryParams = new URLSearchParams(tmpParams.body).toString()
    // const queryParams = objectToQuery(tmpParams.body)
    // const queryParams = querystring.stringify(tmpParams.body)
    // return fetch(`${url}${params.index}/search?${queryParams}`, requestOptions)
    return fetch(
      `${url}${
        params.index
      }/_search?source_content_type=application/json&source=${JSON.stringify(
        tmpParams
      )}`,
      requestOptions
    )
  }

  return (
    <ElasticContext.Provider
      value={{
        request,
      }}
    >
      {children}
    </ElasticContext.Provider>
  )
}
