import React from 'react'
import GetAnalytics from './get'

export const GQLProviderDashboard = () => {
  const Get = (accountId, onLoad, onError) => {
    return (
      <GetAnalytics
        accountId={accountId}
        onLoad={onLoad}
        onError={onError}
        useDummyData={false}
      />
    )
  }

  return {
    GetAnalyticsData: Get,
  }
}
