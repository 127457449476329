import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
  S_SAVING,
} from '../../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    events: [],
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedEvents = (data) => {
  return {
    type: S_LOAD_DONE,
    events: data,
    errors: {},
  }
}
export const StateNoEvents = {
  type: S_NO_DATA,
  events: [],
  errors: {},
}
export const StateSaving = {
  type: S_SAVING,
}
export const StateCreated = (data) => {
  return {
    type: S_CREATED,
    event: data,
    eventEdit: {},
    errors: {},
  }
}
export const actionLoadedEvents = (data) => {
  const events = [...data]
  return {
    reduce: () => {
      return StateLoadedEvents(events)
    },
  }
}
export const actionNoEvents = {
  reduce: (state) => {
    return { ...state, ...StateNoEvents }
  },
}
export const actionCreateSub = (data) => {
  const ddd = data
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving,
        create: { ...ddd },
      }
    },
  }
}
