import React from 'react'
import { GQLProviderTeams } from './data/provider'
import { TeamsContextProvider } from './context'
import TeamsViews from './views'
import { useAppContext } from '../../context/app-context'

const TeamsView = (props) => {
  const { appData } = useAppContext()
  const data = { accountId: (appData.account || props.account).id }
  return (
    <TeamsContextProvider data={data} provider={GQLProviderTeams()}>
      <TeamsViews />
    </TeamsContextProvider>
  )
}

export default TeamsView
