import React from 'react'
import { GQLProviderIncidents } from './data/provider'
import { IncidentsContextProvider } from './context'
import { useAppContext } from '../../context/app-context'
import IncidentsViews from './views'

const IncidentsView = (props) => {
  const { appData } = useAppContext()
  const data = {
    account: appData.account || props.account,
    user: appData.user || props.user,
    users: appData.users || props.users,
  }
  return (
    <IncidentsContextProvider data={data} provider={GQLProviderIncidents()}>
      <IncidentsViews />
    </IncidentsContextProvider>
  )
}

export default IncidentsView
