export const Timezones = [
  {
    text: 'Africa/Abidjan',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Accra',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Addis_Ababa',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Algiers',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Asmara',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Asmera',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Bamako',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Bangui',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Banjul',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Bissau',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Blantyre',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Brazzaville',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Bujumbura',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Cairo',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Casablanca',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Ceuta',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Conakry',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Dakar',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Dar_es_Salaam',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Djibouti',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Douala',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/El_Aaiun',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Freetown',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Gaborone',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Harare',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Johannesburg',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Juba',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Kampala',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Khartoum',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Kigali',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Kinshasa',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Lagos',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Libreville',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Lome',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Luanda',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Lubumbashi',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Lusaka',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Malabo',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Maputo',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Maseru',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Mbabane',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Mogadishu',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Monrovia',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Nairobi',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Africa/Ndjamena',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Niamey',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Nouakchott',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Ouagadougou',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Porto-Novo',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Sao_Tome',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Timbuktu',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Africa/Tripoli',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Africa/Tunis',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Africa/Windhoek',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'America/Adak',
    tzOffset: '-09:00',
    diff: '-9',
  },
  {
    text: 'America/Anchorage',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'America/Anguilla',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Antigua',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Araguaina',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Buenos_Aires',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Catamarca',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/ComodRivadavia',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Cordoba',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Jujuy',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/La_Rioja',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Mendoza',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Rio_Gallegos',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Salta',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/San_Juan',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/San_Luis',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Tucuman',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Argentina/Ushuaia',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Aruba',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Asuncion',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Atikokan',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Atka',
    tzOffset: '-09:00',
    diff: '-9',
  },
  {
    text: 'America/Bahia',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Bahia_Banderas',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Barbados',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Belem',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Belize',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Blanc-Sablon',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Boa_Vista',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Bogota',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Boise',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Buenos_Aires',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Cambridge_Bay',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Campo_Grande',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Cancun',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Caracas',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Catamarca',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Cayenne',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Cayman',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Chicago',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Chihuahua',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Coral_Harbour',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Cordoba',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Costa_Rica',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Creston',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Cuiaba',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Curacao',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Danmarkshavn',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'America/Dawson',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Dawson_Creek',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Denver',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Detroit',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Dominica',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Edmonton',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Eirunepe',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/El_Salvador',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Ensenada',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Fort_Nelson',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Fort_Wayne',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Fortaleza',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Glace_Bay',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Godthab',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'America/Goose_Bay',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Grand_Turk',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Grenada',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Guadeloupe',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Guatemala',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Guayaquil',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Guyana',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Halifax',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Havana',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Hermosillo',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Indiana/Indianapolis',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Indiana/Knox',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Indiana/Marengo',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Indiana/Petersburg',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Indiana/Tell_City',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Indiana/Vevay',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Indiana/Vincennes',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Indiana/Winamac',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Indianapolis',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Inuvik',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Iqaluit',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Jamaica',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Jujuy',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Juneau',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'America/Kentucky/Louisville',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Kentucky/Monticello',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Knox_IN',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Kralendijk',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/La_Paz',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Lima',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Los_Angeles',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Louisville',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Lower_Princes',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Maceio',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Managua',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Manaus',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Marigot',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Martinique',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Matamoros',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Mazatlan',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Mendoza',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Menominee',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Merida',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Metlakatla',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'America/Mexico_City',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Miquelon',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'America/Moncton',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Monterrey',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Montevideo',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Montreal',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Montserrat',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Nassau',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/New_York',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Nipigon',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Nome',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'America/Noronha',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'America/North_Dakota/Beulah',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/North_Dakota/Center',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/North_Dakota/New_Salem',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Nuuk',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'America/Ojinaga',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Panama',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Pangnirtung',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Paramaribo',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Phoenix',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Port-au-Prince',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Port_of_Spain',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Porto_Acre',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Porto_Velho',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Puerto_Rico',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Punta_Arenas',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Rainy_River',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Rankin_Inlet',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Recife',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Regina',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Resolute',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Rio_Branco',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Rosario',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Santa_Isabel',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Santarem',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Santiago',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Santo_Domingo',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Sao_Paulo',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Scoresbysund',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'America/Shiprock',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Sitka',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'America/St_Barthelemy',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/St_Johns',
    tzOffset: '-02:30',
    diff: '-2',
  },
  {
    text: 'America/St_Kitts',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/St_Lucia',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/St_Thomas',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/St_Vincent',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Swift_Current',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Tegucigalpa',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'America/Thule',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'America/Thunder_Bay',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Tijuana',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Toronto',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Tortola',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Vancouver',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Virgin',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'America/Whitehorse',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'America/Winnipeg',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'America/Yakutat',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'America/Yellowknife',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Antarctica/Casey',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Antarctica/Davis',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Antarctica/DumontDUrville',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Antarctica/Macquarie',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Antarctica/Mawson',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Antarctica/McMurdo',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Antarctica/Palmer',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Antarctica/Rothera',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Antarctica/South_Pole',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Antarctica/Syowa',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Antarctica/Troll',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Antarctica/Vostok',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Arctic/Longyearbyen',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Asia/Aden',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Almaty',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Amman',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Anadyr',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Asia/Aqtau',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Aqtobe',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Ashgabat',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Ashkhabad',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Atyrau',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Baghdad',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Bahrain',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Baku',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Asia/Bangkok',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Barnaul',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Beirut',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Bishkek',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Brunei',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Calcutta',
    tzOffset: '+05:30',
    diff: 5,
  },
  {
    text: 'Asia/Chita',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Choibalsan',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Chongqing',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Chungking',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Colombo',
    tzOffset: '+05:30',
    diff: 5,
  },
  {
    text: 'Asia/Dacca',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Damascus',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Dhaka',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Dili',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Dubai',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Asia/Dushanbe',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Famagusta',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Gaza',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Harbin',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Hebron',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Ho_Chi_Minh',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Hong_Kong',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Hovd',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Irkutsk',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Istanbul',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Jakarta',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Jayapura',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Jerusalem',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Kabul',
    tzOffset: '+04:30',
    diff: 4,
  },
  {
    text: 'Asia/Kamchatka',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Asia/Karachi',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Kashgar',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Kathmandu',
    tzOffset: '+05:45',
    diff: 5,
  },
  {
    text: 'Asia/Katmandu',
    tzOffset: '+05:45',
    diff: 5,
  },
  {
    text: 'Asia/Khandyga',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Kolkata',
    tzOffset: '+05:30',
    diff: 5,
  },
  {
    text: 'Asia/Krasnoyarsk',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Kuala_Lumpur',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Kuching',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Kuwait',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Macao',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Macau',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Magadan',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Asia/Makassar',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Manila',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Muscat',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Asia/Nicosia',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Novokuznetsk',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Novosibirsk',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Omsk',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Oral',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Phnom_Penh',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Pontianak',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Pyongyang',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Qatar',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Qostanay',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Qyzylorda',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Rangoon',
    tzOffset: '+06:30',
    diff: 6,
  },
  {
    text: 'Asia/Riyadh',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Saigon',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Sakhalin',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Asia/Samarkand',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Seoul',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Shanghai',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Singapore',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Srednekolymsk',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Asia/Taipei',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Tashkent',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Tbilisi',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Asia/Tehran',
    tzOffset: '+04:30',
    diff: 4,
  },
  {
    text: 'Asia/Tel_Aviv',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Asia/Thimbu',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Thimphu',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Tokyo',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Tomsk',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Ujung_Pandang',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Ulaanbaatar',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Ulan_Bator',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Asia/Urumqi',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Asia/Ust-Nera',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Asia/Vientiane',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Asia/Vladivostok',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Asia/Yakutsk',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Asia/Yangon',
    tzOffset: '+06:30',
    diff: 6,
  },
  {
    text: 'Asia/Yekaterinburg',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Asia/Yerevan',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Atlantic/Azores',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Atlantic/Bermuda',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Atlantic/Canary',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Atlantic/Cape_Verde',
    tzOffset: '-01:00',
    diff: '-1',
  },
  {
    text: 'Atlantic/Faeroe',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Atlantic/Faroe',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Atlantic/Jan_Mayen',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Atlantic/Madeira',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Atlantic/Reykjavik',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Atlantic/South_Georgia',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'Atlantic/St_Helena',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Atlantic/Stanley',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Australia/ACT',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Adelaide',
    tzOffset: '+09:30',
    diff: 9,
  },
  {
    text: 'Australia/Brisbane',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Broken_Hill',
    tzOffset: '+09:30',
    diff: 9,
  },
  {
    text: 'Australia/Canberra',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Currie',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Darwin',
    tzOffset: '+09:30',
    diff: 9,
  },
  {
    text: 'Australia/Eucla',
    tzOffset: '+08:45',
    diff: 8,
  },
  {
    text: 'Australia/Hobart',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/LHI',
    tzOffset: '+10:30',
    diff: '10',
  },
  {
    text: 'Australia/Lindeman',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Lord_Howe',
    tzOffset: '+10:30',
    diff: '10',
  },
  {
    text: 'Australia/Melbourne',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/NSW',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/North',
    tzOffset: '+09:30',
    diff: 9,
  },
  {
    text: 'Australia/Perth',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Australia/Queensland',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/South',
    tzOffset: '+09:30',
    diff: 9,
  },
  {
    text: 'Australia/Sydney',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Tasmania',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/Victoria',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Australia/West',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Australia/Yancowinna',
    tzOffset: '+09:30',
    diff: 9,
  },
  {
    text: 'Brazil/Acre',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'Brazil/DeNoronha',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'Brazil/East',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Brazil/West',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'CET',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'CST6CDT',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'Canada/Atlantic',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Canada/Central',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'Canada/Eastern',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'Canada/Mountain',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Canada/Newfoundland',
    tzOffset: '-02:30',
    diff: '-2',
  },
  {
    text: 'Canada/Pacific',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'Canada/Saskatchewan',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Canada/Yukon',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'Chile/Continental',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'Chile/EasterIsland',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Cuba',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'EET',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'EST',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'EST5EDT',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'Egypt',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Eire',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Etc/GMT',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/GMT+0',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/GMT+1',
    tzOffset: '-01:00',
    diff: '-1',
  },
  {
    text: 'Etc/GMT+10',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'Etc/GMT+11',
    tzOffset: '-11:00',
    diff: '-11',
  },
  {
    text: 'Etc/GMT+12',
    tzOffset: '-12:00',
    diff: '-12',
  },
  {
    text: 'Etc/GMT+2',
    tzOffset: '-02:00',
    diff: '-2',
  },
  {
    text: 'Etc/GMT+3',
    tzOffset: '-03:00',
    diff: '-3',
  },
  {
    text: 'Etc/GMT+4',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'Etc/GMT+5',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'Etc/GMT+6',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Etc/GMT+7',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'Etc/GMT+8',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'Etc/GMT+9',
    tzOffset: '-09:00',
    diff: '-9',
  },
  {
    text: 'Etc/GMT-0',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/GMT-1',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Etc/GMT-10',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Etc/GMT-11',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Etc/GMT-12',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Etc/GMT-13',
    tzOffset: '+13:00',
    diff: '13',
  },
  {
    text: 'Etc/GMT-14',
    tzOffset: '+14:00',
    diff: '14',
  },
  {
    text: 'Etc/GMT-2',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Etc/GMT-3',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Etc/GMT-4',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Etc/GMT-5',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Etc/GMT-6',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Etc/GMT-7',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Etc/GMT-8',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Etc/GMT-9',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Etc/GMT0',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/Greenwich',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/UCT',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/UTC',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/Universal',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Etc/Zulu',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Europe/Amsterdam',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Andorra',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Astrakhan',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Europe/Athens',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Belfast',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Belgrade',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Berlin',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Bratislava',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Brussels',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Bucharest',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Budapest',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Busingen',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Chisinau',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Copenhagen',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Dublin',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Gibraltar',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Guernsey',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Helsinki',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Isle_of_Man',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Istanbul',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Jersey',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Kaliningrad',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Kiev',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Kirov',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Lisbon',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Ljubljana',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/London',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Europe/Luxembourg',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Madrid',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Malta',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Mariehamn',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Minsk',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Monaco',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Moscow',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Nicosia',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Oslo',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Paris',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Podgorica',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Prague',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Riga',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Rome',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Samara',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Europe/San_Marino',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Sarajevo',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Saratov',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Europe/Simferopol',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Skopje',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Sofia',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Stockholm',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Tallinn',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Tirane',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Tiraspol',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Ulyanovsk',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Europe/Uzhgorod',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Vaduz',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Vatican',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Vienna',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Vilnius',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Volgograd',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Warsaw',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Zagreb',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Europe/Zaporozhye',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Europe/Zurich',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'GB',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'GB-Eire',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'GMT',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'GMT+0',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'GMT-0',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'GMT0',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Greenwich',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'HST',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'Hongkong',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Iceland',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Indian/Antananarivo',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Indian/Chagos',
    tzOffset: '+06:00',
    diff: 6,
  },
  {
    text: 'Indian/Christmas',
    tzOffset: '+07:00',
    diff: 7,
  },
  {
    text: 'Indian/Cocos',
    tzOffset: '+06:30',
    diff: 6,
  },
  {
    text: 'Indian/Comoro',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Indian/Kerguelen',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Indian/Mahe',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Indian/Maldives',
    tzOffset: '+05:00',
    diff: 5,
  },
  {
    text: 'Indian/Mauritius',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Indian/Mayotte',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Indian/Reunion',
    tzOffset: '+04:00',
    diff: 4,
  },
  {
    text: 'Iran',
    tzOffset: '+04:30',
    diff: 4,
  },
  {
    text: 'Israel',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'Jamaica',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'Japan',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Kwajalein',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Libya',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'MET',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'MST',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'MST7MDT',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Mexico/BajaNorte',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'Mexico/BajaSur',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Mexico/General',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'NZ',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'NZ-CHAT',
    tzOffset: '+12:45',
    diff: '12',
  },
  {
    text: 'Navajo',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'PRC',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'PST8PDT',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'Pacific/Apia',
    tzOffset: '+13:00',
    diff: '13',
  },
  {
    text: 'Pacific/Auckland',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Bougainville',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Chatham',
    tzOffset: '+12:45',
    diff: '12',
  },
  {
    text: 'Pacific/Chuuk',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Pacific/Easter',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Pacific/Efate',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Enderbury',
    tzOffset: '+13:00',
    diff: '13',
  },
  {
    text: 'Pacific/Fakaofo',
    tzOffset: '+13:00',
    diff: '13',
  },
  {
    text: 'Pacific/Fiji',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Funafuti',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Galapagos',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'Pacific/Gambier',
    tzOffset: '-09:00',
    diff: '-9',
  },
  {
    text: 'Pacific/Guadalcanal',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Guam',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Pacific/Honolulu',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'Pacific/Johnston',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'Pacific/Kanton',
    tzOffset: '+13:00',
    diff: '13',
  },
  {
    text: 'Pacific/Kiritimati',
    tzOffset: '+14:00',
    diff: '14',
  },
  {
    text: 'Pacific/Kosrae',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Kwajalein',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Majuro',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Marquesas',
    tzOffset: '-09:30',
    diff: '-9',
  },
  {
    text: 'Pacific/Midway',
    tzOffset: '-11:00',
    diff: '-11',
  },
  {
    text: 'Pacific/Nauru',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Niue',
    tzOffset: '-11:00',
    diff: '-11',
  },
  {
    text: 'Pacific/Norfolk',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Noumea',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Pago_Pago',
    tzOffset: '-11:00',
    diff: '-11',
  },
  {
    text: 'Pacific/Palau',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Pacific/Pitcairn',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'Pacific/Pohnpei',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Ponape',
    tzOffset: '+11:00',
    diff: '11',
  },
  {
    text: 'Pacific/Port_Moresby',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Pacific/Rarotonga',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'Pacific/Saipan',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Pacific/Samoa',
    tzOffset: '-11:00',
    diff: '-11',
  },
  {
    text: 'Pacific/Tahiti',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'Pacific/Tarawa',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Tongatapu',
    tzOffset: '+13:00',
    diff: '13',
  },
  {
    text: 'Pacific/Truk',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Pacific/Wake',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Wallis',
    tzOffset: '+12:00',
    diff: '12',
  },
  {
    text: 'Pacific/Yap',
    tzOffset: '+10:00',
    diff: '10',
  },
  {
    text: 'Poland',
    tzOffset: '+02:00',
    diff: 2,
  },
  {
    text: 'Portugal',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'ROC',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'ROK',
    tzOffset: '+09:00',
    diff: 9,
  },
  {
    text: 'Singapore',
    tzOffset: '+08:00',
    diff: 8,
  },
  {
    text: 'Turkey',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'UCT',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'US/Alaska',
    tzOffset: '-08:00',
    diff: '-8',
  },
  {
    text: 'US/Aleutian',
    tzOffset: '-09:00',
    diff: '-9',
  },
  {
    text: 'US/Arizona',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'US/Central',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'US/East-Indiana',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'US/Eastern',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'US/Hawaii',
    tzOffset: '-10:00',
    diff: '-10',
  },
  {
    text: 'US/Indiana-Starke',
    tzOffset: '-05:00',
    diff: '-5',
  },
  {
    text: 'US/Michigan',
    tzOffset: '-04:00',
    diff: '-4',
  },
  {
    text: 'US/Mountain',
    tzOffset: '-06:00',
    diff: '-6',
  },
  {
    text: 'US/Pacific',
    tzOffset: '-07:00',
    diff: '-7',
  },
  {
    text: 'US/Samoa',
    tzOffset: '-11:00',
    diff: '-11',
  },
  {
    text: 'UTC',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'Universal',
    tzOffset: '+00:00',
    diff: 0,
  },
  {
    text: 'W-SU',
    tzOffset: '+03:00',
    diff: 3,
  },
  {
    text: 'WET',
    tzOffset: '+01:00',
    diff: 1,
  },
  {
    text: 'Zulu',
    tzOffset: '+00:00',
    diff: 0,
  },
]
export const TimezonesSimple = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Nuuk',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'CET',
  'CST6CDT',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/UTC',
  'Etc/Universal',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'NZ',
  'NZ-CHAT',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kanton',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
  'UTC',
  'Universal',
  'W-SU',
  'WET',
  'Zulu',
]
