import { FormControl, Slider } from '@material-ui/core'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'
import React from 'react'
import Subtitle from './Subtitle'

const XCSlider = (props) => {
  const { getEditField, onEditFieldChange } = props
  const field = getEditField(props.field)
  const lblId = `${props.field}-label`
  const marks = [
    {
      value: props.minimum,
      label: `${props.minimum} ${props.unit}`,
    },
    {
      value: props.maximum,
      label: `${props.maximum} ${props.unit}s`,
    },
  ]
  const value = field.value || 1
  if (value > props.minimum) {
    marks.push({
      value,
      label: `${value} ${props.unit}s`,
    })
  }

  const formField = (
    <FormControl className={props.formControlClass}>
      <Subtitle id={lblId} key={`${props.field}-sub`}>
        {props.label || props.field.replaceAll(/_/g, ' ')}
      </Subtitle>
      <Slider
        id={props.field}
        key={props.field}
        aria-labelledby={lblId}
        valueLabelDisplay={'auto'}
        value={value}
        getAriaValueText={(val) => `${val} Day(s)`}
        defaultValue={props.defaultValue}
        min={props.minimum}
        max={props.maximum}
        step={props.step}
        marks={marks}
        className={props.className}
        disabled={props.disabled}
        onChange={(event, newValue) => {
          onEditFieldChange({
            target: { id: props.field, value: newValue },
          })
        }}
        margin="normal"
        variant="outlined"
      />
    </FormControl>
  )
  const asRow = props.asRow === undefined ? true : props.asRow
  return asRow ? <Row>{formField}</Row> : <>{formField}</>
}
export default XCSlider

XCSlider.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  step: PropTypes.number,
  unit: PropTypes.string,

  trans: PropTypes.func,
  onEditFieldChange: PropTypes.func,
  getEditField: PropTypes.func,
  asRow: PropTypes.bool,
}
