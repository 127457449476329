import React from 'react'
import { GQLProviderServiceChecks } from './data/provider'
import { ServiceChecksContextProvider } from './context'
import ServiceChecksViews from './views'
import { useAppContext } from '../../../context/app-context'

const ServiceChecksView = (props) => {
  const { appData } = useAppContext()
  const { account, teams } = appData
  // console.log('ServiceChecksView props:', props)

  const data = {
    serviceGroupId: props.groupId,
    plan: appData.plan || props.plan,
    teams: teams,
    account: account,
  }
  return (
    <ServiceChecksContextProvider
      data={data}
      provider={GQLProviderServiceChecks()}
    >
      <ServiceChecksViews />
    </ServiceChecksContextProvider>
  )
}

export default ServiceChecksView
