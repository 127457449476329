import React from 'react'
import { GQLProviderCheckResults } from './data/di'
import { CheckResultsContextProvider } from './context'
import CheckResultsViews from './views'
import { useAppContext } from '../../../context/app-context'

const CheckResultsView = (props) => {
  const { appData } = useAppContext()
  const data = {
    accountId: (appData.account || props.account).id,
    checkId: props.match.params.checkId,
  }
  return (
    <CheckResultsContextProvider
      data={data}
      provider={GQLProviderCheckResults()}
    >
      <CheckResultsViews />
    </CheckResultsContextProvider>
  )
}

export default CheckResultsView
