import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import {
  ExpandMore,
  Person,
  ExitToApp,
  AccountTree,
  Subscriptions,
  NotificationsActive,
} from '@material-ui/icons'
import { Typography, ListItemIcon } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Routes } from '../routes'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarAvatar: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    marginLeft: '20px',
  },
  appBarSpan: {
    marginLeft: '20px',
    color: theme.colors.primary,
  },
  appBarButton: {
    color: theme.colors.primary,
  },
  userButton: {
    marginLeft: 'auto',
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

const stringToColor = (string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export const UserMenu = (props) => {
  const { user, t } = props
  console.log('userMenu user', user)

  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const Menu = (props) => {
    // const { colorMode, setColorMode } = useAppContext()
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList
          autoFocusItem={open}
          id="menu-list-grow"
          onKeyDown={handleListKeyDown}
          onClick={handleClose}
        >
          <MenuItem>
            <Link to={Routes.profile} className={classes.navLink}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">
                {t('menu_profile.profile')}
              </Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to={Routes.notificationsUser(user.id)}
              className={classes.navLink}
            >
              <ListItemIcon>
                <NotificationsActive fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">
                {t('menu_profile.notifications')}
              </Typography>
            </Link>
          </MenuItem>
          {user.owner && user.owner.id && (
            <MenuItem>
              <Link to={Routes.account} className={classes.navLink}>
                <ListItemIcon>
                  <AccountTree fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  {t('menu_profile.account')}
                </Typography>
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <Link to={Routes.subscription} className={classes.navLink}>
              <ListItemIcon>
                <Subscriptions fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">
                {t('menu_profile.subscriptions')}
              </Typography>
            </Link>
          </MenuItem>
          <hr />
          {/* <MenuItem> */}
          {/*  <IconButton */}
          {/*    onClick={() => { */}
          {/*      setColorMode(colorMode === 'light' ? 'dark' : 'light') */}
          {/*    }} */}
          {/*  > */}
          {/*    <ListItemIcon> */}
          {/*      <Palette fontSize="small" /> */}
          {/*    </ListItemIcon> */}
          {/*    <Typography variant="inherit">{colorMode}</Typography> */}
          {/*  </IconButton> */}
          {/* </MenuItem> */}
          {/* <hr /> */}
          <MenuItem
            onClick={() => {
              localStorage.clear()
              props.logout()
            }}
          >
            <ListItemIcon>
              <ExitToApp fontSize="small" color="error" />
            </ListItemIcon>
            <Typography variant="inherit" color="error">
              {t('menu_profile.logout')}
            </Typography>
          </MenuItem>
          {/* <hr /> */}
          {/* <MenuItem> */}
          {/* <LangBar /> */}
          {/* </MenuItem> */}
        </MenuList>
      </ClickAwayListener>
    )
  }

  return (
    <div className={props.className}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.userButton}
      >
        <span className={classes.appBarSpan}>{user.name}</span>
        <Avatar className={classes.appBarAvatar} {...stringAvatar(user.name)} />
        <ExpandMore color="primary" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <Menu logout={props.logout} />
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default withTranslation()(React.memo(UserMenu))
