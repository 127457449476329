import { UpdateUser } from './update-user'
import React from 'react'
import { GetUser } from './get-user'
import { CreateUser } from './create'
import { UserMapperDataToDomain, UserMapperDomainToData } from './mapper'

/**
 * Provides Update and Get GraphQL components.
 * Provider is injected as dependency for useEditProfile hook,
 * which make testing that hook easier by mocking this provider.
 * @returns {{Get: (function(*, *, *)), Update: (function(*, *, *))}}
 * @constructor
 */
export const GraphQLProviderProfile = () => {
  const Get = (accountId, profileId, onLoad, onError) => {
    GetUser({
      id: profileId,
      accountId: accountId,
      onLoad: onLoad,
      onError: onError,
      mapper: UserMapperDataToDomain,
    })
  }
  const Update = (state, onLoad, onError) => {
    const onDone = (data) => {
      onLoad(UserMapperDataToDomain(data))
    }
    const changed = UserMapperDomainToData({
      ...state.user,
      ...state.profileEdit,
    })
    const changedNotifications =
      Object.keys(state.profileEdit).filter((prop) => {
        return prop.indexOf('ntf_') >= 0
      }).length > 0
    if (!changedNotifications) {
      delete changed.notifications
    }
    if (changed.cognitoDetails) {
      delete changed.cognitoDetails.email
    }
    delete changed.role
    delete changed.accountId
    delete changed.id

    return (
      <UpdateUser
        profileEdit={changed}
        profileId={state.user.id}
        onLoad={onDone}
        onError={onError}
      />
    )
  }
  const createUser = (accountId, data, onLoad, onError) => {
    const dataToSend = UserMapperDomainToData(data)
    delete dataToSend.id
    delete dataToSend.accountId
    delete dataToSend.notifications
    delete dataToSend.cognitoDetails.phone_number
    return (
      <CreateUser
        accountId={accountId}
        data={dataToSend}
        onError={onError}
        onDone={(created) => {
          onLoad(UserMapperDataToDomain(created))
        }}
      />
    )
  }

  return {
    Get,
    Update,
    CreateUser: createUser,
  }
}
