import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { usePlansContext } from '../context'
import {
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_SAVING,
  S_NO_DATA,
} from '../../../utils/states-actions'
import PlansList from './list'
import ErrorCmp from '../../../components/error'
import { LoadingForState } from '../../../components/Loading'
import { withTranslation } from 'react-i18next'
import Saving from './saving'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const styles = () => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
  planHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
  },
  planIcon: {
    minWidth: 'auto',
  },
  plan: {
    flex: '0 0 auto',
    flexDirection: 'row',
    padding: '8px',
  },
})
// sb-eglis4518816@business.example.com
// d2;hQ9_$

// Sandbox account
// sb-eglis4518816@business.example.com
// Client ID
// AYxfgeGDaHlL5B016tWnwtJIvcLMBSY8T9GCQkTnYviXQ2pd0uDa23fastbTJj64LlT8Z3AzLPiyot60
// Secret
// EOf47mP0Ly9Neg7ag4Q-JDugkCuUFgYEb10M67-qhV4LZZWK_wTW47UMXw0KYTNIs2kPrzcI-nQWQfCe

//   <div id="paypal-button-container-P-39D24615WU148352KMCIO62Q"></div>
// <script src="https://www.paypal.com/sdk/js?client-id=AYxfgeGDaHlL5B016tWnwtJIvcLMBSY8T9GCQkTnYviXQ2pd0uDa23fastbTJj64LlT8Z3AzLPiyot60&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
// <script>
//   paypal.Buttons({
//   style: {
//   shape: 'rect',
//   color: 'blue',
//   layout: 'vertical',
//   label: 'subscribe'
// },
//   createSubscription: function(data, actions) {
//   return actions.subscription.create({
//   /* Creates the subscription */
//   plan_id: 'P-39D24615WU148352KMCIO62Q'
// });
// },
//   onApprove: function(data, actions) {
//   alert(data.subscriptionID); // You can add optional success message for the subscriber here
// }
// }).render('#paypal-button-container-P-39D24615WU148352KMCIO62Q'); // Renders the PayPal button
// </script>

const PlansViews = (props) => {
  const hookRef = usePlansContext()
  const { state } = hookRef
  const { classes } = props
  console.log('PlansViews state', JSON.parse(JSON.stringify(state)))
  // console.log(state)

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.load()}
            <LoadingForState />
          </>
        )
      case S_NO_DATA:
      case S_LOAD_DONE:
        return (
          <Elements
            stripe={loadStripe(
              'pk_test_51LRvUiITO1Z68eF95Xzeaz8vELIVEiIJmkkd9ViqMZ3ptuJt22LQMSnhJsYo0ULWwzd9fByb0EEXFNc6XCKs7jTA00hk6QodSy'
            )}
          >
            {(props.editPlan && props.editPlan(hookRef)) || (
              <PlansList {...hookRef} />
            )}
          </Elements>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      case S_SAVING:
        return (
          <>
            {hookRef.createSub(state.create)}
            <Saving />
          </>
        )
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  console.log('gettt', getByState)

  // return <Paper className={classes.root}>{getByState()}</Paper>
  return (
    <Paper className={classes.root}>
      <stripe-pricing-table
        pricing-table-id="prctbl_1LaOMlITO1Z68eF9h5FdGeXO"
        publishable-key="pk_test_51LRvUiITO1Z68eF95Xzeaz8vELIVEiIJmkkd9ViqMZ3ptuJt22LQMSnhJsYo0ULWwzd9fByb0EEXFNc6XCKs7jTA00hk6QodSy"
      ></stripe-pricing-table>
    </Paper>
  )
}

export default withTranslation()(withStyles(styles)(PlansViews))
