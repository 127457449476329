import React from 'react'
import { GQLProviderManageUser } from './data/di'
import { ManageUserContextProvider } from './context'
import ManageUserViews from './views'
import { GQLProviderUsers } from '../data/provider'

const ManageUserView = (props) => {
  const data = {
    id: props.match.params.id,
    accountId: props.match.params.account,
  }
  return (
    <ManageUserContextProvider
      data={data}
      provider={GQLProviderUsers()}
      providerUser={GQLProviderManageUser()}
    >
      <ManageUserViews />
    </ManageUserContextProvider>
  )
}

export default ManageUserView
