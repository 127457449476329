import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useAccountContext } from '../context'
import {
  ACS_LOAD_DONE,
  ACS_LOAD_ERR,
  ACS_LOAD_START,
  ACS_SAVING,
} from '../states-actions'
import EditAccountInfo from './edit-account-info'
import SavingAccount from './saving-account'
import ErrorCmp from '../../../../../components/error'
import { withTranslation } from 'react-i18next'
import LoadingView from '../../../../../components/loading-view'
import TopBar from '../../../../../components/topbar'

const styles = (theme) => ({
  broot: {
    overflowX: 'auto',
    margin: 'auto',
    padding: '0 ' + theme.spacing(2) + 'px',
  },
  button: {
    float: 'right',
    marginLeft: 'auto',
  },
  navHolder: {
    padding: '8px',
  },
})

const AccountViews = (props) => {
  const hookRef = useAccountContext()
  const { state } = hookRef
  const { classes, t } = props
  console.log(state)
  console.log('accHookRef', hookRef)

  const getByState = () => {
    switch (state.type) {
      case ACS_LOAD_START:
        hookRef.load(hookRef.accountId)
        return <LoadingView />
      case ACS_LOAD_DONE:
        return <EditAccountInfo {...hookRef} />
      case ACS_SAVING:
        return (
          <>
            {hookRef.update()}
            <SavingAccount />
          </>
        )
      case ACS_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar titleText={t('account_edit.title')} hideBack={true} />
      <Paper className={classes.broot}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(AccountViews))
