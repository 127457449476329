import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    checkResults: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedCheckResults = (data) => {
  return {
    type: S_LOAD_DONE,
    checkResults: data,
    errors: {},
  }
}
export const StateNoCheckResults = {
  type: S_NO_DATA,
  checkResults: [],
  errors: {},
}
export const actionLoadedCheckResults = (data) => {
  const checkResults = [...data]
  return {
    reduce: () => {
      return StateLoadedCheckResults(checkResults)
    },
  }
}
export const actionNoCheckResults = {
  reduce: (state) => {
    return { ...state, ...StateNoCheckResults }
  },
}
