export const CheckType = {
  HTTP: 1,
  HEAD: 2,
  TCP: 3,
  DNS: 4,
  SMTP: 5,
  SSH: 6,
  PING: 7,
  CERT: 8,
}
export const CheckTypeValues = {
  1: 'HTTP',
  2: 'HEAD',
  3: 'TCP',
  4: 'DNS',
  5: 'SMTP',
  6: 'SSH',
  7: 'PING',
  8: 'CERT',
}
export const SupportedCheckTypes = {
  HTTP: CheckType.HTTP,
  HEAD: CheckType.HEAD,
  TCP: CheckType.TCP,
  DNS: CheckType.DNS,
  SSH: CheckType.SSH,
  CERT: CheckType.CERT,
}
export const AllSupportedTypes = Object.keys(SupportedCheckTypes)

export const ServiceType = {
  WEB: 'web',
  MAIL: 'mail',
}
export const GetServiceType = (checkType) => {
  switch (checkType) {
    case CheckType.SMTP:
      return ServiceType.MAIL
    case CheckType.HTTP:
    case CheckType.HEAD:
    case CheckType.TCP:
    case CheckType.DNS:
    case CheckType.SSH:
    case CheckType.PING:
    case CheckType.CERT:
      return ServiceType.WEB
    default:
      throw new Error(`Unknown checkType: ${checkType}`)
  }
}
export const CheckRateUnit = {
  constant: 0,
  seconds: 1,
  minutes: 2,
  hours: 3,
  days: 4,
}
export const TimeUnitInSeconds = {
  none: 0,
  second: 1,
  minute: 60,
  hour: 3600,
  day: 3600 * 24,
}
export const TimeUnitInSecondsValues = Object.values(TimeUnitInSeconds)
export const CheckStatus = {
  ok: 0,
  warning: 1,
  critical: 2,
  unknown: 3,
  total: 4,
}
export const DNSQuery = {
  A: 'A',
  AAAA: 'AAAA',
  SRV: 'SRV',
  TXT: 'TXT',
  MX: 'MX',
  ANY: 'ANY',
  none: '',
}
export const WithPortOption = [
  CheckType.HTTP,
  CheckType.HEAD,
  CheckType.TCP,
  CheckType.SMTP,
  CheckType.SSH,
]
export const WithURLOption = [CheckType.HTTP, CheckType.HEAD]

export const WithIpOption = [
  CheckType.HTTP,
  CheckType.HEAD,
  CheckType.TCP,
  CheckType.SMTP,
  CheckType.SSH,
  CheckType.PING,
]
export const WithHTTPOptions = [CheckType.HTTP, CheckType.HEAD]
export const WithAuthOptions = [CheckType.HTTP, CheckType.HEAD]
export const WithNoAdditionalOptions = [CheckType.CERT]
export const WithNoCheckRate = [CheckType.CERT]

export const GetServiceId = (plan, checkType) => {
  for (const serviceType of plan.service_type) {
    for (const serviceGroup of serviceType.service_group) {
      for (const service of serviceGroup.service) {
        if (service.checkTypes.indexOf(checkType) >= 0) {
          return service.id
        }
      }
    }
  }
  return null
}

export const GetServiceIds = (plan, groupId) => {
  for (const serviceType of plan.service_type) {
    for (const serviceGroup of serviceType.service_group) {
      if (serviceGroup.id === groupId) {
        return serviceGroup.service.map((service) => {
          return service.id
        })
      }
    }
  }
  return null
}
export const CheckTypeNames = {
  inited: false,
}
export const GetCheckTypeName = (value) => {
  if (!CheckTypeNames.inited) {
    Object.keys(CheckType).forEach((key) => {
      CheckTypeNames[CheckType[key]] = key
    })
    CheckTypeNames.inited = true
  }
  return CheckTypeNames[value]
}

export const CheckStatusColors = {
  inited: false,
}
export const GetStatusColor = (value) => {
  if (!CheckStatusColors.inited) {
    CheckStatusColors[CheckStatus.unknown] = 'grey'
    CheckStatusColors[CheckStatus.ok] = 'green'
    CheckStatusColors[CheckStatus.warning] = 'orange'
    CheckStatusColors[CheckStatus.critical] = 'red'
    CheckStatusColors[CheckStatus.total] = 'blue'
    CheckStatusColors.inited = true
  }
  return CheckStatusColors[value === undefined ? CheckStatus.unknown : value]
}

export const GetColorFromText = (value) => {
  switch (value) {
    case 'red':
      return '#ff0000'
    case 'green':
      return '#00ff00'
    case 'orange':
      return '#ffa500'
    default:
      return '#808080'
  }
}
