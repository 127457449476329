import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useEventsContext } from '../context'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../../utils/states-actions'
import EventsList from './list'
import ErrorCmp from '../../../../components/error'
import { withTranslation } from 'react-i18next'
import { Routes } from '../../../../routes'
import { useHistory } from 'react-router'
import TopBar from '../../../../components/topbar'
import LoadingView from '../../../../components/loading-view'

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
  button: {
    marginLeft: 'auto',
  },
  navHolder: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'start',
    padding: theme.spacing(3),
  },
  title: {
    paddingLeft: theme.spacing(3),
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonNav: {
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

const EventsViews = (props) => {
  const hookRef = useEventsContext()
  const history = useHistory()
  const { state, account, serviceId, timestamp } = hookRef
  const { classes, t } = props
  // console.log(state)
  const handleOnBack = () => {
    history.replace(Routes.incidents)
  }

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        return (
          <>
            {hookRef.loadEvents(account.id, serviceId, timestamp)}
            <LoadingView />
          </>
        )
      case S_DELETE:
      case S_NO_DATA:
      case S_LOAD_DONE:
        return (
          <>
            {(props.eventsList && props.eventsList(hookRef)) || (
              <EventsList {...hookRef} />
            )}
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <Paper className={classes.root}>
      <TopBar titleText={t('events.title')} onBack={handleOnBack} />
      {getByState()}
    </Paper>
  )
}

export default withTranslation()(withStyles(styles)(EventsViews))
