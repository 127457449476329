import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTeamsContext } from '../context'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../utils/states-actions'
import TeamsList from './list'
import ErrorCmp from '../../../components/error'
import LoadingView from '../../../components/loading-view'
import { useHistory } from 'react-router'
import { Routes } from '../../../routes'
import TopBar from '../../../components/topbar'
import { Add } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

const styles = () => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
})

const TeamsViews = (props) => {
  const hookRef = useTeamsContext()
  const history = useHistory()
  const { state } = hookRef
  const { classes, t } = props
  // console.log(state)
  const handleCreateOrg = () => {
    history.push(Routes.teamNew)
  }

  const getByState = () => {
    switch (state.type) {
      case S_LOAD_START:
        hookRef.load(hookRef.accountId)
        return (
          <>
            <LoadingView />
          </>
        )
      case S_DELETE:
      case S_NO_DATA:
      case S_LOAD_DONE:
        return (
          <>
            {state.type === S_DELETE && hookRef.deleteTeam(state.forDeletion)}
            {(props.teamsList && props.teamsList(hookRef)) || (
              <TeamsList {...hookRef} />
            )}
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar
        titleText={t('teams.title')}
        hideBack={true}
        hasNextButton={true}
        nextButtonIcon={<Add />}
        textNext={t('teams.add')}
        onNext={handleCreateOrg}
      />
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(TeamsViews))
