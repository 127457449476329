import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { getI18n, withTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { AssignmentTurnedIn } from '@material-ui/icons'
import { GetStatusColor } from '../../../services/checks/service-utils'
import Title from '../../../../components/Title'
import Subtitle from '../../../../components/Subtitle'
import { Row } from 'reactstrap'

const styles = (theme) => ({
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  textField: {
    minWidth: 300,
    visibility: 'hidden',
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    padding: theme.spacing(2),
  },
  title: {
    margin: 0,
  },
  subtitle: {
    margin: 'auto ' + theme.spacing(1) + 'px',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
})

/* eslint-disable camelcase */
export const LocationSection = (props) => {
  const { classes, geoip, asSingleLine } = props
  const { city_name, region_name, country_name } = geoip
  if (asSingleLine) {
    const address = [city_name, region_name, country_name]
      .filter((itm) => {
        return itm != null
      })
      .join(', ')
    return <Subtitle className={classes.subtitle}>{address}</Subtitle>
  }
  return (
    <>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Title className={classes.title}>{city_name}&nbsp;</Title>
        <Subtitle className={classes.subtitle}>
          {region_name}, {country_name}
        </Subtitle>
      </Row>
    </>
  )
}

const CheckResultsList = (props) => {
  const { state, t, classes } = props
  const formatter = new Intl.DateTimeFormat(getI18n().language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
  const hasData = state.checkResults.length > 0
  console.log('state', state)
  const CheckResultsTable = () => {
    if (!hasData) {
      return null
    }
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell key="status">{t('checkResults.status')}</TableCell>
            <TableCell key="name">{t('checkResults.attempted_from')}</TableCell>
            <TableCell key="latest">{t('checkResults.latest')}</TableCell>
            <TableCell key="loadtime">{t('checkResults.loadtime')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.checkResults.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <AssignmentTurnedIn
                    style={{ color: GetStatusColor(item.returncode) }}
                  />
                </TableCell>
                <TableCell>
                  {(item.src_geoip && (
                    <LocationSection
                      classes={classes}
                      asSingleLine={'ajdee'}
                      geoip={item.src_geoip}
                    />
                  )) ||
                    null}
                </TableCell>
                <TableCell>
                  {formatter.format(new Date(item.timestamp)) || 0}
                </TableCell>
                <TableCell>{item.loadtime || 0}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <CheckResultsTable />
    </div>
  )
}
export default withTranslation()(withStyles(styles)(CheckResultsList))
