export const Entity = {
  serviceCheck: 'serviceCheck',
  check: 'check',
  plan: 'plan',
  notification: 'notification',
  account: 'account',
  schedule: 'schedule',
  incident: 'incident',
  event: 'incidentEvent',
  service: 'service',
  serviceGroup: 'group',
  team: 'team',
  user: 'user',
  init: 'init',
  analytic: 'analytic',
}
export const Entities = {
  serviceChecks: 'serviceChecks',
  checks: 'checks',
  plans: 'plans',
  notifications: 'notifications',
  schedules: 'schedules',
  accounts: 'accounts',
  incidents: 'incidents',
  events: 'incidentEvents',
  services: 'services',
  serviceGroups: 'serviceGroups',
  teams: 'teams',
  users: 'users',
  inits: 'inits',
  analytics: 'analytics',
}
