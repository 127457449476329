import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
} from '../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    analytics: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoaded = (data) => {
  console.log('StateLoaded: ', data)
  return {
    type: S_LOAD_DONE,
    analytics: data,
    errors: {},
  }
}
export const StateNoData = {
  type: S_NO_DATA,
  analytics: [],
  errors: {},
}
export const actionLoadedAnalytics = (data) => {
  const analytics = { ...data }
  return {
    reduce: () => {
      return StateLoaded(analytics)
    },
  }
}
export const actionActiveIndexChanged = (key, index) => {
  const indexInfo = { key, index }
  return {
    reduce: (state) => {
      const result = { ...state }
      result[indexInfo.key] = indexInfo.index
      return result
    },
  }
}
