export const eventToText = (event) => {
  // console.log(event)
  if (event.target && event.target.type === 'checkbox') {
    return event.target && event.target.checked
  }
  if (event.target && event.target.type === 'div') {
    return event.target.innerText
  }
  return event.target ? event.target.value : event
}
