import React, { createContext, useContext, useReducer } from 'react'
import {
  StateInitial,
  actionLoadedAnalytics,
  actionActiveIndexChanged,
} from './states-actions'
import { reducer } from '../../components/reducer'
import { actionLoadFailed } from '../../utils/states-actions'

/**
 *
 * @param data: object
 * @param provider: GQLProviderDashboard
 */
export const useDashboard = (data, provider) => {
  const { accountId, teams } = data
  const [state, dispatch] = useReducer(reducer, StateInitial())

  const Load = (accountId) => {
    console.log('::Load:', accountId)
    const onError = (err) => {
      console.log('::Load llOnError:', err)
      dispatch(actionLoadFailed(err))
    }
    const onLoad = (data) => {
      console.log('::Load llOnLoad:', data)
      dispatch(actionLoadedAnalytics(data))
    }
    return provider.GetAnalyticsData(accountId, onLoad, onError)
  }

  const onPiePartChange = (activeIndexName, dt, index) => {
    console.log('onPiePartChange: ', activeIndexName, dt, index)
    if (state[activeIndexName] === index) {
      return
    }
    dispatch(actionActiveIndexChanged(activeIndexName, index))
  }

  return {
    state,
    accountId,
    teams,
    loadAnalytics: Load,
    onPieEnter: onPiePartChange,
  }
}

export const DashboardContext = createContext({})
export const useDashboardContext = () => useContext(DashboardContext)
export const DashboardContextProvider = (props) => {
  const hookRef = useDashboard(props.data, props.provider)

  return (
    <DashboardContext.Provider value={hookRef}>
      {props.children}
    </DashboardContext.Provider>
  )
}
