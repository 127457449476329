import cognitoUtils from '../../../cognito/lib/cognitoUtils'
import { API, graphqlOperation } from 'aws-amplify'
import { GET_INIT } from './get-init'
import MapperInit from './mapper'

export const LOCAL_KEY_INIT = 'init'
export const LOCAL_KEY_THEME = 'light'

export const WelcomeProvider = () => {
  const CheckSession = (href, onDone, onError) => {
    cognitoUtils
      .handleHref(href)
      .then((result) => {
        console.log('session result: ', JSON.stringify(result))
        onDone(result)
      })
      .catch((error) => {
        console.log('session error: ', JSON.stringify(error))
        onError(error)
      })
  }
  const StoreResult = (sessionUserId, onDone, onError) => {
    console.log('StoreResult', sessionUserId)
    API.graphql(graphqlOperation(GET_INIT))
      .then((apiData) => {
        onDone(MapperInit(apiData.data.init, sessionUserId))
      })
      .catch((err) => {
        console.log('error: ', err)
        onError(err)
      })
  }

  return {
    CheckSession,
    StoreResult,
  }
}

export default WelcomeProvider
