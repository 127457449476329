import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const Subtitle = (props) => (
  <Typography
    component="h6"
    variant={props.variant || 'subtitle1'}
    style={props.style}
    className={props.className}
    color={props.color ?? 'textSecondary'}
    data-testid={props['data-testid']}
  >
    {props.children}
  </Typography>
)

Subtitle.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'initial',
    'inherit',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
  ]),
}
export default Subtitle
