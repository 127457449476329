import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { eventToText } from '../../../utils/eventsExt'
import { useHistory } from 'react-router'
import { Delete } from '@material-ui/icons'
import Loading from '../../../components/Loading'
import { Routes } from '../../../routes'
import { INVITATION_ACCEPTED } from '../user/edit/states-actions'
import { S_DELETE } from '../../../utils/states-actions'

const styles = (theme) => ({
  buttonOld: {
    float: 'right',
    display: 'block',
    textTransform: 'unset !important',
    marginTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  button: {
    textTransform: 'unset !important',
  },
  buttonManage: {
    textTransform: 'unset !important',
    marginRight: theme.spacing(3),
  },
  textField: {
    display: 'none',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  action: {
    textAlign: 'center',
    padding: 0,
  },
  tableHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    backgroundColor: '#FFFFFFDD',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
})
const invitationStatusToText = (status) => {
  return status === INVITATION_ACCEPTED ? 'accepted' : 'sent'
}

const UsersList = (props) => {
  const { state, t, classes, doDeleteUser, accountId } = props
  const history = useHistory()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterState, setFilterState] = useState({ nameFilter: '' })
  const handleOpenUser = (id) => {
    console.log('handleOpenUser', accountId, id)
    history.push(Routes.userManage(accountId, id))
  }
  const handleOpenTeam = (id) => {
    history.push(Routes.team(id))
  }
  const handleFilterChange = (event) => {
    const newFilter = { ...filterState }
    newFilter[`${event.target.id}`] = eventToText(event)
    setFilterState(newFilter)
  }
  const handleSortRequest = (property) => {
    const newOrderBy = property
    let newOrder = 'desc'

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc'
    }

    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }
  const hasUsers = state.users.length > 0
  const UsersTable = () => {
    if (!hasUsers) {
      return null
    }
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              key="name"
              sortDirection={orderBy === 'name' ? order : false}
            >
              <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={order}
                  onClick={() => handleSortRequest('name')}
                >
                  {t('users.name')}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
            <TableCell
              key="email"
              sortDirection={orderBy === 'email' ? order : false}
            >
              <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={order}
                  onClick={() => handleSortRequest('email')}
                >
                  {t('address.email')}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
            <TableCell key="invitation_status">
              <Tooltip
                title="Invitation status"
                placement="bottom-end"
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === 'invitation_status'}
                  direction={order}
                  onClick={() => handleSortRequest('invitation_status')}
                >
                  {t('users.invitation_status')}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
            <TableCell key="teams">
              <Tooltip title="Teams" placement="bottom-end" enterDelay={300}>
                <TableSortLabel
                  active={orderBy === 'teams'}
                  direction={order}
                  onClick={() => handleSortRequest('teams')}
                >
                  {t('users.teams')}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
            <TableCell key="actions" />
          </TableRow>
        </TableHead>
        <TableBody>
          {state.users.map((usr) => {
            if (!usr.teams) {
              usr.teams = []
            }
            const openUser = () => handleOpenUser(usr.id)
            const delUsr = () => doDeleteUser(usr.id)
            const isMarkedForDeletion = state.forDeletion === usr.id
            return (
              <TableRow key={usr.id} className={classes.row}>
                <TableCell onClick={openUser}>
                  {[usr.family_name, usr.given_name].join(' ')}
                </TableCell>
                <TableCell onClick={openUser}>{usr.email}</TableCell>
                <TableCell onClick={openUser}>
                  <div data-testid={`invitation-status-${usr.id}`}>
                    {t(invitationStatusToText(usr.invitation_status))}
                  </div>
                </TableCell>
                <TableCell>
                  {usr.teams.length > 0 &&
                    usr.teams.map((org) => {
                      const openOrg = (ev) => {
                        ev.stopPropagation()
                        handleOpenTeam(org.id)
                      }
                      return (
                        <Button
                          key={org.id}
                          size={'small'}
                          autoCapitalize={'lower'}
                          data-testid={`${usr.id}-org-${org.id}`}
                          variant="text"
                          color="primary"
                          onClick={openOrg}
                        >
                          {org.name}
                        </Button>
                      )
                    })}
                </TableCell>
                <TableCell className={classes.action}>
                  <IconButton
                    data-testid={`delete-${usr.id}`}
                    disabled={isMarkedForDeletion}
                    size={'small'}
                    onClick={delUsr}
                  >
                    <Delete color={'error'} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <div className={classes.tableHolder}>
      <Box
        className={classes.appBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={hasUsers ? 'space-between' : 'center'}
        p={1}
      >
        {/* SearchBox is hidden via CSS, check styles on top */}
        {hasUsers && (
          <TextField
            id={'nameFilter'}
            size={'small'}
            label={t('users.search')}
            className={classes.textField}
            value={filterState.nameFilter}
            onChange={handleFilterChange}
            variant="outlined"
            type="text"
          />
        )}
      </Box>
      <UsersTable />
      {state.type === S_DELETE && (
        <Loading className={classes.loader} onClick={() => {}} />
      )}
    </div>
  )
}
export default withTranslation()(withStyles(styles)(UsersList))
