import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useServiceChecksContext } from '../context'
import ServiceChecksList from './list'
import ErrorCmp from '../../../../components/error'
import {
  S_DELETE,
  S_LOAD_DONE,
  S_LOAD_ERR,
  S_LOAD_START,
  S_NO_DATA,
} from '../../../../utils/states-actions'
import { useAppContext } from '../../../../context/app-context'
import { UserRoles } from '../../../users/manage/roles'
import { S_TOGGLE, StateInitial } from '../states-actions'
import { withTranslation } from 'react-i18next'
import LoadingView from '../../../../components/loading-view'
import TopBar from '../../../../components/topbar'
import { Add } from '@material-ui/icons'
import { Routes } from '../../../../routes'
import { useHistory } from 'react-router'

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    margin: 'auto',
  },
  button: {
    marginLeft: 'auto',
  },
  filtersHolder: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
})

const ServiceChecksViews = (props) => {
  const hookRef = useServiceChecksContext()
  const { appData } = useAppContext()
  const history = useHistory()
  const { user, account } = appData
  const { state, teams } = hookRef
  const { classes, t } = props
  const handleCreateService = () => {
    history.push(Routes.serviceEditRoute('new'))
  }
  console.log('Check Index: state: ', state)
  const teamsData =
    (appData.user.role !== UserRoles.viewer ? teams : appData.user.teams) || []
  const filter =
    state.filter ||
    StateInitial(teamsData.length > 0 ? teamsData[0] : '').filter
  console.log('Check Index: FILTER: ', filter)

  const getByState = () => {
    const forceFetch = filter.forceFetch === true
    const stateType = forceFetch ? S_LOAD_START : state.type
    switch (stateType) {
      case S_LOAD_START:
        hookRef.loadChecks(
          filter.by,
          hookRef.serviceGroupId,
          user.id,
          account.id,
          filter.team,
          filter.type,
          forceFetch
        )
        return <LoadingView />
      case S_DELETE:
      case S_TOGGLE:
        return (
          <>
            {state.type === S_DELETE &&
              hookRef.deleteServiceCheck(state.forDeletion)}
            {state.type === S_TOGGLE &&
              hookRef.toggleServiceCheck(state.forToggle, state.activate)}
            <LoadingView />
          </>
        )
      case S_NO_DATA:
      case S_LOAD_DONE:
        return (
          <>
            {(props.serviceChecksList && props.serviceChecksList(hookRef)) || (
              <ServiceChecksList {...hookRef} />
            )}
          </>
        )
      case S_LOAD_ERR:
        return <ErrorCmp code={state.code} message={state.message} />
      default:
        throw new Error(`Unknown state type: ${state.type}`)
    }
  }

  return (
    <>
      <TopBar
        titleText={t('services.title')}
        hideBack={true}
        hasNextButton={true}
        nextButtonIcon={<Add />}
        onNext={handleCreateService}
      />
      {/* <CheckFilters */}
      {/*  onFilterChange={onFilterChange} */}
      {/*  filter={state.filter} */}
      {/*  teams={teams} */}
      {/*  user={appData.user} */}
      {/*  planType={appData.plan.name} */}
      {/*  onForceRefresh={onForceRefresh} */}
      {/*  onTeamChange={onTeamChange} */}
      {/*  onTypeChange={onTypeChange} */}
      {/*  handleCreate={handleCreateTeam} */}
      {/*  createButtonText={'service_checks.add'} */}
      {/* /> */}
      <Paper className={classes.root}>{getByState()}</Paper>
    </>
  )
}

export default withTranslation()(withStyles(styles)(ServiceChecksViews))
