import {
  S_LOAD_DONE,
  S_LOAD_START,
  S_NO_DATA,
} from '../../utils/states-actions'

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    teams: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedTeams = (data) => {
  return {
    type: S_LOAD_DONE,
    teams: data,
    errors: {},
  }
}
export const StateNoTeams = {
  type: S_NO_DATA,
  teams: [],
  errors: {},
}
export const actionLoadedTeams = (data) => {
  const teams = [...data]
  return {
    reduce: () => {
      return StateLoadedTeams(teams)
    },
  }
}
export const actionNoTeams = {
  reduce: (state) => {
    return { ...state, ...StateNoTeams }
  },
}
