import { Button, Paper } from '@material-ui/core'
import Title from '../../../components/Title'
import { Capitalize } from '../../../utils/stringExt'
import Subtitle from '../../../components/Subtitle'
import PropTypes from 'prop-types'
import React from 'react'
import { PlanType } from '../../../components/current-plan'

export const PlanTemplate = (props) => {
  // const stripe = useStripe()
  // const [paymentRequest, setPaymentRequest] = useState(null)

  // useEffect(() => {
  //   if (!stripe) {
  //     return
  //   }
  //   const pr = stripe.paymentRequest({
  //     country: 'US',
  //     currency: 'usd',
  //     total: {
  //       label: 'Demo total',
  //       amount: 1099,
  //     },
  //     requestPayerName: true,
  //     requestPayerEmail: true,
  //   })
  //   setPaymentRequest(pr)
  // }, [stripe])
  const { isCurrentPlan, planName, price, classes, t, theme, isForMonth } =
    props
  const currentPlanLabel = isCurrentPlan
    ? ` (${t('subscriptions.current_plan')})`
    : ''
  const planTransName = planName
  const priceInterval = `subscription.per_${isForMonth ? 'month' : 'year'}`

  const skipShowPay = planName === PlanType.free || isCurrentPlan

  return (
    <Paper elevation={isCurrentPlan ? 24 : 1} className={classes.plan}>
      <Title>{planName}</Title>
      <Title>{`${Capitalize(planTransName)} ${currentPlanLabel}`}</Title>
      <Subtitle
        style={{
          height: '240px',
          overflow: 'scroll',
          marginBottom: theme.spacing(2),
        }}
      >
        {t(`${planTransName}.desc`)}
      </Subtitle>
      {/* <Subtitle>{t('subscription.features')}</Subtitle> */}
      {/* {Object.entries(JSON.parse(t(`${planTransName}.features`))).map((f) => { */}
      {/*  return ( */}
      {/*    <div key={planName + '_' + f[0]}> */}
      {/*      <hr /> */}
      {/*      <Subtitle>{Capitalize(f[0].replace('_', ' ') + ':')}</Subtitle> */}
      {/*      <Subtitle> */}
      {/*        {Capitalize(f[1].feature.replace('_', ' '))} &nbsp;&nbsp; */}
      {/*      </Subtitle> */}
      {/*    </div> */}
      {/*  ) */}
      {/* })} */}
      <br />
      <Title>{t('subscription.price')}</Title>
      <Subtitle>{`$${price}/${t(priceInterval)}`}</Subtitle>
      <br />
      {skipShowPay ? null : <Button>{'Pay'}</Button>}
    </Paper>
  )
}

export const PlanTemplateLegacy = (props) => {
  const {
    isCurrentPlan,
    planName,
    price,
    planId,
    PayPalButton,
    subscriptionCreate,
    subscriptionApproved,
    classes,
    t,
    theme,
    isForMonth,
  } = props
  const currentPlanLabel = isCurrentPlan
    ? ` (${t('subscriptions.current_plan')})`
    : ''
  const planTransName = `subscription.plan_${planName}`
  const priceInterval = `subscription.per_${isForMonth ? 'month' : 'year'}`

  return (
    <Paper elevation={isCurrentPlan ? 24 : 1} className={classes.plan}>
      <Title>{`${Capitalize(
        t(`${planTransName}.title`)
      )} ${currentPlanLabel}`}</Title>
      <Subtitle
        style={{
          height: '240px',
          overflow: 'scroll',
          marginBottom: theme.spacing(2),
        }}
      >
        {t(`${planTransName}.desc`)}
      </Subtitle>
      <Subtitle>{t('subscription.features')}</Subtitle>
      {Object.entries(JSON.parse(t(`${planTransName}.features`))).map((f) => {
        return (
          <div key={planName + '_' + f[0]}>
            <hr />
            <Subtitle>{Capitalize(f[0].replace('_', ' ') + ':')}</Subtitle>
            <Subtitle>
              {Capitalize(f[1].feature.replace('_', ' '))} &nbsp;&nbsp;
            </Subtitle>
          </div>
        )
      })}
      <br />
      <Title>{t('subscription.price')}</Title>
      <Subtitle>{`$${price}/${t(priceInterval)}`}</Subtitle>
      <br />
      {planName === PlanType.free || isCurrentPlan ? null : (
        <PayPalButton
          createSubscription={(data, actions) =>
            subscriptionCreate(data, actions, planName, planId)
          }
          onApprove={(data, actions) =>
            subscriptionApproved(data, actions, planName, planId)
          }
        />
      )}
    </Paper>
  )
}

PlanTemplate.propTypes = {
  isCurrentPlan: PropTypes.bool.isRequired,
  planName: PropTypes.string.isRequired,
  PayPalButton: PropTypes.func,
  subscriptionCreate: PropTypes.func,
  subscriptionApproved: PropTypes.func,
  price: PropTypes.number.isRequired,
  planId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  isForMonth: PropTypes.bool,
}
