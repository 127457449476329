import { gql } from '@apollo/client'
import React from 'react'
import { ExecuteGQL } from '../../../utils/executeGraphQL'
import PropTypes from 'prop-types'

// createSubscription(
// accountId: ID!, userId: ID!, paypalSubId: String!, createdAt: String!, expiresAt: String!, planName: String!)

// mutation createSubscriptions($createdAt: DateTime!, $accountId: ID!, $userId: ID!, $planId: ID!) {
const QUERY = gql`
  mutation createSubscription(
    $paypalSubId: ID!
    $accountId: ID!
    $userId: ID!
    $planName: String!
  ) {
    # createSubscription(createdAt: $createdAt) {
    #   key
    #   createdAt {
    #     formatted
    #   }
    # }
    # AddPlanSubscriptionAccount(
    #   from: {key: $subId}
    #   to: {key: $accountId}
    # ) {
    #   from {
    #     key
    #   }
    #   to {
    #     key
    #     name
    #   }
    # }
    # AddPlanSubscriptionCreatedBy(
    #   from: {key: $subId}
    #   to: {key: $userId}
    # ) {
    #   from {
    #     key
    #   }
    #   to {
    #     key
    #     name
    #   }
    # }
    AddPlanSubscriptionPlan(from: { key: $subId }, to: { key: $planId }) {
      from {
        key
      }
      to {
        key
        name
      }
    }
  }
`
export const CreateSubscription = (props) => {
  const { planName, onLoad, onError } = props
  const filter =
    planName === 'multipleTeams'
      ? { account: { key: props.accountId } }
      : { user: { key: props.userId } }
  return (
    <ExecuteGQL
      actionName={'planSubscriptions'}
      variables={{
        filter,
      }}
      onDone={onLoad}
      onError={onError}
      query={QUERY}
    />
  )
}

export default CreateSubscription

CreateSubscription.propTypes = {
  planName: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  userId: PropTypes.string,
}
