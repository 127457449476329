import { ExecuteGQLAsync } from '../../../../../utils/executeGraphQL'
import { Entities } from '../../../../entities'
import { QUERY_PLAN_FIELDS } from '../../../../users/data/get-users'

export const ACCOUNT_FIELDS_BASE = [
  'id',
  'name',
  'active',
  'address',
  'createdAt',
  'owner',
  'plan',
]
export const ADDRESS_FIELDS_BASE = [
  'line1',
  'line2',
  'city',
  'zip',
  'state',
  'country',
  'lat',
  'long',
]
export const ACCOUNT_FIELDS_BASE_SUB = {
  // owner: 'owner {\n\tid\n\tname\n}',
  address: `address {\n${ADDRESS_FIELDS_BASE.join('\n')}\n}`,
  plan: `plan {\n${QUERY_PLAN_FIELDS}\n}`,
}
export const ACCOUNT_FIELDS = ACCOUNT_FIELDS_BASE.map(
  (name) => ACCOUNT_FIELDS_BASE_SUB[name] || name
).join('\n')

const QUERY = `
  query getAccounts($where: AccountsWhere!) {
    ${Entities.accounts}(where: $where) {
      items {
        ${ACCOUNT_FIELDS}
      }
    }
  }
`
export const GetAccount = (props) => {
  const { id, onLoad, onError } = props

  ExecuteGQLAsync({
    justFirst: true,
    actionName: Entities.accounts,
    variables: { where: { id: id } },
    onDone: onLoad,
    onError: onError,
    mapper: props.mapper,
    query: QUERY,
  })
}

export default GetAccount
