import { AccountMapperDataToDomain } from '../../accounts/account/edit/data/mapper'
import { UserMapperDataToDomain } from '../../users/user/edit/data/mapper'
import { TeamMapperDataToDomain } from '../../teams/team/edit/data/mapper'
import { ServiceCheckMapperDataToDomain } from '../../services/checks/data/mapper'

const MapperInit = (apiData, sessionUserId) => {
  console.log('apiData', JSON.stringify(apiData))
  const result = { ...apiData }
  console.log('apiData', result)
  result.plan = result.plan || result.accounts[0].plan || {}
  const user = result.users.filter((usr) => {
    return usr.id === sessionUserId
  })[0]
  user.name =
    user.cognitoDetails.given_name + ' ' + user.cognitoDetails.family_name
  return {
    account: AccountMapperDataToDomain(result.accounts[0]),
    users: result.users.map((usr) => UserMapperDataToDomain(usr)),
    user: UserMapperDataToDomain(user),
    teams: result.teams.map((mData) => TeamMapperDataToDomain(mData)),
    services: (result.services ?? []).map((mData) =>
      ServiceCheckMapperDataToDomain(mData)
    ),
    plan: result.plan || {},
  }
}

export default MapperInit
