import React, { useEffect, useRef, useState } from 'react'
import { ToCamelCase } from './stringExt'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { useMutationXC, useQueryXC } from '../graphql/gql-hooks-xc'

/**
 * Replaces IN_VARS and OUT_VARS in dynamic query template
 * @param changed changed fields with values
 * @param inQuery dynamic query template
 * @returns {*} apollo/client gql() result
 */
export const prepareUpdateQuery = (
  changed,
  inQuery,
  exclude = [],
  types = {}
) => {
  const qq = inQuery
  const inVars = []
  const outVars = []
  if (exclude) {
    console.log('excluding: ', exclude)
  }
  for (const [key] of Object.entries(changed)) {
    inVars.push(`$${key}: ${types[key] || 'String'}`)
    outVars.push(`${key}: $${key}`)
  }
  const qweSrcTempl = qq
    .replaceAll(/IN_VARS/g, inVars.join('\n'))
    .replaceAll(/OUT_VARS/g, outVars.join('\n'))
  return `
    ${qweSrcTempl}
  `
}
export const ExecuteGQLWithBuilder = (props) => {
  const { gql, variables, mutationName } = props.builderData
  return (
    <ExecuteGQLMutationLegacy
      actionName={mutationName}
      variables={variables}
      query={gql}
      mapper={props.mapper}
      {...props}
    />
  )
}
ExecuteGQLWithBuilder.propTypes = {
  builderData: PropTypes.object.isRequired,
  mapper: PropTypes.func,
}
export const ExecuteGQLWithBuilderDummy = (props) => {
  return <ExecuteGQLDummy {...props} />
}
ExecuteGQLWithBuilderDummy.propTypes = {
  ...ExecuteGQLWithBuilder.propTypes,
  fakeError: PropTypes.object,
  fakeSuccess: PropTypes.object,
}
export const ExecuteGQLDummy = (props) => {
  const { onError, onDone, fakeError, fakeSuccess } = props
  const mapper =
    props.mapper ??
    ((src) => {
      return src
    })
  const action = useRef(null)
  const [state, setDummyState] = useState({ loading: true })
  useEffect(() => {
    if (state.loading) {
      return
    }
    if (state.error) {
      onError(state.error)
      return
    }
    if (state.data) {
      onDone(mapper(state.data))
    }
  }, [onDone, onError, state])
  if (state.loading) {
    setTimeout(() => {
      setDummyState(fakeError ? { error: fakeError } : { data: fakeSuccess })
    }, 600)
  }
  return action.current
}
ExecuteGQLDummy.propTypes = {
  fakeError: PropTypes.object,
  fakeSuccess: PropTypes.any,
}
export const ExecuteGQLWithBuilderAsync = (props) => {
  const { gql, variables, mutationName } = props.builderData
  ExecuteGQLMutation({
    actionName: mutationName,
    variables: variables,
    query: gql,
    ...props,
  })
}
ExecuteGQLWithBuilderAsync.propTypes = {
  builderData: PropTypes.object.isRequired,
}

export const ExecuteGQLMutationLegacy = (props) => {
  const { actionName, onDone, onError } = props
  const action = useRef(null)
  const alreadyCalled = useRef(false)
  const { execute, state } = useMutationXC()

  useEffect(() => {
    if (state.loading) {
      return
    }
    if (state.error) {
      onError(state.error)
      return
    }
    if (state.data) {
      onDone(state.data)
    }
  }, [actionName, onDone, onError, state])

  // Prevent multiple calls
  if (alreadyCalled.current) {
    return action.current
  }
  alreadyCalled.current = true
  console.log('ExecuteGQLMutation: ', props)
  execute(props)
  return action.current
}
export const ExecuteGQL = (props) => {
  const { actionName, onDone, onError } = props
  const action = useRef(null)
  const alreadyCalled = useRef(false)
  const { execute, state } = useQueryXC()
  useEffect(() => {
    if (state.loading) {
      return
    }
    if (state.error) {
      onError(state.error)
      return
    }
    if (state.data) {
      onDone(state.data)
    }
  }, [actionName, onDone, onError, state])
  // Prevent multiple calls
  if (alreadyCalled.current) {
    return action.current
  }
  alreadyCalled.current = true
  console.log('ExecuteGQLMutation: ', props)
  execute(props)
  return action.current
}
export const ExecuteGQLAsync = (props) => {
  const { actionName, variables, query, onDone, onError } = props
  const justFirst = props.justFirst ?? false
  const onReadyToMap = (data, mapper) => {
    console.log('data: ', data)
    if (mapper === undefined) {
      return data
    }
    const mapped = data.map((mData) => mapper(mData))
    console.log('mapped: ', mapped)
    return mapped
  }
  API.graphql(graphqlOperation(query, variables), {
    pollInterval: props.pollInterval || 0,
    fetchPolicy:
      props.fetchPolicy ||
      (props.forceFetch && 'network-only') ||
      'cache-first',
  })
    .then((apiData) => {
      console.log('apiData', JSON.stringify(apiData))
      const data = { ...apiData.data }
      const returnAll = props.returnAll || false
      if (returnAll) {
        onDone(data)
        return
      }
      const rData = onReadyToMap(data[actionName].items, props.mapper)
      onDone(justFirst ? (rData.length > 0 ? rData[0] : {}) : rData)
    })
    .catch((err) => {
      console.log('error: ', err)
      onError(err)
    })
}
export const ExecuteGQLAsyncDummy = (props) => {
  const { onDone, onError, fakeError, fakeSuccess } = props
  setTimeout(() => {
    fakeError ? onError({ error: fakeError }) : onDone({ data: fakeSuccess })
  }, 600)
}
ExecuteGQLAsyncDummy.propTypes = {
  fakeError: PropTypes.object,
  fakeSuccess: PropTypes.any,
}

export const ExecuteGQLMutation = (props) => {
  const { actionName, variables, query, onDone, onError } = props

  API.graphql(graphqlOperation(query, variables), {
    pollInterval: props.pollInterval || 0,
    fetchPolicy:
      props.fetchPolicy ||
      (props.forceFetch && 'network-only') ||
      'cache-first',
  })
    .then((data) => {
      console.log('data', JSON.stringify(data))
      const res = data.data[actionName]
      const keys = Object.keys(res)
      const indOfType = keys.indexOf('__typename')
      if (indOfType >= 0) {
        keys.splice(indOfType, 1)
      }
      console.log(res, keys)
      onDone(keys.length === 1 ? res[keys[0]][0] : res)
    })
    .catch((err) => {
      console.log('error: ', err)
      onError(err)
    })
}

/**
 *
 * @param entity EntityName in camelCase
 * @param fields GraphQL type fields to be returned
 * @param filter like {key: 'dsadada'}
 * @param changed changed data like {'name': 'dsjkkdas'}
 * @param addConnections: [GQL_Connection, GQL_Create, GQL_Update]
 * @returns {{DocumentNode, string}} (gql``, mutationName)
 */
export const buildForUpdate = (
  entity,
  fields,
  filter,
  changed,
  addConnections = []
) => {
  const eName = ToCamelCase(entity)
  const mutationName = `update${eName}s`
  console.log('buildForUpdate', entity, eName)

  const variables = { where: { ...filter }, input: {} }
  const disconnectValue = {}
  const connectValue = {}
  const delValue = {}
  const inValue = { ...changed }
  let deleteConnection = false
  let disconnectConnection = false
  let connectConnection = false
  addConnections.forEach((val) => {
    const kk = Object.keys(val)[0]
    console.log(
      'kk',
      JSON.parse(JSON.stringify(kk)),
      JSON.parse(JSON.stringify(val))
    )
    const nVal = val[kk]
    if (val.type === 'delete') {
      deleteConnection = true
      delValue[kk] = nVal
    } else if (val.type === 'disconnect') {
      disconnectConnection = true
      disconnectValue[kk] = nVal
    } else if (val.type === 'connect') {
      connectConnection = true
      connectValue[kk] = nVal
    } else {
      inValue[kk] = nVal
    }
  })
  console.log(
    'deleteConnection: ',
    deleteConnection,
    ', deleteEntity: ',
    eName,
    ', inValue: ',
    JSON.parse(JSON.stringify(inValue)),
    ', delValue: ',
    JSON.parse(JSON.stringify(delValue))
  )
  const additionalArgs = deleteConnection
    ? `, $delete: ${eName}DeleteInput`
    : ''
  const additionalArgs2nd = deleteConnection ? ', delete: $delete' : ''

  const additionalArgsDis = disconnectConnection
    ? `, $disconnect: ${eName}DisconnectInput`
    : ''
  const additionalArgsDis2nd = disconnectConnection
    ? ', disconnect: $disconnect'
    : ''

  const additionalArgsCon = connectConnection
    ? `, $connect: ${eName}ConnectInput`
    : ''
  const additionalArgsCon2nd = connectConnection ? ', connect: $connect' : ''

  variables.input = inValue
  if (deleteConnection) {
    variables.delete = delValue
  }
  if (disconnectConnection) {
    variables.disconnect = disconnectValue
  }
  if (connectConnection) {
    variables.connect = connectValue
  }

  return {
    gql: `
  mutation Update${eName}($where: ${eName}WhereMutation!, $input: ${eName}UpdateInput!${additionalArgs}${additionalArgsDis}${additionalArgsCon}) {
    ${mutationName}(where: $where, input: $input${additionalArgs2nd}${additionalArgsDis2nd}${additionalArgsCon2nd}) {
      ${entity}s {
        ${fields}
      }
    }
  }
`,
    mutationName,
    variables: variables,
  }
}
export const buildForDelete = (entity, filter, fields, connections = []) => {
  const eName = ToCamelCase(entity)
  const mutationName = `delete${eName}s`
  console.log('buildForDelete', entity, eName)
  const inValue = {}
  const hasConnections = connections.length > 0
  connections.forEach((val) => {
    const kk = Object.keys(val)[0]
    // const vvv = {}
    inValue[kk] = val[kk]
    // inValue.push(vvv)
  })
  const additionalArgs = hasConnections ? `, $delete: ${eName}DeleteInput` : ''
  const additionalArgs2nd = hasConnections ? ', delete: $delete' : ''
  const variables = { filter: { ...filter } }
  if (hasConnections) {
    variables.delete = inValue
  }
  console.log(
    'variables: ',
    JSON.parse(JSON.stringify(variables)),
    ', connections: ',
    JSON.parse(JSON.stringify(connections))
  )

  return {
    gql: `
  mutation Delete${eName}($filter: ${eName}WhereMutation!${additionalArgs}) {
    ${mutationName}(where: $filter${additionalArgs2nd}) {
      ${entity}s {
        ${fields}
      }
    }
  }
`,
    mutationName,
    variables: variables,
  }
}
export const GQLConnection = (entity, key, value) => {
  const result = {}
  const connect = {
    connect: { where: {} },
  }
  connect.connect.where[`${key}`] = value
  result[`${entity}`] = connect
  result.type = 'connect'
  return result
}
export const GQLDisconnect = (entity, key, value) => {
  const result = {}
  const disconnect = {
    disconnect: { where: {} },
  }
  disconnect.disconnect.where[`${key}`] = value
  result[`${entity}`] = disconnect.disconnect
  result.type = 'disconnect'
  return result
}
export const GQLConnect = (entity, key, value) => {
  const result = {}
  const connect = {
    connect: { where: {} },
  }
  connect.connect.where[`${key}`] = value
  result[`${entity}`] = connect.connect
  result.type = 'connect'
  return result
}
export const GQLCreate = (entity, value) => {
  const result = {}
  const create = {
    create: {},
  }
  create.create = value
  result[`${entity}`] = create
  result.type = 'create'
  return result
}
export const GQLDelete = (entity, key, condition, additionalEntityName) => {
  const result = {}
  const inVal = { ...condition }
  const data = {
    where: {},
  }
  data.where[`${key}`] = inVal[`${key}`]
  result[`${entity}`] = data
  result.type = 'delete'
  if (additionalEntityName) {
    result.additionalEntityName = additionalEntityName
  }
  return result
}
export const GQLUpdate = (entity, key, value, asSingleUpdate) => {
  const result = {}
  const inVal = { ...value }
  const update = {
    where: {},
    update: {},
  }
  update.where[`${key}`] = inVal[`${key}`]
  delete inVal[`${key}`]
  update.update = inVal
  result[`${entity}`] = asSingleUpdate ? update : [update]
  result.type = 'update'
  return result
}
/**
 *
 * @param entity: string
 * @param fields: string
 * @param changed: {}
 * @param addConnections: [GQL_Connection, GQL_Create, GQL_Update]
 * @returns {{variables: {input: [undefined]}, gql: DocumentNode, mutationName: string}}
 */
export const buildForCreate = (
  entity,
  fields,
  changed,
  addConnections = []
) => {
  const eName = ToCamelCase(entity)
  const mutationName = `create${eName}s`
  console.log('buildForCreate', entity, eName)
  const inValue = { ...changed }
  addConnections.forEach((val) => {
    const kk = Object.keys(val)[0]
    inValue[kk] = val[kk]
  })
  return {
    gql: `
  mutation Create${eName}($input: ${eName}CreateInput!) {
    ${mutationName}(input: $input) {
      ${entity}s {
        ${fields}
      }
    }
  }
`,
    mutationName,
    variables: { input: inValue },
  }
}
export const buildForConnection = (
  entity,
  entityFilter,
  connectTo,
  connectToFilter,
  fields,
  shouldConnect
) => {
  const eCntBase = ToCamelCase(entity)
  const eCnt = ToCamelCase(connectTo)
  const mutationName = `update${eCntBase}${eCnt}s`
  const operation = shouldConnect ? 'Add' : 'Remove'
  console.log('buildForConnect', entity, connectTo, eCnt)

  const input = {}
  input[`${operation.toLowerCase()}`] = {}
  input[`${operation.toLowerCase()}`][`${connectTo}s`] = [connectToFilter.id]
  return {
    gql: `
  mutation ${operation}${eCnt}($filter: ${eCntBase}WhereMutation!, $input: ${eCntBase}${eCnt}sUpdateInput!) {
    ${mutationName}(where: $filter, input: $input) {
      ${entity}s {
        ${fields}
      }
    }
  }
`,
    mutationName,
    variables: { filter: { ...entityFilter }, input },
  }
}

export const CacheChangeType = {
  add: 10,
  delete: 20,
  update: 30,
}

export const CacheModifyFor = (type, existing, updatedData, fields) => {
  switch (type) {
    case CacheChangeType.add:
      return [...existing, updatedData]
    case CacheChangeType.delete:
      return existing.filter((value) => {
        return value.id !== updatedData.id
      })
    case CacheChangeType.update:
      console.log('CacheModifyFor existing: ', JSON.stringify(existing))
      return existing.map((value) => {
        if (value.id !== updatedData.id) {
          return value
        }
        const changes = {}
        fields.forEach((trimmedKey) => {
          if (updatedData[trimmedKey] !== undefined) {
            changes[trimmedKey] = updatedData[trimmedKey]
          }
        })
        console.log('CacheModifyFor changes: ', JSON.stringify(changes))
        return { ...value, ...changes }
      })
    default:
      throw new Error('Unknown cache change type: ' + type)
  }
}
export const CacheAddConnection = (
  existing = [],
  filterId,
  src,
  connectionKey
) => {
  console.log('CacheAddConnection', JSON.parse(JSON.stringify(existing)))
  return existing.map((value) => {
    if (
      value.id !== filterId ||
      typeof value[`${connectionKey}`] === 'undefined'
    ) {
      return value
    }
    const data = value[`${connectionKey}`]
    const isArray = data != null && typeof data.length !== 'undefined'
    const freshData = {}
    Object.keys(src).forEach((sKey) => {
      freshData[sKey] = src[sKey]
    })
    const dest = isArray ? [...data] : { ...freshData }
    if (isArray) {
      dest.push(freshData)
    }
    const result = { ...value }
    result[`${connectionKey}`] = dest
    return result
  })
}
export const CacheRemoveConnection = (
  existing = [],
  filterId,
  removedId,
  connectionKey
) => {
  return existing.map((value) => {
    if (
      value.id !== filterId ||
      typeof value[`${connectionKey}`] === 'undefined'
    ) {
      return value
    }
    const src = value[`${connectionKey}`] ?? {}
    console.log('src', JSON.parse(JSON.stringify(src)))
    console.log(
      'typeof src.filter == undefined',
      typeof src.filter === 'undefined'
    )
    const dest =
      typeof src.filter === 'undefined'
        ? null
        : [
            ...src.filter((toFilter) => {
              return toFilter.id !== removedId
            }),
          ]
    console.log('dest', dest ? JSON.parse(JSON.stringify(dest)) : 'undefined')
    const result = { ...value }
    result[`${connectionKey}`] = dest
    console.log('dest', JSON.parse(JSON.stringify(result)))
    return result
  })
}
export const getByKey = (src, key) => {
  const result = src.filter((value) => {
    return value.id === key
  })
  return result.length > 0 ? result[0] : { id: key }
}
export const buildForGet = (entity, fields, filter, whereSuffix = '') => {
  const eName = ToCamelCase(entity)
  const mutationName = `${entity}s`
  // console.log('buildForGet', entity, eName, fields)
  return {
    gql: `
  query get${eName}($filter: ${eName}${whereSuffix}Where!) {
    ${mutationName}(where: $filter) {
      items {
        ${fields}
      }
    }
  }
`,
    mutationName,
    variables: { filter: { ...filter } },
  }
}

export default ExecuteGQLMutationLegacy
