import React from 'react'
import { buildForGet, ExecuteGQL } from '../../../utils/executeGraphQL'
import { Entity } from '../../entities'
import { IncidentMapperDataToDomain } from './mapper'
import PropTypes from 'prop-types'

export const FIELDS_BASE_INCIDENTS = [
  'id',
  'accountId',
  'serviceId',
  'timestamp',
  'closedAt',
  'name',
  'duration',
  'code',
  'acknowledged',
  'acknowledgedBy',
  'groupId',
  'lastStateChange',
  'type',
]
export const AKNOWLEDGEDBY_FIELDS_BASE = [
  'acknowledgedAt',
  'entity',
  'entityId',
  'source',
]
export const FIELDS_BASE_INCIDENTS_SUB = {
  acknowledgedBy:
    'acknowledgedBy {' + AKNOWLEDGEDBY_FIELDS_BASE.join('\n') + '}',
}
export const FIELDS_INCIDENTS = FIELDS_BASE_INCIDENTS.map(
  (name) => FIELDS_BASE_INCIDENTS_SUB[name] || name
).join('\n')

const GetIncidents = (props) => {
  const { accountId, onLoad, onError } = props
  // mutationName will be renamed to queryName later
  const { gql, mutationName, variables } = buildForGet(
    Entity.incident,
    FIELDS_INCIDENTS,
    {
      accountId: accountId,
    }
  )
  return (
    <ExecuteGQL
      justFirst={false}
      actionName={mutationName}
      variables={variables}
      mapper={(src) => IncidentMapperDataToDomain(src, props.mapEntities)}
      onDone={onLoad}
      onError={onError}
      query={gql}
    />
  )
}
GetIncidents.propTypes = {
  mapEntities: PropTypes.func,
}

export default GetIncidents
