import { getDiff } from '../../../../utils/diff'
import {
  S_CREATED,
  S_LOAD_DONE,
  S_LOAD_START,
  S_SAVING,
} from '../../../../utils/states-actions'

export const S_WITH_NOTIFICATIONS = 60

export const StateInitial = () => {
  return {
    type: S_LOAD_START,
    team: {},
    teamEdit: {},
    message: 'Please wait',
    errors: {},
  }
}
export const StateLoadedTeam = (data) => {
  return {
    type: S_LOAD_DONE,
    team: data,
    teamEdit: {},
    errors: {},
  }
}
export const StateCreatedTeam = (data) => {
  return {
    type: S_CREATED,
    team: data,
    teamEdit: {},
    errors: {},
  }
}
export const StateSaving = {
  type: S_SAVING,
}
export const StateWithNotifications = () => {
  return {
    type: S_WITH_NOTIFICATIONS,
  }
}

export const actionSaveTeam = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateSaving,
      }
    },
  }
}
export const actionShowNotifications = () => {
  return {
    reduce: (state) => {
      return {
        ...state,
        ...StateWithNotifications(),
      }
    },
  }
}
export const actionLoadedTeam = (data) => {
  const team = { ...data }
  return {
    reduce: () => {
      return StateLoadedTeam({ ...team })
    },
  }
}
export const actionCreatedTeam = (data) => {
  const team = { ...data }
  return {
    reduce: () => {
      return StateCreatedTeam({ ...team })
    },
  }
}
export const actionOnTeamChange = (data) => {
  const team = { ...data }
  return {
    reduce: (state) => {
      return getDiff(team, state, 'team')
    },
  }
}
