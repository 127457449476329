import { useReducer } from 'react'
import { reducer } from '../components/reducer'
import { API, graphqlOperation } from 'aws-amplify'

const State = {
  initial: () => {
    return {
      loading: true,
    }
  },
  error: (error) => {
    const errors = (error.errors ?? [])
      .map((err) => {
        return err.message
      })
      .join(',')

    return {
      reduce: () => {
        return {
          loading: false,
          error: {
            code: 0,
            message: errors,
          },
        }
      },
    }
  },
  done: (result) => {
    return {
      reduce: () => {
        return {
          loading: false,
          data: result,
        }
      },
    }
  },
}

const gqlHeaders = (props) => {
  return {
    pollInterval: props.pollInterval || 0,
    fetchPolicy:
      props.fetchPolicy ||
      (props.forceFetch && 'network-only') ||
      'cache-first',
  }
}

export const useMutationXC = () => {
  const [state, dispatch] = useReducer(reducer, State.initial())
  const execute = (props) => {
    const onError = (err) => {
      console.log('::execute llOnError:', err)
      dispatch(State.error(err))
    }
    const onLoad = (data) => {
      console.log('::execute onLoad:', data)
      dispatch(State.done(data))
    }
    const { actionName, variables, query } = props

    API.graphql(graphqlOperation(query, variables), gqlHeaders(props))
      .then((data) => {
        console.log('data', JSON.stringify(data))
        const res = data.data[actionName]
        const keys = Object.keys(res)
        const indOfType = keys.indexOf('__typename')
        if (indOfType >= 0) {
          keys.splice(indOfType, 1)
        }
        console.log(res, keys)
        onLoad(
          keys.length === 1
            ? Array.isArray(res[keys[0]])
              ? res[keys[0]][0]
              : res[keys[0]]
            : res
        )
      })
      .catch(onError)
  }

  return {
    state,
    execute,
  }
}

export const useQueryXC = () => {
  const [state, dispatch] = useReducer(reducer, State.initial())
  const execute = (props) => {
    const onError = (err) => {
      console.log('::execute llOnError:', err)
      dispatch(State.error(err))
    }
    const onLoad = (data) => {
      console.log('::execute onLoad:', data)
      dispatch(State.done(data))
    }
    const { actionName, variables, query } = props
    const justFirst = props.justFirst ?? false
    const onReadyToMap = (data, mapper) => {
      console.log('data: ', data)
      if (mapper === undefined) {
        return data
      }
      const mapped = Array.isArray(data)
        ? data.map((mData) => mapper(mData))
        : mapper(data)
      console.log('mapped: ', mapped)
      return mapped
    }

    API.graphql(graphqlOperation(query, variables), gqlHeaders(props))
      .then((apiData) => {
        console.log('data', JSON.stringify(apiData))
        const data = { ...apiData.data }
        const returnAll = props.returnAll || false
        const rData = onReadyToMap(
          returnAll ? data : data[actionName].items,
          props.mapper
        )
        onLoad(justFirst ? (rData.length > 0 ? rData[0] : {}) : rData)
      })
      .catch(onError)
  }

  return {
    state,
    execute,
  }
}
