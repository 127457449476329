import React from 'react'
import { withTranslation } from 'react-i18next'
import { useWelcomeContext } from '../context'
import { useAppContext } from '../../../context/app-context'
import LoadingView from '../../../components/loading-view'

const StoreCognitoUser = (props) => {
  const hookRef = useWelcomeContext()
  const { appData } = useAppContext()
  console.log('StoreCognitoUser: ', props)
  if (!appData) {
    hookRef.storeUser(hookRef.state.session)
  }
  return <LoadingView message={'welcome.sync'} />
}
export default withTranslation()(StoreCognitoUser)
