import { FormControl, FormLabel, RadioGroup } from '@material-ui/core'
import { RadioItem } from './radio-item'
import React from 'react'
import PropTypes from 'prop-types'

const Toggle = (props) => {
  const { field, value, onToggle, t } = props
  const name = props.name || `toggle-${Date()}`

  return (
    <FormControl component="fieldset" className={props.classFormControl}>
      {props.label && (
        <FormLabel component="legend">{t(props.label)}</FormLabel>
      )}
      <RadioGroup
        className={props.classRadioGroup}
        aria-label={name}
        name={name}
        value={value || false}
        onChange={(event) => {
          onToggle(field, event)
        }}
      >
        <RadioItem
          value={true}
          label={t('enabled')}
          testId={`${field}Enabled`}
        />
        <RadioItem
          value={false}
          label={t('disabled')}
          testId={`${field}Disabled`}
        />
      </RadioGroup>
    </FormControl>
  )
}

Toggle.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
  onToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classFormControl: PropTypes.string,
  classRadioGroup: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

export default Toggle
